import React, {
  useEffect,
  useRef,
  useReducer,
  useState,
  useCallback,
} from 'react';
import { t } from 'i18next';
import { useInterval } from '../shared/hooks/use-interval';
import { userOnboardingMachine } from '../shared/machines';
import { useMachine } from '@xstate/react';
import { throttle } from 'lodash';
import {
  ResponsiveContainer,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  ScatterChart,
  Scatter,
} from 'recharts';
import {
  boxoToUsd,
  isAndroid,
  isIos,
  isNativeApp,
  isPwa,
  isTelegram,
  priceFmt,
  priceFmtThousands,
} from '../shared/utils';
import { AnimatedDot, AnimatedCongrats } from '../shared/animation';
import { v4 as uuid } from 'uuid';
import { useUserContext } from '../shared/hooks/use-user';
import {
  BackgoundLight,
  BetControl,
  DebugControl,
  ProgressBar,
  SuperBox,
  SvgStyles,
  AnimatedHand,
  DotCoin,
  TutorialDialog,
  Speedometr,
  GiftJump,
} from '../components/terminal';
import { DepositDialog } from './deposit';
import useBlockchainDeposit from '../shared/hooks/use-blockchain';
import {
  toast,
  errorToast,
  winToast,
  clearToasts,
  WinToastContainer,
  ToastContainer,
} from '../shared/toast';
import { CountUpDown, changeType } from '../shared/countupdown';
import { fetchPrices } from '../shared/api/price';
import { useBonusesContext } from '../shared/hooks/use-bonus';
import {
  createSearchParams,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Auth from './auth';
import NotFound from './404';
import Settings from './settings/settings';
import PendingRedirect from './pending';
import { Balance } from './balance';
import { ConnectionLoader, Loader } from './loader';
import { useSoundContext } from '../shared/hooks/use-sound';
import {
  boxSelectionMachine,
  boxesMachine,
  boxStatus,
} from '../shared/machines/terminal';
import { animatedCongratsMachine } from '../shared/machines/animation';
import useEmulatorPrice from '../emulator/emulator';
import { BonusListDialog } from './bonus';
import { sendBet } from '../shared/api/account';
import { Leaderboard, LeagueStatus } from './leaderboard';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { useUpdaterContext } from '../shared/hooks/use-updater';
import { SystemDialog } from '../shared/dialogs';
import { useWssPrices } from '../shared/hooks/use-wssPrices';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import { Premium, Shop } from './shop';
import { useAnalyticsContext } from '../shared/hooks/use-analytics';
import { getChallengeBonusProgress } from '../shared/api/bonus';
import { useAdsContext } from '../shared/hooks/use-ads';
import { tutorialMachine } from '../shared/machines/tutorial';
import Header from '../shared/header';

const isEmulator = false;
const usePrices = isEmulator ? useEmulatorPrice : useWssPrices;

const lootBoxTypes = { grade0: 1, grade1: 3, grade2: 6 };

export default function Terminal() {
  const logger = useLoggerContext();
  const [debugPausePrice, setDebugPausePrice] = useState(false);

  const navigate = useNavigate();

  const {
    normalClick: clickSound,
    wetClick: wetClickSound,
    levelUp: levelUpSound,
    hits: hitsSound,
    win: winSound,
    notification: notificationSound,
  } = useSoundContext();

  const {
    initUser,
    resetUser,
    user,
    balance,
    winBalance,
    setBalance,
    setWinBalance,
    isLoading,
    setOnUserLogout,
    isLogged,
  } = useUserContext();
  const hideGambling = false;

  const { updateApp, updateIsRequired } = useUpdaterContext();

  const onLogout = async (sender) => {
    setOnUserLogout(null);
    resetUser(sender);
    sessionStorage.clear();
    setTimeout(() => {
      navigate('/home', { replace: true });
    }, 400);
  };

  const onRestartGame = async () => {
    boxesSend('RESET');
    resetUser('restart', 100);
    sendUserOnboarding({
      type: 'RESET',
      payload: {
        bets: 0,
        winsCounter: 0,
      },
    });
    nativeApp.haptics('success');
    setTimeout(() => {
      navigate('/app', { replace: true });
    }, 400);
  };

  useEffect(() => {
    const init = async () => {
      if (isLogged) {
        const { minBet } = await initUser(user?.email);
        setOnUserLogout(onLogout);
        setBet(minBet ?? 1);
      }
    };
    init();
  }, []);

  const refWinBalance = useRef();
  refWinBalance.current = winBalance;

  const refBalance = useRef();
  refBalance.current = balance;

  const {
    getActualBonuses,
    getActualChallengeBonus,
    getActualRecoveryBonus,
    getActualWelcomeBonus,
    getActualStreakBonus,
    getActualAdsBonus,
    getActualSponsoredBonus,
    isLoading: isBonusesLoading,
    invalidateBonuses,
    getActualNewBonuses,
  } = useBonusesContext();
  const [bonuses, setBonuses] = useState([]);
  const [challengeBonus, setChallengeBonus] = useState(null);
  const [sponsoredBonus, setSponsoredBonus] = useState(null);
  const [healthBonus, setHealthBonus] = useState(null);
  const [streakBonus, setStreakBonus] = useState(null);

  const [newBonusesCount, setNewBonusesCount] = useState(0);

  useEffect(() => {
    if (isBonusesLoading) return;
    if (!isLogged) return;
    getActualBonuses().then((newBonuses) => setBonuses(newBonuses));
    getActualChallengeBonus().then((newChallengeBonus) => {
      setChallengeBonus(newChallengeBonus);
      sendUserOnboarding({
        type: 'RESET',
        payload: {
          winsCounter: newChallengeBonus?.progress,
          winsCounterLimit: newChallengeBonus?.params?.limit,
        },
      });
    });
    getActualNewBonuses().then((newBonuses) =>
      setNewBonusesCount(newBonuses?.length)
    );
    getActualSponsoredBonus().then((newBonus) => setSponsoredBonus(newBonus));
    getActualAdsBonus().then((newBonus) => setHealthBonus(newBonus));
    getActualStreakBonus().then((newBonus) => setStreakBonus(newBonus));
  }, [isLogged, isBonusesLoading]);

  // const [persistedTutorial, setPersistedTutorial] = useLocalStorage(
  //   'tutorial-state',
  //   tutorialMachine.initialState
  // );
  const [tutorial, sendTutorial, tutorialService] = useMachine(
    tutorialMachine //,  { state: persistedTutorial }
  );
  useEffect(() => {
    tutorialService?.subscribe((state) => {
      //setPersistedTutorial(state);
      logger?.event('tutorial', { state: state.value });
      track(`tutorial${state.value}`);
    });
  }, []);

  const [userOnboarding, sendUserOnboarding] = useMachine(
    userOnboardingMachine,
    {
      actions: {
        notifyOnLimit: (e, c) => {
          /*
          if (!user?.email) {
            setTimeout(() => {
              if (location.pathname.replace(/\/+$/, '') === '/app') {
                navigate('./bonus/no-auth', { replace: true });
                clearToasts();
                sendUserOnboarding({ type: 'RESET' });
              }
            }, 100);
          }
          */
        },
      },
    }
  );

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(isIos() || isAndroid() || isNativeApp());
    setLastPriceUpdate(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Animated coins
  const pendingAnimation = useRef(0);
  const onAnimatioCompete = (count) => {
    pendingAnimation.current = Math.max(0, pendingAnimation.current - count);
  };

  const [isBoxesGridShown, setIsBoxesGridShown] = useState(true);
  const [isBoxesGridOverlayShown, setIsBoxesGridOverlayShown] = useState(true);

  const [isConnected, setIsConnected] = useState(true); //location.pathname.replace(/\/+$/, '') !== '/app/start';

  useEffect(() => {
    const isShown =
      isConnected &&
      (isLogged ||
        (!tutorial?.matches('waitForStart') &&
          !tutorial?.matches('waitAcceptChallenge') &&
          !tutorial?.matches('waitIncreaseBet')));
    setIsBoxesGridShown(isShown);
    setIsBoxesGridOverlayShown(isShown);
  }, [isLogged, tutorial?.value, isConnected]);

  // useEffect(() => {
  //   if (
  //     streakBonus &&
  //     !streakBonus?.disabled &&
  //     isConnected &&
  //     location.pathname.replace(/\/+$/, '') === '/app'
  //   ) {
  //     navigate('./bonus/streak', { replace: true });
  //   }
  // }, [streakBonus?.disabled, isConnected]);

  const [animatedCongratsState, sendAnimatedCongrats] = useMachine(
    animatedCongratsMachine
  );

  //time
  const boxLines = 4; //5..7
  const boxLinesArray = Array(boxLines).fill(0);
  const [boxColumns, setBoxColumns] = useState(9);
  const boxColumnsArray = Array(boxColumns).fill(0);

  const boxesOffset = 10000; //Time interval between price and 1st line of boxes
  const boxDuration = 2000; //height in msec
  const timeForBoxes = boxDuration * boxLines + boxesOffset;
  const [timeHistory, setTimeHistory] = useState(15 * 1000);
  const [timeDomain, setTimeDomain] = useState({
    min: -timeHistory,
    max: timeForBoxes,
  });
  const [time, setTime] = useState(0);

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [lastPriceUpdate, setLastPriceUpdate] = useState();
  const [lastUpdate, setLastUpdate] = useState();

  //price
  const [priceDomain, setPriceDomain] = useState({ min: 0, max: 10 });
  const [price, setPrice] = useState(0);
  const [priceChangePx, setPriceChangePx] = useState(0);
  //price data
  const [data, setData] = useState([]); //[{time:-1, price}, {time:0, price}]
  const [recentPricesData, setRecentPricesData] = useState({ used: false });

  const [randomPointData, setRandomPointData] = useState([]); //[{time:-1, price}, {time:0, price}]

  useEffect(() => {
    if (data.length !== 2) {
      return;
    }
    const start = data[0].time - timeHistory;
    const end = data[0].time;
    fetchPrices(start, end).then((prices) => {
      setRecentPricesData({ prices, used: false });
    });
  }, [data, timeHistory]);

  useEffect(() => {
    if (!recentPricesData.prices || recentPricesData.used) {
      return;
    }
    setRecentPricesData({ used: true });

    const convertPrices = (prices) =>
      !prices || !Array.isArray(prices)
        ? []
        : prices
            .map((price, i, prices) => {
              const prevPrice =
                i > 0 && prices.length > 0 ? prices[i - 1] : price;
              return [
                {
                  time: prevPrice.timestamp,
                  price: price.value,
                },
                {
                  time: price.timestamp,
                  price: price.value,
                },
              ];
            })
            .flat();

    updateData(
      data,
      convertPrices(recentPricesData.prices),
      timeHistory,
      setData
    );
  }, [recentPricesData, setData, data, timeHistory]);

  //bet
  const [bet, setBet] = useState(1);

  //grid
  const [boxesGrid, setBoxesGrid] = useState();
  const [boxesGridMismatch, setBoxesGridMismatch] = useState(0); //mismatch beween 1st line of boxes and real grid position in pixels

  const [terminalSize, setTerminalSize] = useState({ height: 800, width: 500 });
  const [centralPoint, setCentralPoint] = useState({ x: 0, y: 0 });
  const boxTerminalRef = useRef();

  const onDeposit = useCallback(
    async (income, asset, network, depositAddress) => {
      const newBalance = balance + income * 1000;

      setBalance(newBalance);
      toast(
        t(`Deposited {{amount}} {{asset}}`, {
          amount: income.toFixed(2),
          asset,
        }),
        {
          delay: 1500,
        }
      );

      logger?.event('deposit', {
        balance: newBalance,
        winBalance: winBalance,
        amount: income,
        asset,
        network,
        address: depositAddress,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [balance]
  );
  const [assets, history] = useBlockchainDeposit(user.email, onDeposit, logger);

  //boxes
  const [boxSize, setBoxSize] = useState(0); //width in dollars
  const [boxSizePx, setBoxSizePx] = useState(0); //width in px

  const ads = useAdsContext();

  //bonus toasts
  const [lastBonusToastTime, setLastBonusToastTime] = useState(null);
  useInterval(() => {
    //Show toasts for bonuses
    async function showToast() {
      const challengeBonus = await getActualChallengeBonus();
      const recoveryBonus = await getActualRecoveryBonus();
      const streakBonus = await getActualStreakBonus();
      const healthBonus = await getActualAdsBonus();

      const challengeProgress = challengeBonus
        ? userOnboarding?.context?.winsCounter /
          userOnboarding?.context?.winsCounterLimit
        : 0;

      if (challengeProgress >= 1) {
        setLastBonusToastTime(Date.now()); //Supress futher requests
        //Doublecheck progress
        getChallengeBonusProgress().then((resp) => {
          if (resp?.progress >= challengeBonus?.params?.limit) {
            //userOnboarding?.context?.winsCounterLimit
            clearToasts();
            navigate('./bonus/challenge', {
              replace: true,
            });
          } else {
            setLastBonusToastTime(null); //Reset timer
            resp?.progress &&
              sendUserOnboarding({
                type: 'WIN_OR_LOSE',
                payload: {
                  wins: resp.progress,
                },
              });
          }
        });
        return;
        setLastBonusToastTime(Date.now());
        toast(`${t('Claim achievement bonus')}💰`, {
          onOpen: () => notificationSound(),
          onClick: () => {
            navigate('./bonus/challenge', {
              replace: true,
              state: location.pathname,
            });
          },
          icon: () => <img width="32" alt="" src="/img/target-green.svg" />,
        });
        return;
      }

      if (
        !!recoveryBonus &&
        !recoveryBonus?.hidden &&
        !recoveryBonus?.disabled
      ) {
        setLastBonusToastTime(Date.now());
        toast(`${t('Claim recovery bonus')}💰`, {
          onOpen: () => notificationSound(),
          onClick: () => {
            navigate('./bonus/recovery', { replace: true });
          },
          icon: () => <img width="32" alt="" src="/img/red-heart-flat.svg" />,
        });
        return;
      }

      const challengeIndex = challengeBonus
        ? (challengeBonus?.params?.limit ?? 50) / 50 - 1
        : 0;
      const streakIndex = streakBonus?.disabled
        ? (streakBonus?.params?.dayIndex ?? 0) - 1
        : streakBonus?.params?.dayIndex ?? 0;

      const showStreak =
        streakIndex === 0 && // Day 1
        challengeIndex >= 1 && // 2nd challenge
        challengeProgress >= 0.5; // Half way
      // ||
      // (streakIndex > 0 && // Other days
      //   challengeProgress >= 0.1); // Just started to play

      if (
        !!streakBonus &&
        !streakBonus?.disabled &&
        showStreak &&
        ads.isReady()
      ) {
        setLastBonusToastTime(Date.now());
        toast(`${t('Claim daily bonus')}💰`, {
          onOpen: () => notificationSound(),
          onClick: () => {
            navigate('./bonus/streak', { replace: true });
          },
          icon: () => <img width="32" alt="" src="/img/calendar-flat.svg" />,
        });
        return;
      }

      const showHealth =
        (streakIndex === 0 && // Day 1
          challengeIndex >= 2 && // 3rd challenge
          challengeProgress >= 0.5) || // Half way
        (streakIndex > 0 && // Other days
          challengeProgress >= 0.5); // Half way through

      if (
        !!healthBonus &&
        !healthBonus?.disabled &&
        showHealth &&
        ads.isReady()
      ) {
        //setHealthBonus(healthBonus);
        // setLastBonusToastTime(Date.now());
        // toast(`${t('Claim health bonus')}💰`, {
        //   onOpen: () => notificationSound(),
        //   onClick: () => {
        //     navigate('./bonus/health', { replace: true });
        //   },
        //   icon: () => <img width="32" alt="" src="/img/health-colored.svg" />,
        // });
        return;
      }
      setLastBonusToastTime(0);
    }

    if (!isLogged && !isConnected) return;
    if (location.pathname.replace(/\/+$/, '') !== '/app') return;
    if (lastBonusToastTime === null) {
      setLastBonusToastTime(Date.now() - 25 * 1000);
      showToast();
      return;
    }
    if (Date.now() - lastBonusToastTime <= 20 * 1000) return;
    showToast();
  }, 2000);

  const [boxesState, boxesSend] = useMachine(boxesMachine, {
    state: {
      ...boxesMachine.initialState,
      context: { ...boxesMachine.initialState.context, timeHistory },
    },
    actions: {
      notifyAdded: () => {
        nativeApp.haptics('selection');
        setTimeout(() => wetClickSound && wetClickSound(), 30);
      },
      notifyUpdate: (context, event) => {
        const prize = event.data.prize;
        const pendingBoxesCount = event.data.count.pending;
        const executedCount = event.data.count.executed;
        const maxMultiplier = event.data.count.maxMultiplier;
        const maxPrize = event.data.count.maxPrize;

        if (
          !context.lastExecutedTime ||
          Date.now() - context.lastExecutedTime > boxTimeSize
        )
          setTimeout(() => {
            context?.getCloser && hitsSound(context?.getCloser);
          }, 200);

        if (pendingBoxesCount > 0 || executedCount > 0) {
          sendUserOnboarding({
            type: 'WIN_OR_LOSE',
            payload: {
              prize,
              wins: executedCount,
              ...event.data.count,
            },
          });
        }

        if (prize > 0) {
          const newWinBalance = Number(refWinBalance.current * 1) + prize;
          const newBalance = Number(refBalance.current * 1) + prize;
          setWinBalance(newWinBalance);
          setBalance(newBalance);
          setTimeout(() => {
            //Sound
            if (maxMultiplier >= 1.5) winSound(2);
            else if (maxMultiplier >= 1.3) winSound(1);
            else winSound(0);

            nativeApp.haptics('heavy');

            //Visual
            sendAnimatedCongrats('ADD', {
              //payload: { text: `× ${priceFmt(maxMultiplier, 1, 1)}` },
              payload: { text: `+ ${priceFmt(prize, 1, 1)}` },
            });

            const congratMessage =
              maxMultiplier >= 40
                ? t(`Unbelievable!`)
                : maxMultiplier >= 20
                ? t(`Phenomenal!`)
                : maxMultiplier >= 10
                ? t(`Legendary!`)
                : maxMultiplier >= 8
                ? t(`Incredible!`)
                : maxMultiplier >= 6
                ? t(`Epic!`)
                : maxMultiplier >= 5
                ? t(`Outstanding!`)
                : maxMultiplier >= 4
                ? t(`Super!`)
                : '';

            congratMessage &&
              location.pathname.replace(/\/+$/, '') === '/app' &&
              !tutorial.matches('waitFor1stWin') &&
              winToast(congratMessage, {
                winMultiplier: maxMultiplier,
                winAmount: maxPrize,
                onOpen: () => {
                  levelUpSound();
                  nativeApp.vibrate(400);
                },
              });

            const firstWin = 10; //userOnboarding.context.wins ? 0 : 10;
            pendingAnimation.current =
              pendingAnimation.current +
              Math.max(1, prize - executedCount) +
              firstWin;

            !isLogged &&
              logger?.event('win', {
                winCount: Number(executedCount?.toFixed(0)),
                amount: Number(prize?.toFixed(2)),
                multiplier: maxMultiplier,
                bigWin: !!congratMessage,
                balance: Number((balance + prize)?.toFixed(2)),
                winBalance: Number(newWinBalance?.toFixed(2)),
              });
          }, 200);
        }
      },
    },
  });

  const onDeliverPurchase = async (amount, purchase, error) => {
    const product = nativeApp?.products.find(
      (p) => p.id?.toLowerCase() === purchase?.productId?.toLowerCase()
    );

    if (error || !product || (product?.type === 'shop' && !amount)) {
      logger?.event('error', {
        msg: `Purchase has failed`,
        error,
        product,
        amount,
        balance,
        purchase,
      });
      errorToast(
        t(`Purchase has failed. Please try later or contact support.`),
        { autoClose: 6000 }
      );
      if (process.env.NODE_ENV === 'development')
        alert('Error: ' + error + ' product=' + JSON.stringify(product));
      navigate('/app', { replace: true });
      return;
    }

    if (product?.type === 'premium') {
      await initUser(user?.email);
      toast(t(`Your account status is upgraded to premium`), {
        delay: 0,
      });
      setTimeout(() => {
        navigate('/app', { replace: true });
      }, 700);
      track('purchase');
      logger?.event('purchase', {
        product,
        purchase,
      });
    }

    if (product?.type === 'shop') {
      const newBalance = Number(balance * 1) + amount * 1;
      setBalance(newBalance);
      //setBet(newBalance / ...);
      track('purchase');
      logger?.event('purchase', {
        product,
        amount,
        balance: newBalance,
        purchase,
      });
      navigate('/app', { replace: true });
      toast(
        t(`Your balance is credited with {{amount}} {{asset}}`, {
          amount: priceFmt(amount, 0),
          asset: 'BOXO',
        }),
        {
          delay: 1500,
        }
      );
    }
  };

  const nativeApp = useNativeAppContext();
  nativeApp.setOnDeliverPurchase(onDeliverPurchase);

  const [boxSelectionState, boxSelectionSend] = useMachine(
    boxSelectionMachine,
    {
      services: {
        submitBoxes: (context) =>
          new Promise(async (resolve, reject) => {
            try {
              const boxes = await submitBoxes(context.selection);
              resolve({ boxes });
            } catch (e) {
              console.error(e);
              reject();
            }
          }),
      },
      actions: {
        notifyAdding: () => {
          clickSound && clickSound();
          nativeApp.haptics('selection');
        },

        notifyError: () => {
          //no email
          //const pendingCount = boxesState?.context?.pendingCount;
          if (!user?.email) {
            if (
              location.pathname.replace(/\/+$/, '') === '/app' &&
              !boxesState.context.pendingCount &&
              userOnboarding.context.winsCounter <
                userOnboarding.context.winsCounterLimit
            ) {
              setBalance(tutorial.context.restartAmount);
              boxesSend('RESET');
              setBet(1);
              sendUserOnboarding({
                type: 'RESET',
                payload: {
                  winsCounter: 0,
                  winsCounterLimit: userOnboarding.context.winsCounterLimit,
                },
              });
              clearToasts();
              navigate({
                pathname: './bonus/tutorial/lose',
                replace: true,
              });

              // if (!boxesState.context.pendingCount) {
              //   if (tutorial.matches('done'))
              //     navigate('./p/300/bonus/no-auth', { replace: true });
              // }
            }
          }
          //email is not empty
          if (user.email !== '') {
            if (balance < 1) {
              getActualWelcomeBonus().then((b) => {
                if (b) {
                  navigate('./bonus/welcome', { replace: true });
                } else {
                  errorToast(t(`Insufficient balance`));
                  navigate('./p/500/balance', { replace: true });
                }
              });
            }
          }
        },

        notifySubmission: (_, event) => {
          const boxes = event.data.boxes;
          if (boxes.length === 0) return;
          boxesSend('ADD', { payload: { boxes } });
          const newBalance = Number(balance * 1) - boxes.length * bet;
          setBalance(newBalance);

          logger?.visits <= 2 &&
            userOnboarding.context.bets === 0 &&
            track('FirstBox');

          sendUserOnboarding({
            type: 'BET',
            payload: { boxes: boxes.length },
          });

          !isLogged &&
            logger?.event('box', {
              bet,
              count: boxes.length,
              balance: Number(newBalance?.toFixed(2)),
              //amount: totalPrize,
            });
        },
      },
    }
  );

  function getBoxInGridByXY(price, time) {
    return (
      price &&
      boxesGrid &&
      boxesGrid.find(
        (box) =>
          box.x1 <= price && price <= box.x2 && box.y1 <= time && time <= box.y2
      )
    );
  }

  async function submitBoxes(uniqueSelectedBoxes) {
    const newBoxes = uniqueSelectedBoxes
      .map((box) => {
        const x = price + (box.dx1 + box.dx2) / 2;
        const y = time + (box.dy1 + box.dy2) / 2;
        const gridBox = getBoxInGridByXY(x, y);
        const loseProbability = 1 - 1 / (1 + gridBox.prize); //eg if prize=2 then p=0.7
        const lootBox_ =
          gridBox.prize >= 1.1 &&
          Math.random() <= (loseProbability ^ 2) / (10 * 3 * Math.sqrt(bet)) //eg p=0.70 if pLootBox=0.0049 //0.98%
            ? lootBoxTypes.grade1
            : gridBox.prize >= 1.1 &&
              Math.random() <=
                (loseProbability ^ 2) / (10 * 15 * Math.sqrt(bet)) //eg if p=0.70 pLootBox=0.0049 * 2  = 0.098%
            ? lootBoxTypes.grade2
            : lootBoxTypes.grade0;

        const lootBox = tutorial.hasTag('fixedGrid') ? 1 : lootBox_;
        const prize = lootBox * gridBox.prize * bet;
        return {
          ...gridBox,
          bet: box.bet,
          status: boxStatus.pending,
          lootBox,
          prize,
          originalPrize: gridBox.originalPrize,
          multiplier: !user?.email ? gridBox.prize * lootBox : gridBox.prize,
          id: uuid(),
          createdAt: Date.now(),
        };
      })
      .filter((b) => b.multiplier >= 1);
    const sufficientBoxesCount = (balance / bet) >> 0;
    if (newBoxes.length > 0 && sufficientBoxesCount <= 0)
      throw new Error('Not enough balance');
    const sufficientBoxes = newBoxes.slice(0, sufficientBoxesCount);

    if (user.email === '') return sufficientBoxes;
    if (sufficientBoxes.length === 0) return [];
    else {
      const data = await sendBet(time, bet, sufficientBoxes);
      const { modifiedPrizes, error } = data;
      if (error) {
        logger.event('error', {
          msg: `Send box error: ${error}`,
          time,
          boxes: sufficientBoxes[0],
          boxesCount: sufficientBoxes.length,
        });
        if (error === 'low balance') navigate('/home');
        console.error('Send box error', error);
        return [];
      }
      const modifiedBoxes = sufficientBoxes.map((sb, i) => {
        const modifiedPrize =
          modifiedPrizes?.find((mp) => mp.pos === i)?.prize * 1;
        return {
          ...sb,
          lootBox: modifiedPrize ? lootBoxTypes.grade2 : lootBoxTypes.grade0,
          prize: modifiedPrize ? modifiedPrize * bet : sb.originalPrize * bet, //!
          originalPrize: modifiedPrize ? modifiedPrize : sb.originalPrize,
          multiplier: modifiedPrize ? modifiedPrize : sb.multiplier,
          createdAt: Date.now(),
        };
      });
      return modifiedBoxes;
    }
  }

  const addSelectedBox = throttle((box) => {
    if (!box) return;
    const x = box.x1 + boxSize / 2;
    const y = box.y1;
    const newBox = {
      ...box,
      status: boxStatus.pending,
      x,
      y,
      dx1: box.x1 - price,
      dx2: box.x2 - price,
      dy1: box.y1 - time,
      dy2: box.y2 - time,
      bet,
    };
    boxSelectionSend({ type: 'ADD', payload: { box: newBox } });
  }, 10);

  const location = useLocation();

  const [boxTimeSize, setBoxTimeSize] = useState();

  function onNewPrice(event) {
    //console.log(event); //saves data to axios for using it in emulator
    if (debugPausePrice) return;
    setLastPriceUpdate(Date.now());

    //price
    const newPrice = event.price.value;
    setPrice(newPrice);

    const newBoxSize = event.boxes ? event.boxes.config.price_size : boxSize;
    setBoxSize(newBoxSize); //width in dollars

    //adjust range to maintain aspect ratio of the boxes
    const numberOfBoxes = terminalSize.width > 500 ? 11 : 9;
    setBoxColumns(numberOfBoxes);
    const priceRange = (numberOfBoxes * newBoxSize) / 2;
    setPriceChangePx(
      ((newPrice - price) / (2 * priceRange)) * terminalSize.width
    );
    const boxSizePx = terminalSize.width / numberOfBoxes; //Math.floor(terminalSize.width / numberOfBoxes);
    //const rest = terminalSize.width - (numberOfBoxes * boxSizePx) / 2;
    setBoxSizePx(boxSizePx);
    const timeHistory = Math.round(
      (boxDuration * terminalSize.height) / boxSizePx - timeForBoxes
    );
    setTimeHistory(timeHistory);

    setCentralPoint({
      x: terminalSize.width / 2,
      y: (terminalSize.height * timeHistory) / (timeHistory + timeForBoxes),
    });

    //const boxesTop = (terminalSize.height * timeHistory) / (timeHistory + timeForBoxes)

    setPriceDomain({
      min: newPrice - priceRange,
      max: newPrice + priceRange,
    });

    //Time
    const newTime = event.price.timestamp;
    const oldTime = time || newTime;
    setTime(newTime);

    const minTime = newTime - timeHistory;
    const maxTime = newTime + timeForBoxes;
    setTimeDomain({
      min: minTime,
      max: maxTime,
    });

    boxesSend('UPDATE', { payload: { price: newPrice, time: newTime } });

    //boxes grid
    const newBoxesGrid = [];

    if (!event.boxes) logger?.event('error', { msg: 'missing boxes' });
    else {
      const { config, prizes } = event.boxes;
      if (config.time_size !== boxTimeSize) setBoxTimeSize(config.time_size);

      const newMismatch =
        ((boxesOffset - config.delay) / config.time_size) * boxSizePx;
      setBoxesGridMismatch(newMismatch);

      let countBoxes = 0;
      for (let t = 0; t < Math.min(boxLines, config.time_count); t += 1) {
        for (let p = 0; p < config.price_count; p += 1) {
          const y1 = newTime + config.delay + t * config.time_size;
          const y2 = y1 + config.time_size;
          const x1 =
            newPrice - (newBoxSize * config.price_count) / 2.0 + p * newBoxSize;
          const x2 = x1 + config.price_size;
          const originalPrize = prizes[t][p];
          //const prize = Math.floor(originalPrize * 10) / 10; //TODO - server rounding

          const oldPrize =
            Math.round(
              boxesGrid &&
                boxesGrid.length &&
                //boxesGrid[p * config.time_count + t].prize * 10
                boxesGrid[countBoxes]?.prize * 10
            ) / 10;

          const newPrize = isLogged
            ? originalPrize
            : tutorial.hasTag('fixedGrid')
            ? 25
            : tutorial.hasTag('slowChangesGrid')
            ? time % 10000 === 0 || oldPrize === 25
              ? originalPrize
              : oldPrize
            : Math.floor(originalPrize * 10) / 10 !== 1
            ? originalPrize
            : originalPrize * (1 + 0.1 * Math.floor(Math.random() + 0.15));

          const isHidden =
            x1 < newPrice - priceRange ||
            x2 > newPrice + priceRange ||
            y2 > maxTime;
          if (!isHidden) {
            const box = {
              id: countBoxes,
              x1,
              x2,
              y1,
              y2,
              prize: newPrize,
              originalPrize,
              isPrizeUpdated: oldPrize !== Math.round(newPrize * 10) / 10,
            };
            newBoxesGrid.push(box);
            countBoxes++;
          }
        }
      }
    }
    setBoxesGrid(newBoxesGrid);

    if (!newBoxesGrid[(boxLines - 1) * boxColumns + boxColumns - 1]?.prize)
      logger.event('error', {
        msg: `boxesGrid[last] is null`,
        event,
        grid: newBoxesGrid,
        boxLines,
        boxColumns,
        maxTime,
        oldTime: time,
        newTime,
      });

    //history
    updateData(
      data,
      [
        {
          time: oldTime,
          price: newPrice,
        },
        {
          time: newTime,
          price: newPrice,
        },
      ],
      timeHistory,
      setData
    );

    const randomRange = priceRange * 3;
    if (randomPointData.length)
      updateRandomData(newPrice, randomRange, minTime);
    else
      setRandomPointData([
        ...Array.from(
          Array(Math.min(25, (newTime + boxesOffset - minTime) * 2)).keys()
        ).map((tIdx) => ({
          price: newPrice - randomRange + Math.random() * randomRange * 2,
          time: minTime + tIdx * 500,
          //speed: Math.round(Math.random() * 1),
        })),
        // ...Array.from(
        //   Array(Math.min(100, (newTime + boxesOffset - minTime) * 2)).keys()
        // ).map((tIdx) => ({
        //   price: newPrice - randomRange + Math.random() * randomRange * 2,
        //   time: minTime + tIdx * 500,
        //   speed: Math.round(Math.random() * 1),
        // })),
      ]);
  }

  function updateRandomData(newPrice, randomRange, minTime) {
    const newRandomPointData = [
      ...randomPointData
        ?.filter((p) => p.time >= minTime)
        .map((p) => ({
          ...p,
          time: p.time - (Math.random() > 0.95 ? 1000 : 0), // * (1 + p.speed),
          price:
            p.price >= newPrice - randomRange &&
            p.price <= newPrice + randomRange
              ? p.price
              : newPrice -
                randomRange +
                ((p.price + randomRange - (newPrice % (2 * randomRange))) %
                  (2 * randomRange)),
        })),
      ...[1].map(() => ({
        price: newPrice - randomRange + Math.random() * randomRange * 2,
        time: time + boxesOffset,
        //speed: Math.round(Math.random() * 1),
      })),
    ];
    setRandomPointData(newRandomPointData);
  }

  useInterval(() => {
    if (debugPausePrice) return;

    //mitigate viewport zoom on double tap on ios devices
    const viewportmeta = document.querySelector('meta[name=viewport]');
    viewportmeta &&
      viewportmeta.setAttribute(
        'content',
        'initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0'
      );

    //mitigate remaining selection issue
    if (pendingAnimation.current > 0) forceUpdate();

    //mitigate page freezing in safari
    setLastUpdate(Date.now());
    if (!debugPausePrice && lastUpdate - lastPriceUpdate > 3000) {
      setPrice(undefined);
      if (location.pathname.replace(/\/+$/, '') === '/app')
        navigate('/app/price', { replace: true });
    }
  }, 300);

  usePrices(onNewPrice);

  const [goAfterLogin, setGoAfterLogin] = useState(null);
  const onLogin = async (email, pathname) => {
    boxesSend('RESET');
    nativeApp.haptics('success');
    initUser(email).then((res) => {
      if (bet < res?.minBet) setBet(res?.minBet ?? 1);
      setGoAfterLogin(pathname ? pathname : './');
      navigate('./', { replace: true });
    });
  };

  //after Login handle
  useEffect(() => {
    if (!goAfterLogin) return;
    if (goAfterLogin !== './') {
      setGoAfterLogin(null);
      navigate(goAfterLogin, { replace: true });
      return;
    }
    if (streakBonus && !streakBonus?.disabled) {
      setGoAfterLogin(null);
      navigate('./bonus/streak', { replace: true });
    }
  }, [goAfterLogin, streakBonus?.disabled]);

  const { track } = useAnalyticsContext();

  const onClaimBonusError = async (bonus, error) => {
    if (bonus) {
      if (bonus?.typeId === 'ChallengeBonus') {
        sendUserOnboarding({
          type: 'RESET',
          payload: { winsCounter: 0 },
        });
      }

      errorToast(
        t(`Can not claim bonus: {{error}}`, {
          error: 'try again later',
          autoClose: 2000,
        })
      );
      logger?.event('error', { msg: 'Can not claim bonus', error });
    }
    navigate('/app', { replace: true });
  };

  const updateBonusData = async () => {
    invalidateBonuses();
    const newChallengeBonus = await getActualChallengeBonus();
    if (newChallengeBonus) {
      sendUserOnboarding({
        type: 'RESET',
        payload: {
          winsCounter: newChallengeBonus?.progress,
          winsCounterLimit: newChallengeBonus?.params?.limit,
        },
      });
    }
  };

  const onClaimBonus = async (bonus) => {
    const bonusAmount = bonus?.claimedAmount
      ? Number(bonus.claimedAmount * 1)
      : 0;
    const newBalance = Number(balance * 1) + bonusAmount;

    logger?.event('bonus', {
      claimedAmount: bonusAmount,
      oldBalance: balance,
      newBalance: newBalance,
      descr: bonus.typeId,
    });

    setBalance(newBalance);

    setLastBonusToastTime(0);
    if (bonus.typeId === 'ChallengeBonus') {
      track(`ChallengeBonus${bonus?.params?.limit}`);
      navigate('./league', { replace: true });
      updateBonusData();
      return;
      /*
      const streakBonus = await getActualStreakBonus();
      if (!!streakBonus && !streakBonus.disabled)
        navigate('/app/bonus/streak', { replace: true });
      else {
        navigate('./league', { replace: true });
        updateBonusData();
      }
      return;
      */
    }

    if (bonus.typeId === 'StreakBonus') {
      track(`StreakBonus${bonus?.params?.dayIndex}`);
      if (isNativeApp()) navigate('/app/shop', { replace: true });
      else navigate('/app', { replace: true });
      return;
    }

    if (bonus.typeId === 'AdBonus') {
      track(`HealthBonus`);
    }

    if (bonus.typeId === 'WelcomeBonusFreeBoxes') {
      nativeApp?.requestReview();
      navigate('/app/bonus/push', { replace: true });
      return;
    }

    if (bonus.typeId === 'tutorial') {
      setTimeout(
        () => {
          sendTutorial({ type: 'CLAIMED' });
        },
        bonusAmount ? 2500 : 0
      );
    }

    /*
    if (bonus.typeId === 'PushNotificationsBonus') {
      const streakBonus = await getActualStreakBonus();
      if (!!streakBonus && !streakBonus.disabled)
        navigate('/app/bonus/streak', { replace: true });
      return;
    }
*/

    navigate('/app', { replace: true });
  };

  const onRegister = (email) => {
    initUser(email).then(() => {
      getActualBonuses();
      setBet(1);
      track('RegistrationCompleted');
    });
  };

  //Triggering tutorial events
  useInterval(
    () => {
      if (user?.email || isEmulator) return;

      if (tutorial.matches('waitFor1stWin')) {
        //&& !boxesState.context.pendingCount
        if (userOnboarding?.context?.winsCounter > 0) {
          sendTutorial({ type: 'WIN' });
          clearToasts();
          navigate({
            pathname: './p/2500/bonus/tutorial/win',
            state: location.pathname,
            search: createSearchParams({
              name: `${t('Great job')}!`,
              amount: tutorial.context.amount,
              color: tutorial.context.color,
              limit: 1, //userOnboarding.context.winsCounterLimit,
              progress: userOnboarding.context.winsCounter,
            }).toString(),
            replace: true,
          });

          //Give the 1st challenge
          setTimeout(() => {
            boxesSend('RESET');
            sendUserOnboarding({
              type: 'RESET',
              payload: {
                winsCounter: 0, //userOnboarding.context.winsCounter,
                winsCounterLimit: 5,
              },
            });
          }, 4000);

          return;
        }
      }

      if (
        tutorial.matches('waitFor1stChallenge') &&
        userOnboarding?.context?.winsCounter >=
          userOnboarding?.context?.winsCounterLimit // &&
        //!boxesState.context.pendingCount
      ) {
        sendTutorial({ type: 'WIN' });
        clearToasts();
        navigate({
          pathname: './p/500/bonus/tutorial',
          state: location.pathname,
          search: createSearchParams({
            name: t('You did it'),
            amount: tutorial.context.amount,
            color: tutorial.context.color,
            limit: userOnboarding.context.winsCounterLimit,
            progress: userOnboarding.context.winsCounter,
          }).toString(),
          replace: true,
        });

        //Give the 2nd challenge
        setBet(1);
        setTimeout(() => {
          boxesSend('RESET');
          sendUserOnboarding({
            type: 'RESET',
            payload: {
              winsCounter: 0,
              winsCounterLimit: 10,
            },
          });
        }, 500);

        return;
      }

      if (
        tutorial.matches('done') &&
        userOnboarding?.context?.winsCounter >=
          userOnboarding?.context?.winsCounterLimit
      )
        if (location.pathname.replace(/\/+$/, '') === '/app') {
          clearToasts();
          navigate('./p/2500/bonus/no-auth', { replace: true });
          return;
        }

      if (
        location.pathname.replace(/\/+$/, '') === '/app' &&
        !boxesState.context.pendingCount &&
        balance < bet &&
        userOnboarding.context.winsCounter <
          userOnboarding.context.winsCounterLimit
      ) {
        setBalance(tutorial.context.restartAmount);
        sendUserOnboarding({
          type: 'RESET',
          payload: {
            winsCounter: 0,
            winsCounterLimit: userOnboarding.context.winsCounterLimit,
          },
        });
        clearToasts();
        boxesSend('RESET');
        setBet(1);
        navigate({
          pathname: './bonus/tutorial/lose',
          search: createSearchParams({
            name: t('First win bonus'),
            amount: tutorial.context.amount,
            color: tutorial.context.color,
            limit: 1, //userOnboarding.context.winsCounterLimit,
            progress: userOnboarding.context.winsCounter,
          }).toString(),
          replace: true,
        });

        return;
      }
    },
    !user?.email && !isEmulator ? 300 : null
  );

  const topMenuHeight = 70;
  const bottomMenuHeight =
    true || isNativeApp() || isPwa() || isTelegram() ? (isIos() ? 80 : 62) : 0;
  //const bottomMenuHeight = isNativeApp() || isPwa() ? 80 : 0;

  const isPremium = user?.premiumExpiresAt >= Date.now();

  return (
    <>
      <Header
        title="Stock Market Simulator"
        description={`Test your prediction skills. The stock game is based on live realtime market data`}
      />
      <SvgStyles />
      <ToastContainer />
      <WinToastContainer />

      <Routes>
        <Route
          path="/" //Components that shown only at /app
          element={
            <div>
              <SystemDialog
                title={t('Update app')}
                show={updateIsRequired}
                actions={[`OK`]}
                message={`${t('New version is available')}`}
                onAction={() => updateApp()}
              />
            </div>
          }
        />
        <Route
          path="/start"
          element={
            <ConnectionLoader
              price={price}
              centralPoint={centralPoint}
              onConnecting={() => setIsConnected(false)}
              onConnected={async () => {
                setIsConnected(true);
                const streakBonus = await getActualStreakBonus();
                if (streakBonus && !streakBonus?.disabled) {
                  navigate('./bonus/streak', { replace: true });
                } else {
                  navigate('/app', { replace: true });
                }
              }}
            />
          }
        />
        <Route
          path="/price"
          element={
            <ConnectionLoader
              price={price}
              skipSplash
              centralPoint={centralPoint}
              onConnecting={() => setIsConnected(false)}
              onConnected={() => {
                navigate('/app', { replace: true });
                setIsConnected(true);
              }}
            />
          }
        />

        <Route path="/p/:delay/*" element={<PendingRedirect />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/settings/*" element={<Settings onLogout={onLogout} />} />

        {user.email && (
          <>
            <Route path="/balance/*" element={<Balance />} />
            <Route path="/shop/*" element={<Shop shortView />} />
            <Route
              path="/deposit/*"
              element={
                <DepositDialog
                  email={user?.email}
                  assets={assets}
                  history={history}
                  balance={balance}
                  winBalance={winBalance}
                  logger={logger}
                />
              }
            />
            <Route path="/premium/*" element={<Premium />} />

            <Route
              path="/leaderboard/*"
              element={
                <Leaderboard
                  email={user.email}
                  winBalance={winBalance}
                  logger={logger}
                />
              }
            />
            <Route
              path="/league"
              element={
                <LeagueStatus
                  email={user.email}
                  winBalance={winBalance}
                  logger={logger}
                />
              }
            />

            {/*
            <Route
              path="/withdraw/*"
              element={
                <WithdrawDialog
                  email={user.email}
                  winBalance={winBalance}
                  logger={logger}
                  onReset={() => {
                    //ReseetWinBalance();
                  }}
                  onWithdraw={(withdrawAmount, withdrawalAddress) => {
                    const newWinBalance = winBalance - withdrawAmount * 100;
                    setWinBalance(newWinBalance);

                    toast(
                      t(`Withdrawal requested {{amount}} USDT`, {
                        amount: withdrawAmount,
                      }),
                      {
                        delay: 1000,
                      }
                    );

                    logger?.event('withdraw', {
                      amount: withdrawAmount,
                      asset: 'USDT',
                      address: withdrawalAddress,
                      balance: balance,
                    });

                    if (
                      process.env.NODE_ENV !== 'production' &&
                      winBalance - withdrawAmount < 0
                    ) {
                      setBalance(999);
                      setWinBalance(0);
                    }
                  }}
                />
              }
            /> */}
          </>
        )}
        {!user.email && (
          <>
            <Route
              path="/settings/*"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
            <Route
              path="/balance/*"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
            <Route
              path="/deposit/*"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
            <Route
              path="/withdraw/*"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
            <Route
              path="/bonus"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
            <Route
              path="/shop"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
          </>
        )}
        <Route
          path="/auth/*"
          element={
            <Auth
              email={user?.email}
              logger={logger}
              onLogin={onLogin}
              onRegister={onRegister}
            />
          }
        />
        <Route
          path="/bonus/*"
          element={
            <BonusListDialog
              email={user?.email}
              logger={logger}
              onClaimBonus={onClaimBonus}
              onClaimBonusError={onClaimBonusError}
              onLogin={onLogin}
              challengeBonusProgress={userOnboarding.context.winsCounter}
              onRestartGame={onRestartGame}
            />
          }
        />
      </Routes>

      <div
        className="container pt-3 pb-3"
        style={{
          height: `${topMenuHeight}px`,
          width: '100%',
        }}
      >
        <div className="row">
          <div
            className="col-4"
            onClick={() => {
              if (user.email === '') {
                navigate('./auth/login', { replace: true });
              } else {
                navigate('./settings/account', { replace: true });
              }
            }}
          >
            <div className="col">
              <div className="text-default">
                <img
                  src="/img/user.svg"
                  alt=""
                  style={{
                    width: '18px',
                    marginTop: '-3px',
                    marginLeft: '4px',
                    marginRight: '4px',
                    opacity: 0.9,
                  }}
                />
              </div>
              <div className="text-muted text-nowrap text-uppercase lh-1">
                <small>{!user.email ? t('Login') : t('Account')}</small>
              </div>
            </div>

            {/* <div
              className="text-start text-default"
              style={{
                opacity: isConnected ? 1 : 0,
              }}
            >
              Bitcoin
              <img
                src="/img/btc.svg"
                alt=""
                style={{
                  width: '18px',
                  marginTop: '-3px',
                  marginLeft: '4px',
                  marginRight: '4px',
                  opacity: 0.9,
                }}
              />
            </div>
            <div className="text-start text-muted text-nowrap text-uppercase lh-1">
              {false && <small>{t('Asset price')}</small>}
              <small>{t('Asset')}</small>
            </div> */}
          </div>

          <div className="col-4">
            {(user?.email ||
              (!user?.email && tutorial.hasTag('showBetControl'))) && (
              <>
                <div
                  className=""
                  role="button"
                  onClick={() => {
                    if (user.email === '') {
                      navigate('./auth/sign-up', { replace: true });
                    } else {
                      if (
                        winBalance > 0 ||
                        process.env.NODE_ENV !== 'production'
                      )
                        navigate('./league', { replace: true });
                    }
                  }}
                >
                  <div
                    className="text-center glow-small text-primary-accent text-monospace"
                    style={{ fontWeight: 400 }}
                  >
                    {(user?.email && winBalance) ||
                    userOnboarding.context.prize ? (
                      <CountUpDown
                        duration={2}
                        decimals={bet < 10 ? 1 : 0} //{winBalance > 1000 ? 0 : 1}
                        value={
                          !user?.email
                            ? userOnboarding.context.prize
                            : winBalance
                        }
                        animate={changeType.up}
                      />
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="text-center text-muted text-uppercase lh-1">
                    {((user?.email && winBalance > 0) ||
                      userOnboarding.context.prize > 0) && (
                      <small>
                        {false && t('Win')}
                        {t('Score')}
                        {
                          //winBalance > 0 && <span className="btn-withdraw-dropdown" />
                        }
                      </small>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-4">
            <div
              className=""
              role="button"
              onClick={() => {
                if (user.email === '') {
                  navigate('./auth/sign-up', { replace: true });
                } else {
                  navigate('./balance', { replace: true });
                }
              }}
            >
              <div className="row flex-nowrap">
                {hideGambling && (
                  <div className="col">
                    <div className="text-end text-default">
                      <img
                        src="/img/user-small.svg"
                        alt=""
                        style={{
                          width: '18px',
                          marginTop: '-3px',
                          marginLeft: '4px',
                          marginRight: '4px',
                          opacity: 0.9,
                        }}
                      />
                    </div>
                    <div className="text-end text-muted text-nowrap text-uppercase lh-1">
                      <small>{!user.email ? t('Login') : t('Account')}</small>
                    </div>
                  </div>
                )}
                {!hideGambling && (
                  <div className="col">
                    <div
                      className={`text-end text-monospace${
                        false && bonuses.length > 0 ? ' text-primary' : ''
                      }`}
                    >
                      {false && bonuses.length > 0 && (
                        <span className="text-primary">
                          <sup
                            style={{
                              fontWeight: 100,
                              fontSize: '0.4rem',
                              top: '-0.2rem',
                              left: '-0.7rem',
                            }}
                          >
                            ●
                          </sup>
                        </span>
                      )}
                      {user?.email && newBonusesCount > 0 && (
                        <div style={{ display: 'inline-block' }}>
                          <div className="pulsating-circle" />
                        </div>
                      )}
                      <CountUpDown
                        value={bet < 10 ? balance : Math.floor(balance)}
                        decimals={bet < 10 ? 1 : 0} //{balance > 1000 ? 0 : 1}
                        animate={changeType.up}
                      />
                    </div>

                    <div className="text-end text-muted text-uppercase lh-1">
                      <small>{t('Balance')} </small>
                      {/* <small>BOXO</small> */}
                    </div>
                  </div>
                )}

                {/*                 <div className="col-2">
                  <span
                    className={`text-end forward-symbol${
                      false && bonuses.length > 0 ? ' text-primary' : ''
                    }`}
                    style={{
                      position: 'relative',
                      opacity: 0.7,
                      top: '-3px',
                      left: '-5px',
                    }}
                  />
                </div>
 */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isLoading &&
        !price &&
        false &&
        location.pathname.replace(/\/+$/, '') === '/app' && (
          <Loader
            onClick={() => {
              if (!price && lastUpdate - lastPriceUpdate > 4000) {
                navigate('/home', { replace: true });
              }
            }}
          />
        )}

      {false && (
        <DebugControl
          //text1={`bonus=${userOnboarding.context.emptyBoxesExpiredBet}`}
          //text1={time}
          //text1={`isReady=${ads?.isReady() === true}`}
          //text3={Math.round((Date.now() - ads?.lastShowTime) / 100 / 60) / 10}
          /*text2={`win/spent=${(
            userOnboarding.context.emptyBoxesExpiredBet +
            userOnboarding.context.prize
          ).toFixed(1)}/${(
            userOnboarding.context.boxesWinBet +
            userOnboarding.context.boxesExpiredBet
          ).toFixed(1)}`}
         text3={`RTP=${(
            (100 *
              (userOnboarding.context.prize +
                userOnboarding.context.emptyBoxesExpiredBet)) /
            (userOnboarding.context.boxesWinBet +
              userOnboarding.context.boxesExpiredBet)
          ).toFixed(2)}%`}
          */
          onPause={() => {
            setDebugPausePrice(!debugPausePrice);
            setIsBoxesGridOverlayShown(false);
          }}
        />
      )}

      {/* {!!price && !user.email && isConnected && !isEmulator && (
        <SoundControl top={`${centralPoint.y + 150}px`} />
      )} */}

      {!!price && false && (
        <Speedometr
          showing={
            (user?.email && isConnected) ||
            //(!user?.email && tutorial.hasTag('showBetControl'))
            (!user?.email && !tutorial.hasTag('hideProgress'))
          }
          top={`${centralPoint.y + 180}px`}
          //zIndex={tutorial.matches('waitIncreaseBet') ? 104 : undefined}
          fireSpeedUp={boxSelectionState.value}
        />
      )}

      {!!price && isConnected && sponsoredBonus && (
        <GiftJump
          gitfType="gift"
          top={centralPoint.y + topMenuHeight}
          size={terminalSize.width / 2}
          starting={boxSelectionState.value === 'submitting'}
          onClick={() => {
            navigate('./bonus/sponsored', {
              state: '/app',
              replace: true,
            });
          }}
        />
      )}

      {!!price &&
        isConnected &&
        healthBonus &&
        !sponsoredBonus &&
        !healthBonus?.disabled &&
        ads.isReady() && (
          <GiftJump
            giftType="pill"
            top={centralPoint.y + topMenuHeight}
            size={terminalSize.width / 2}
            starting={boxSelectionState.value === 'submitting'}
            onClick={() => {
              navigate('./bonus/health', {
                state: '/app',
                replace: true,
              });
            }}
          />
        )}

      {!!price && !hideGambling && (
        <BetControl
          label={`$${priceFmt(boxoToUsd(bet), 5, 0)}`}
          bet={bet}
          maxBet={
            user?.email
              ? Math.max(user?.maxBet, user?.maxBetAsBalanceShare * balance)
              : 5
          }
          //minBet={user?.minBet}
          top={`${centralPoint.y + 180}px`}
          showing={
            (user?.email && isConnected) ||
            (!user?.email && tutorial.hasTag('showBetControl'))
          }
          zIndex={tutorial.matches('waitIncreaseBet') ? 104 : undefined}
          onSetBet={(b) => {
            setBet(b);
            clickSound();
            nativeApp.haptics('heavy');
            logger?.event('bet', { value: b });
            if (b > 1 && tutorial.matches('waitIncreaseBet'))
              sendTutorial({ type: 'INCREASED' });
          }}
          fireSpeedUp={boxSelectionState.value}
          onError={() => {
            //errorToast(t(`Bet exceeds league limit`));
            errorToast(t(`Value exceeds league limit`));
            //errorSound();
            nativeApp.haptics('error');
          }}
        />
      )}

      <AnimatedCongrats
        state={animatedCongratsState}
        from={centralPoint}
        to={{ x: centralPoint.x, y: -10 }}
      />

      <AnimatedDot
        repeat={pendingAnimation.current}
        from={centralPoint}
        to={{ x: centralPoint.x * 2 - 35, y: 0 }}
        onComplete={onAnimatioCompete}
      />

      {/* <BlurPanel
        height="50vh"
        top={topMenuHeight + terminalSize.height - boxSizePx * boxLines}
      /> */}

      <BackgoundLight height={centralPoint.y} top={topMenuHeight} />
      {/* <BackgoundLight
        height={centralPoint.y}
        top={centralPoint.y + topMenuHeight}
      /> */}

      <div
        style={{
          '--box-appear-time-offset': `${boxesGridMismatch}px`,
          '--box-appear-price-offset': `${
            //Date.now() - lastPriceUpdate < 200
            Date.now() - lastPriceUpdate > 50 ? 0 : priceChangePx
          }px`,
          '--time-offset': `${boxSizePx / 4}px`,
          '--price-offset': `${priceChangePx}px`,
          height: `calc(100% - ${bottomMenuHeight}px - ${topMenuHeight}px)`,
          width: '100%',
        }}
      >
        <ResponsiveContainer
          ref={boxTerminalRef}
          width="100%"
          height="100%"
          minHeight={100}
          onResize={(w, h) => setTerminalSize({ width: w, height: h })}
        >
          <ScatterChart
            style={{ touchAction: 'none' }}
            display={price ? '' : 'none'}
            margin={0}
          >
            <XAxis
              hide={true}
              tickLine={false}
              axisLine={false}
              tick={false}
              type="number"
              dataKey="price"
              domain={([dataMin, dataMax]) => {
                return [priceDomain.min, priceDomain.max];
              }}
              orientation="top"
            />
            <YAxis
              hide={true}
              axisLine={false}
              tickLine={false}
              tick={false}
              reversed={true}
              type="number"
              dataKey="time"
              domain={([dataMin, dataMax]) => {
                return [timeDomain.min, timeDomain.max];
              }}
            />

            {/* Vertical Y axis */}

            {/* <ReferenceLine
              segment={[
                { x: price, y: time - timeHistory + 10 },
                { x: price, y: time + 150 },
              ]}
              className="reference-dashed-line"
            /> */}

            {isConnected && (
              <ReferenceLine
                segment={[
                  { x: price, y: time - boxDuration },
                  { x: price, y: time - boxDuration * 3 },
                ]}
                className="reference-dashed-line"
              />
            )}

            {/* Price */}

            {isConnected && (
              <Scatter
                className={
                  Date.now() - lastPriceUpdate > 50 ? 'line' : 'line shifted'
                }
                data={data}
                line={{
                  stroke: 'var(--theme-muted)',
                  strokeOpacity: 0.4,
                  //stroke: 'var(--theme-primary-accent)',
                  strokeWidth: 1,
                }}
                //lineJointType="monotoneY"
                width={0}
                shape={() => {}}
                isAnimationActive={false}
              />
            )}

            {/* Low panel hiding price transitions */}
            <ReferenceArea
              x1={priceDomain.min}
              x2={priceDomain.max}
              y1={time - 430}
              y2={time + timeForBoxes}
              fill="var(--theme-background)"
              fillOpacity={1}
            />

            {/* Left or right panel hiding price transitions */}
            <ReferenceArea
              x1={priceChangePx < 0 ? priceDomain.min : price + 0.2}
              x2={priceChangePx < 0 ? price - 0.2 : priceDomain.max}
              y1={time - 600}
              y2={time}
              fill={
                Date.now() - lastPriceUpdate > 50 &&
                Date.now() - lastPriceUpdate < 200 &&
                Math.abs(priceChangePx) > 3
                  ? 'var(--theme-background)'
                  : 'transparent'
              }
              fillOpacity={1}
            />

            {/* Static piece of the price */}

            {isConnected && (
              <Scatter
                data={[
                  { time, price },
                  { time: time - 440, price },
                ]}
                line={{
                  stroke: 'var(--theme-muted)',
                  strokeOpacity: 0.4,
                  //stroke: 'var(--theme-primary-accent)',
                  strokeWidth: 1,
                }}
                width={0}
                shape={() => {}}
                isAnimationActive={false}
              />
            )}

            {/* X axis */}

            {/* <ReferenceLine y={time} className="reference-line" /> */}

            {isConnected && (
              <ReferenceLine
                segment={[
                  { x: price - boxSize * 3, y: time },
                  { x: price - boxSize * 1, y: time },
                ]}
                className="reference-dashed-line"
              />
            )}

            {isConnected && (
              <ReferenceLine
                segment={[
                  { x: price + boxSize * 1, y: time },
                  { x: price + boxSize * 3, y: time },
                ]}
                className="reference-dashed-line"
              />
            )}

            {/* Y axis part 2 */}

            {/* <ReferenceLine
              segment={[
                { x: price, y: time + boxDuration },
                { x: price, y: time + boxDuration * 3 },
              ]}
              className="reference-dashed-line"
            /> */}

            {/* Random dots */}

            <Scatter
              className={
                Date.now() - lastPriceUpdate > 50 ? 'dots' : 'dots shifted'
              }
              data={randomPointData}
              shape={(props) => (
                <svg
                  {...props}
                  width="1"
                  height="1"
                  viewBox="0 0 1 1"
                  overflow="visible"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <text fill="var(--theme-primary)" opacity={0.4}>
                    {Math.round(Math.random() * 9)}
                  </text>
                  {/* <text y="13" fill="var(--theme-primary)" opacity={0.2}>
                    { Math.round(Math.random() * 9) }
                  </text> 
                  <text y="26" fill="var(--theme-primary)" opacity={0.08}>
                    {Math.round(Math.random() * 9)}
                  </text>
                  <text y="39" fill="var(--theme-primary)" opacity={0.04}>
                    {Math.round(Math.random() * 9)}
                  </text> */}
                </svg>
              )}
              isAnimationActive={false}
            />

            {/* Price label */}

            <ReferenceLine
              segment={[
                { x: price, y: time },
                { x: price, y: time },
              ]}
              className="reference-dashed-line"
            >
              <Label
                className="label muted"
                offset={15}
                position={'bottom'}
                style={{
                  opacity: isConnected ? 1 : 0,
                  //transition: 'opacity 0.5s',
                }}
                value={`$${priceFmt(price, 2, 2)}`}
              />

              {!isPwa() &&
                !isNativeApp() &&
                !isTelegram() &&
                isBoxesGridShown && (
                  <Label
                    display="none"
                    x={price}
                    y={time}
                    className={`label text-uppercase 
                ${
                  price
                    ? userOnboarding.context.bets === 0
                      ? ' visible'
                      : ' visible muted'
                    : ' hidden'
                }        
                `}
                    offset={75}
                    position={'bottom'}
                    value={
                      //t('Select a box to make a bet')
                      //t('Select a box to play')
                      //t('Swipe for boxes')
                      // true
                      //   ? t('Select boxes to play')
                      //   : t('Select boxes to buy')

                      t('Tap boxes to hit Bitcoin')
                    }
                    style={{
                      opacity: isConnected ? 1 : 0,
                      transition: 'opacity 0.5s',
                    }}
                  />
                )}
            </ReferenceLine>

            {/* Small dots for delta price */}

            {/* <ReferenceDot
              x={price - boxSize * 3}
              y={time + 20}
              r={1}
              fill="var(--theme-muted)"
              strokeWidth={0}
            />

            <ReferenceDot
              x={price + boxSize * 3}
              y={time + 20}
              r={1}
              fill="var(--theme-muted)"
              strokeWidth={0}
            /> */}

            <ReferenceLine
              display="none"
              segment={[
                { x: price - boxSize * 3, y: time + 150 },
                { x: price - boxSize * 3, y: time - timeHistory + 10 },
              ]}
              className="reference-dashed-line"
            >
              <Label
                style={{
                  opacity: isConnected ? 1 : 0,
                  transition: 'opacity 0.5s',
                }}
                offset={15}
                position={'bottom'}
                value={`-${priceFmt(boxSize * 3, 2, 2)}`}
                stroke={0}
                fill="var(--theme-muted)"
              />
            </ReferenceLine>

            <ReferenceLine
              display="none"
              segment={[
                { x: price + boxSize * 3, y: time + 150 },
                { x: price + boxSize * 3, y: time - timeHistory + 10 },
              ]}
              className="reference-dashed-line"
            >
              <Label
                style={{
                  opacity: isConnected ? 1 : 0,
                  transition: 'opacity 0.5s',
                }}
                offset={15}
                position={'bottom'}
                value={`+${priceFmt(boxSize * 3, 2, 2)}`}
                stroke={0}
                fill="var(--theme-muted)"
              />
            </ReferenceLine>

            {isConnected && (
              <ReferenceDot
                className={`dotArea ${
                  !boxesState?.context?.lastExecutedTime ||
                  Date.now() - boxesState.context?.lastExecutedTime >
                    boxTimeSize * 1.15
                    ? boxesState.context?.getCloser
                      ? 'getCloser'
                      : 'standBy'
                    : ''
                }`}
                x={price}
                y={time}
                r={15 * (boxesState.context?.getCloser * 2 || 1)}
                fill="var(--theme-default)"
                stroke="var(--theme-default)"
                strokeWidth={15 * (boxesState.context?.getCloser * 2 || 1)}
              />
            )}

            {/* Dot - old white version */}

            {isConnected && (
              <ReferenceDot
                x={price}
                y={time}
                r={4}
                fill="var(--theme-primary)"
                stroke="var(--theme-default)"
                strokeWidth={3}
              />
            )}

            {/* BTC dot */}

            {isConnected && (
              <ReferenceArea
                shape={(props) => (
                  <DotCoin x={centralPoint.x} y={centralPoint.y} />
                )}
              />
            )}

            {boxesState?.context?.boxes
              ?.filter(
                (box) =>
                  box.status !== boxStatus.executed ||
                  Date.now() - box.executedAt < 2000
              )
              .map((box) => {
                const isExpired =
                  box.status === boxStatus.expired && time > box.y2 + 3000;
                return (
                  <ReferenceArea
                    id={box.id}
                    className={
                      `box${
                        box.status === boxStatus.executed ? ' executed' : ''
                      }
                      ${
                        Date.now() - box.createdAt < 50 // 35 msec to set a shift is it's a new box
                          ? ' new'
                          : Date.now() - lastPriceUpdate > 50
                          ? ''
                          : ' shifted'
                      }`
                      // otherwise 50 msec to get a new price and shift the box as if price is not yet changed
                      // The rest of the time .box class makes an animated transition to real position (0 offset)
                    }
                    shape={
                      box.lootBox >= 0 && ( // >1
                        <SuperBox
                          //opacity={box.status === boxStatus.expired ? 0.1 : 1}
                          isExpired={isExpired}
                          box={box}
                          boxSizePx={box.boxSizePx}
                        />
                      )
                    }
                    key={'box' + box.id}
                    x1={box.x1}
                    x2={box.x2}
                    y1={box.y1}
                    y2={box.y2}
                    ifOverflow="visible"
                    //fillOpacity={box.status === boxStatus.executed ? 0.4 : 0.99}
                    //opacity={box.status === boxStatus.executed ? 0.1 : 0.99}
                  >
                    {true && ( //box.status !== boxStatus.executed
                      <Label
                        fill={
                          isExpired
                            ? 'var(--theme-muted)'
                            : 'var(--theme-default)'
                        }
                        opacity={box.status === boxStatus.executed ? 0.1 : 1}
                        position={'center'}
                        value={`${priceFmtThousands(
                          box.prize,
                          box.prize >= 10 && box.prize < 100
                            ? 1
                            : box.prize >= 100 && box.prize < 1000
                            ? 0
                            : -1
                        )}`}
                        style={{
                          fontSize: '0.765rem',
                          userSelect: 'none',
                          fontWeight: '600',
                        }}
                      ></Label>
                    )}
                  </ReferenceArea>
                );
              })}
          </ScatterChart>
        </ResponsiveContainer>
      </div>

      {
        //Grid control
        true && (
          <div
            style={{
              opacity: isConnected ? 1 : 0,
              transition: 'opacity 0.5s',
            }}
          >
            <div
              //className="row g-0"
              style={{
                position: 'absolute',
                zIndex: 10,
                width: '100%',
                //height: 350, //boxSizePx * boxLines,
                bottom: bottomMenuHeight,
              }}
            >
              {!!price &&
                boxesGrid &&
                isBoxesGridShown &&
                boxLinesArray.map((_, lineIdx) => {
                  return (
                    <div
                      className="d-flex flex-row flex-nowrap" //"row flex-nowrap"
                      key={lineIdx}
                      style={{
                        margin: 0,
                        width: '100%',
                      }}
                    >
                      {boxColumnsArray.map((_, columnIdx) => {
                        const i = lineIdx * boxColumns + columnIdx;
                        const box = boxesGrid[i] ?? {};
                        const size = `${boxSizePx}px`;
                        const isSelected =
                          boxSelectionState?.context.selection?.findIndex(
                            (b) => b.id === box.id
                          ) >= 0;
                        return (
                          <div //x1={box.x1} x2={box.x2} y1={box.y1} y2={box.y2}
                            style={{
                              width: size,
                              height: size,
                            }}
                            className={`grid-box glow- ${
                              box.isPrizeUpdated ? 'updated' : ''
                            }${isSelected ? ' selected' : ''}${
                              box.prize < 1 ? ' disabled' : ''
                            }${
                              // <= 1
                              isSelected && balance < bet ? ' invalid' : ''
                            }`}
                            key={'gridBox' + i}
                            box_id={i}
                          >
                            <SuperBox
                              fillOpacity={1}
                              //   fillOpacity={
                              //   isSelected &&
                              //   boxSelectionState.matches('submitting')
                              //     ? 0
                              //     : 1
                              // }
                              width="100%"
                              height="100%"
                              box={{ ...box, multiplier: box.prize }}
                            />

                            <div
                              className="text-default"
                              style={{ position: 'absolute' }}
                            >
                              {/* {`${priceFmt(box.prize * bet, 1, 1)}`} */}

                              {`${priceFmtThousands(
                                box.prize * bet,
                                box.prize * bet >= 10 && box.prize * bet < 100
                                  ? 1
                                  : box.prize * bet >= 100 &&
                                    box.prize * bet < 1000
                                  ? 0
                                  : -1
                              )}`}
                            </div>

                            {
                              // `× ${priceFmt(box.prize)}`
                              // bet >= 1000
                              //  ? `${priceFmtThousands(box.prize * bet, bet < 10000 ? 1 : 0)}`
                              //  : `${priceFmt(box.prize * bet, bet < 10 ? 1 : 0)}`
                              // {box.prize === 1 ? '× 1.' : `× ${priceFmt(box.prize)}`
                              // {box.prize === 1 && <span></span>}
                            }
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>

            {!!price &&
              !user?.email &&
              //userOnboarding.context.bets === 0 &&
              tutorial.hasTag('waitBoxes') &&
              balance >= 1 &&
              boxesState.context.pendingCount === 0 &&
              !boxSelectionState?.context.selection?.length &&
              isConnected && (
                <>
                  {!tutorial.matches('waitFor1stWin') && (
                    <AnimatedHand
                      type="area"
                      y={`calc(100% - ${
                        bottomMenuHeight + (boxLines - 0.6) * boxSizePx
                      }px)`}
                      x={centralPoint.x - (boxSizePx * 2) / 2}
                      width={boxSizePx * 2}
                      height={boxSizePx * 2.5}
                      animateSelection={{ boxSizePx, xBoxes: 3, yBoxes: 4 }}
                    />
                  )}
                  {tutorial.matches('waitFor1stWin') && (
                    <AnimatedHand
                      type="horizontal"
                      y={`calc(100% - ${
                        bottomMenuHeight + (boxLines - 0.6) * boxSizePx
                      }px)`}
                      x={centralPoint.x - (boxSizePx * 2) / 1}
                      width={boxSizePx * 4}
                      height={boxSizePx * 3}
                      animateSelection={{ boxSizePx, xBoxes: 5 }}
                    />
                  )}
                </>
              )}

            <div
              //BTC price invisible panel
              onClick={() => navigate('./price', { replace: true })}
              style={{
                position: 'absolute',
                zIndex: 20,
                top: centralPoint.y + topMenuHeight + 10,
                left: centralPoint.x,
                width: '80px',
                height: '50px',
                transform: 'translate(-50%, -50%)',
                //backgroundColor: 'red',
                opacity: 0,
              }}
            />

            {isBoxesGridOverlayShown && (
              <div
                //Touch panel
                style={{
                  position: 'absolute',
                  zIndex: 20,
                  width: '100%',
                  //backgroundColor: 'red',
                  opacity: 0,
                  height: `${boxSizePx * boxLines}px`,
                  bottom: bottomMenuHeight,
                }}
              >
                {!!price &&
                  boxesGrid &&
                  isBoxesGridShown &&
                  boxLinesArray.map((_, lineIdx) => {
                    return (
                      <div
                        className="row flex-nowrap"
                        key={lineIdx}
                        style={{
                          margin: 0,
                          width: '100%',
                        }}
                      >
                        {boxColumnsArray.map((_, columnIdx) => {
                          const i = lineIdx * boxColumns + columnIdx;
                          const box = boxesGrid[i] ?? {};
                          const size = `${boxSizePx}px`;
                          return (
                            <div //x1={box.x1} x2={box.x2} y1={box.y1} y2={box.y2}
                              style={{
                                width: size,
                                height: size,
                              }}
                              className={`col`}
                              key={'gridBox' + i}
                              box_id={i}
                              onMouseDown={(e) => {
                                if (!price || isMobile) return;
                                boxSelectionSend({ type: 'RESET' });
                                addSelectedBox(box);
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              onMouseMove={(e) => {
                                if (!price || isMobile) return;
                                if (e.buttons === 1 || e.buttons === 3)
                                  addSelectedBox(box);
                              }}
                              onMouseUp={(e) => {
                                if (isMobile) return;
                                if (price) {
                                  boxSelectionSend({ type: 'SUBMIT' });
                                } else boxSelectionSend({ type: 'RESET' });
                              }}
                              //isMobile
                              onTouchStart={(e) => {
                                if (!price || !isMobile) return;
                                boxSelectionSend({ type: 'RESET' });
                                addSelectedBox(box);
                                e.stopPropagation();
                              }}
                              onTouchMove={(e) => {
                                if (!price || !isMobile) return;
                                const boxId = document
                                  .elementFromPoint(
                                    e.changedTouches[0]?.clientX,
                                    e.changedTouches[0]?.clientY
                                  )
                                  ?.getAttribute('box_id');
                                const boxOver = boxesGrid.find(
                                  (b) => `${b.id}` === `${boxId}`
                                );
                                addSelectedBox(boxOver);
                              }}
                              onTouchEnd={(e) => {
                                if (!isMobile) return;
                                const boxId = document
                                  .elementFromPoint(
                                    e.changedTouches[0]?.clientX,
                                    e.changedTouches[0]?.clientY
                                  )
                                  ?.getAttribute('box_id');
                                const boxOver = boxesGrid.find(
                                  (b) => `${b.id}` === `${boxId}`
                                );
                                if (price && boxOver) {
                                  boxSelectionSend({ type: 'SUBMIT' });
                                } else boxSelectionSend({ type: 'RESET' });
                              }}
                            ></div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        )
      }

      {!user?.email && tutorial.matches('waitAcceptChallenge') && (
        <AnimatedHand
          y={135}
          x="calc(50% - 100px)"
          type="point"
          overlay
          zIndex={101}
        />
      )}

      {!user?.email && tutorial.matches('waitIncreaseBet') && (
        <AnimatedHand
          y={`${centralPoint.y + 40 + 180}px`}
          x="calc(100% - 4vw - 40px)"
          type="point"
          overlay
          zIndex={103}
        />
      )}

      <ProgressBar
        y={100}
        width={180}
        header={
          false && challengeBonus?.params?.amount
            ? `${t('Level {{num}}', {
                num: !challengeBonus?.params?.limitIncrement
                  ? challengeBonus?.params?.amount / 50
                  : (challengeBonus?.params?.limit -
                      challengeBonus?.params?.minLimit) /
                      challengeBonus?.params?.limitIncrement +
                    1,
              })}`
            : t('Bonus')
        }
        show={
          (user.email &&
            !isEmulator &&
            price &&
            isConnected &&
            userOnboarding.context.winsCounter > 0 &&
            challengeBonus?.expired > Date.now()) ||
          (!user.email &&
            price &&
            isConnected &&
            (tutorial.matches('waitAcceptChallenge') ||
              (!tutorial.hasTag('hideProgress') &&
                userOnboarding.context.winsCounter >= 0)))
        }
        position={userOnboarding.context.winsCounter}
        max={userOnboarding.context.winsCounterLimit}
        onClick={() => {
          if (!user.email) {
            if (tutorial.hasTag('tutorialChallenge')) {
              navigate({
                pathname: tutorial.matches('waitAcceptChallenge')
                  ? './bonus/tutorial'
                  : './bonus/tutorial',
                state: location.pathname,
                search: createSearchParams({
                  amount: tutorial.context.amount,
                  color: tutorial.context.color,
                  limit: userOnboarding.context.winsCounterLimit,
                  progress: userOnboarding.context.winsCounter,
                  name: t('Challenge'),
                  backName: tutorial.matches('waitAcceptChallenge')
                    ? t('Accept challenge')
                    : '',
                }).toString(),
                replace: true,
              });
              if (tutorial.matches('waitAcceptChallenge'))
                sendTutorial({ type: 'ACCEPT' });
            } else
              navigate('./bonus/no-auth', {
                state: location.pathname,
                replace: true,
              });
          } else {
            navigate('./bonus/challenge', { replace: true });
          }
        }}
      />

      <div
        className="container pt-3 pb-3 text-center"
        style={{
          position: 'absolute',
          zIndex: 10,
          top: topMenuHeight + terminalSize.height,
          height: bottomMenuHeight,
          width: '100%',
        }}
        onClick={() => {
          if (!isPremium) {
            nativeApp.haptics('success');
            logger.event('ClickBanner');
            nativeApp.openWebBrowser(
              'https://best.sng.link/Dnio2/kw3s?_smtype=3'
            );
          }
        }}
      >
        {!!price && isPremium && (isPwa() || isNativeApp() || isTelegram()) && (
          <small
            className={`text-uppercase label visible muted
          ${
            userOnboarding.context.bets === 0 ? '' : ' muted' //userOnboarding.matches('newcomer')
          }
          `}
            style={{
              position:
                true || isTelegram() || isNativeApp() || isPwa()
                  ? ''
                  : 'absolute',
              top: `${
                terminalSize.height + topMenuHeight - boxLines * boxSizePx
              }px`,
              transform: 'translate(-50%, -130%)',
              left: '50%',
              textWrap: 'nowrap',
              opacity: isConnected ? 1 : 0,
              transition: 'opacity 0.5s',
            }}
          >
            {
              //t('Select a box to make a bet')
              //t('Select a box to play')
              //t('Tap to buy a box')
              //isLogged ? t('Swipe to buy boxes') : t('Swipe to buy boxes')
              //t('Swipe to buy boxes')
              // true ? t('Select boxes to play') : t('Select boxes to buy')
              t('Tap boxes to hit Bitcoin')
            }
          </small>
        )}

        {!isPremium && isConnected && (
          <img
            src={`/img/banner/walletV${
              (Math.floor(Date.now() / 10000) % 2) + 1
            }.jpg`}
            alt=""
            style={{
              position: 'absolute',
              transform: 'translate(-50%, 0)',
              top: 0,
              left: '50%',
              height: '60px',
            }}
          />
        )}
      </div>

      {isConnected && !user?.email && tutorial.matches('waitForStart') && (
        <TutorialDialog
          title={`${t(`Predict Bitcoin's next move`)}`}
          message={t('Each attempt costs {{price}} BOXO', {
            price: 1,
          })}
          onClick={() => {
            sendTutorial('ACCEPT');
            nativeApp.haptics('success');
          }}
        />
      )}

      {isConnected &&
        !user?.email &&
        tutorial.matches('waitAcceptChallenge') && (
          <TutorialDialog
            title={`${t(`Complete challenge`, { number: 10 })}!`}
            message={t('Win bonus', {
              price: 1,
            })}
            onClick={() => {
              navigate({
                pathname: tutorial.matches('waitAcceptChallenge')
                  ? './bonus/tutorial'
                  : './bonus/tutorial',
                state: location.pathname,
                search: createSearchParams({
                  amount: tutorial.context.amount,
                  color: tutorial.context.color,
                  limit: userOnboarding.context.winsCounterLimit,
                  progress: userOnboarding.context.winsCounter,
                  name: t('Challenge'),
                  backName: tutorial.matches('waitAcceptChallenge')
                    ? t('Accept challenge')
                    : '',
                }).toString(),
                replace: true,
              });
              sendTutorial({ type: 'ACCEPT' });
            }}
            buttonName={t('View details')}
          />
        )}

      {/* {isConnected &&
        !user?.email &&
        tutorial.matches('waitAcceptChallenge') && (
          <TutorialDialog
            title={`${t(`Hit {{number}} boxes to get bonus`, { number: 10 })}`}
            message={t('Bigger prizes are harder to predict', {
              price: 1,
            })}
            onClick={() => {
              sendTutorial('ACCEPT');
              nativeApp.haptics('success');
            }}
            buttonName={t('View bonus details')}
          />
        )} */}

      {isConnected && !user?.email && tutorial.matches('waitIncreaseBet') && (
        <TutorialDialog
          title={`${t(`Raise box price to earn more`)}`}
          message={`${t('Watch your balance')}!`}
          // title={`${t('New challenge')}!`}
          // message={`${t(`Raise box price to earn more`)}`}
          onClick={() => {
            setBet(5);
            sendTutorial({ type: 'INCREASED' });
            nativeApp.haptics('success');
          }}
          //buttonName={t('Continue')}
          buttonName={t('Raise')}
        />
      )}
    </>
  );
}

function updateData(data, newData, timeHistory, setData) {
  if (!newData || !newData.length) {
    return;
  }
  let updatedData = data;
  const isCurrent =
    !data.length || newData[0].time >= data[data.length - 1].time;
  const isRecent =
    !isCurrent && newData[newData.length - 1].time <= data[0].time;
  if (isCurrent) {
    updatedData = [...updatedData, ...newData];
  } else if (isRecent) {
    updatedData = [...newData, ...updatedData];
  }
  let i = 0;
  const minTime = updatedData[updatedData.length - 1].time - timeHistory - 1000;
  while (i < updatedData.length && updatedData[i].time < minTime) {
    i += 2;
  }
  updatedData = updatedData.slice(i);
  setData(updatedData);
}
