import { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { isAndroid, isIos, isNativeApp, isTelegram } from '../shared/utils';

export default function Download() {
  const { isReady, event } = useLoggerContext();
  const [params] = useSearchParams();
  const [isTimeOut, setIsTimeOut] = useState(false);
  const src = params.get('src');
  useEffect(() => {
    event('download', { src });
    setTimeout(() => setIsTimeOut(true), 1500); //fallback if session is not ready
  }, []);

  useEffect(() => {
    if (!isTimeOut && !isReady) return;

    setTimeout(function () {
      window.location = '/';
    }, 3000);

    if (isNativeApp() || isTelegram()) window.location.href = '/home';
    else if (isIos())
      window.open(
        'https://apps.apple.com/app/trading-simulator-boxo-game/id6725853622',
        '_blank'
      );
    else
      window.open(
        'https://play.google.com/store/apps/details?id=com.BoxoTradeApp',
        '_blank'
      );
  }, [isReady, isTimeOut]);

  return <></>;
}
