import { t } from 'i18next';
import { SupportLink } from '../../components/terminal';
import { ModalLayout } from '../../shared/layout';

export default function About() {
  return (
    <ModalLayout>
      <div className="modal-header">
        <h1 className="text-center text-default">
          {t('About {{app}}', { app: 'Boxo' })}
        </h1>
      </div>
      <div className="modal-body no-scroll">
        <img
          src="/img/fox/welcome.png"
          style={{
            height: 'auto',
            width: '105%',
            left: '50%',
            position: 'absolute',
            transform: 'translateX(-50%)',
          }}
        />
      </div>

      <div className="modal-footer no-mobile-keyboard">
        <div className="mt-2 text-center">
          <small className="text-muted">
            {`Boxo © ${new Date().getFullYear()} Inc. All Rights Reserved`}
          </small>
          <br />
          <small className="text-muted">{t('Have a question?')}</small>
          <br />
          <SupportLink />
        </div>
      </div>
    </ModalLayout>
  );
}
