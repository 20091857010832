import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useLocation, useSearchParams } from 'react-router-dom';
import { isAndroid, isInAppBrowser, isIos, isPwa, isTelegram } from '../utils';
import axios from 'axios';
import { createContext } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useLocalStorage, useSessionStorage } from './use-hooks';
import {
  logEvent,
  logFinishRegistration,
  logSession,
  logStartRegistration,
} from '../api/log';
import { AxiomWithoutBatching } from '@axiomhq/js';
import { useCallback } from 'react';
import { api_middleware } from '../api/api-client';
import { useInterval } from './use-interval';

const Logger = createContext();
export function LoggerContext({ children }) {
  const logger = useLogger();
  const persistedLogger = useMemo(() => logger, [logger]);
  return <Logger.Provider value={persistedLogger}>{children}</Logger.Provider>;
}
export const useLoggerContext = () => useContext(Logger);

function useLogger() {
  const [isReady, setIsReady] = useState(false);
  const [sessionId] = useSessionStorage('sessionId', uuid());
  const [ipAddress, setIpAddress] = useState(null);
  const [deviceUserId] = useLocalStorage('device-user-id', uuid());
  const [lng] = useLocalStorage('lng', null);
  const [lngDetected] = useLocalStorage('lng-detected', null);
  const [visits, setVisits] = useLocalStorage('visits', 0);
  const [lastVisitDate, setLastVisitDate] = useLocalStorage('last-visit', null);
  const [lastVisitDatePersisted, setLastVisitDatePersisted] = useSessionStorage(
    'last-visit',
    null
  );
  const [geo, setGeo] = useState(null);
  const [utmData, setUtmData] = useLocalStorage('utm_data', null);
  const [enableSonuds] = useLocalStorage('enable-sounds', true);
  const [searchParams] = useSearchParams();

  //Init logger
  useEffect(() => {
    setLastVisitDatePersisted(lastVisitDate); //Persist for session data
    setLastVisitDate(Date.now());

    //Visits
    setVisits(visits + 1);

    //IP
    api_middleware()
      .get(`/api/ip`)
      .then((response) => {
        const newIp =
          response?.data?.remoteAddress &&
          response?.data?.remoteAddress !== '127.0.0.1'
            ? response?.data?.remoteAddress
            : response?.data?.ip && response?.data?.ip !== '127.0.0.1'
            ? response?.data?.ip
            : response?.data?.header && response?.data?.header !== '127.0.0.1'
            ? response?.data?.header
            : 'unknown';
        setIpAddress(newIp);
      })
      .catch((e) => setIpAddress('error'));

    //Geo
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        setGeo({
          country: data.country_name,
          city: data.city,
        });
      })
      .catch(() => {
        setGeo({
          country: 'unknown',
          city: 'unknown',
        });
      });

    //UTM
    const new_utm_source = searchParams.get('utm_source');
    const new_utm_medium = searchParams.get('utm_medium');
    const new_utm_campaign = searchParams.get('utm_campaign');
    if (new_utm_source || new_utm_campaign || new_utm_medium) {
      const newUtmData = {
        utm_source: new_utm_source ? new_utm_source : utmData?.utm_source,
        utm_medium: new_utm_medium ? new_utm_medium : utmData?.utm_medium,
        utm_campaign: new_utm_campaign
          ? new_utm_campaign
          : utmData?.utm_campaign,
      };
      event('utm', { ...newUtmData });
      setUtmData(newUtmData);
    } else
      setUtmData({
        utm_source: 'none',
        utm_medium: 'none',
        utm_campaign: 'none',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [eventCount, setEventCount] = useState(0);

  useInterval(() => updateSession(), isReady ? null : 1000);

  useEffect(() => {
    if (
      (utmData?.utm_source || utmData?.utm_medium || utmData?.utm_campaign) &&
      ipAddress &&
      geo
    )
      updateSession();
  }, [
    utmData?.utm_source,
    utmData?.utm_medium,
    utmData?.utm_campaign,
    ipAddress,
    geo,
  ]);

  useEffect(() => {
    if (sessionId)
      logEvent({
        sessionId,
        name: 'landing',
        data: {
          host: window.location.hostname,
          pathname: window.location.pathname,
          url: window.location.href,
          referrer: document.referrer,
        },
      });
  }, [sessionId]);

  function updateSession() {
    //if (eventCount < 3) return;
    //--it does not work on debug when update a page
    //--also there is dependency in /download page which waits for session

    const session = {
      host: window.location.hostname,
      landing: window.location.pathname,
      url: window.location.href,
      referrer: document.referrer,
      appVersion: process.env.REACT_APP_VERSION,
    };
    const utm = { ...utmData };

    const userAgent = {
      isPwa: isPwa(),
      isInApp: isInAppBrowser(),
      isTelegram: isTelegram(),
      agent: window.navigator.userAgent
        .replace(/\t/g, '')
        .replace(/,/g, '')
        .replace(/;/g, '')
        .replace(/";"/g, ''),
      navigatorStandalone: `${window.navigator.standalone}`,
      standalone: window.matchMedia('(display-mode: standalone)').matches,
      serviceWorker: 'serviceWorker' in navigator ? true : false,
    };

    const device = {
      ipAddress,
      isIos: isIos(),
      isAndroid: isAndroid(),
      window: {
        height: window.screen.availHeight,
        width: window.screen.availWidth,
      },
      visits,
      lastVisitDate: new Date(lastVisitDatePersisted).toISOString(),
      enableSonuds: `${enableSonuds}`,
    };

    const user = {
      lang: lngDetected ? lngDetected : lng,
      geo,
    };

    const data = {
      session,
      utm,
      userAgent,
      device,
      user,
    };

    logSession(sessionId, deviceUserId, data).then((resp) => {
      if (resp?.data?.success) setIsReady(true);
    });
  }

  //Track pathname change
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes('app/p/')) {
      event('navigate', { pathname: location.pathname });
    }
  }, [location.pathname]);

  async function event(name, data) {
    setEventCount(eventCount + 1);
    await logEvent({ sessionId, name, data });
  }

  async function startRegistration(email) {
    if (!email) return;
    await logStartRegistration(sessionId, email);
  }

  async function finishRegistration() {
    await logFinishRegistration();
  }

  //Axiom for Data recording
  const ingest = useCallback(async (data, channel = 'system') => {
    const axiom = new AxiomWithoutBatching({
      token: 'xaat-db37493c-c5c4-4754-b267-3b4638e65142',
    });
    try {
      await axiom.ingest(channel, data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const logData = useCallback(
    async (...params) => {
      const data = params.length > 0 ? params[0] : {};
      ingest(data, 'system', true);
    },
    [ingest]
  );

  const none = () => {};
  return {
    event,
    startRegistration,
    finishRegistration,
    logData,
    isReady,
    visits,

    //Legacy methods
    action: none,
    performance: none,
    landing: none,
    user: none,
    log: none,
    error: none,
  };
}

/* 
function useLogger1() {
  const { user } = useUserContext();
  const [session, setSession] = useState(uuid());
  const [sessionStart, setSessionStart] = useState();
  const [landingTime, setLandingTime] = useState();
  const [actionTime, setActionTime] = useState();
  const [peformanceTime, setPeformanceTime] = useState();
  const [device, setDevice] = useState();
  const [userAgent, setUserAgent] = useState();
  const [ipAddress, setIpAddress] = useState();

  //Init
  useEffect(() => {
    setSession(uuid());
    setSessionStart(Date.now());
    setActionTime(Date.now());
    setLandingTime(Date.now());
    setPeformanceTime(Date.now());

    if (isIos()) setDevice('ios');
    else if (isAndroid()) setDevice('android');
    else setDevice('unknown');
    const newUseAgent = {
      isPwa: isPwa(),
      isInApp: isInAppBrowser(),
      navigatorStandalone: window.navigator.standalone,
      standalone: window.matchMedia('(display-mode: standalone)').matches,
      referrer: document.referrer,
    };
    setUserAgent(newUseAgent);

    axios.get(`/api/ip`).then((response) => {
      const newIp = response?.data?.remoteAddress;
      newIp && setIpAddress(newIp);
    });
  }, []);

  const location = useLocation();

  //Axiom call
  const ingest = useCallback(
    async (data, channel = 'funnel', logData = false) => {
      const axiom = new AxiomWithoutBatching({
        token: 'xaat-db37493c-c5c4-4754-b267-3b4638e65142',
      });

      const sessionDuration = sessionStart
        ? ((Date.now() - sessionStart) / 1000).toFixed(3)
        : 0;
      const msg = logData
        ? data
        : [
            {
              user: { ...user },
              session: {
                id: session,
                duration: sessionDuration,
                host: window.location.hostname,
                device,
                userAgent,
                ip: ipAddress,
              },
              event: { ...data },
            },
          ];
      try {
        if (process.env.NODE_ENV !== 'production' && !logData) {
          console.log('Axiom logger: ', msg);
        } else {
          await axiom.ingest(channel, msg);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [session, sessionStart, user]
  );

  //1. Logger for landing page
  const landing = useCallback(
    (actionName, ...params) => {
      const data = params.length > 0 ? { ...params } : null;
      const landingDuration = landingTime
        ? ((Date.now() - landingTime) / 1000).toFixed(3)
        : 0;

      setActionTime(Date.now());
      setLandingTime(Date.now());
      setPeformanceTime(Date.now());

      ingest({
        name: 'landing',
        action: actionName,
        location: location.pathname,
        data,
        duration: landingDuration,
      });
    },
    [landingTime, ingest, location.pathname]
  );

  //2. Logger for user's actions
  const action = useCallback(
    async (actionName, ...params) => {
      const data = params.length > 0 ? { ...params } : null;
      const actionDuration = actionTime
        ? ((Date.now() - actionTime) / 1000).toFixed(3)
        : 0;
      setActionTime(Date.now());

      ingest({
        name: 'action',
        action: actionName,
        duration: actionDuration,
        data,
      });
    },
    [actionTime, ingest]
  );

  //3. Logger for user's achievements
  const performance = useCallback(
    async (performanceName, ...params) => {
      const data = params.length > 0 ? { ...params } : null;
      const performanceDuration = peformanceTime
        ? ((Date.now() - peformanceTime) / 1000).toFixed(3)
        : 0;
      setPeformanceTime(Date.now());

      ingest({
        name: 'performance',
        action: performanceName,
        duration: performanceDuration,
        data,
      });
    },
    [peformanceTime, ingest]
  );

  const log = useCallback(
    async (msg, ...params) => {
      const data = params.length > 0 ? { ...params } : null;
      ingest(
        {
          type: 'log',
          msg,
          data,
        },
        'system'
      );
    },
    [ingest]
  );

  const logData = useCallback(
    async (...params) => {
      const data = params.length > 0 ? params[0] : {};
      ingest(data, 'system', true);
    },
    [ingest]
  );

  const error = useCallback(
    async (msg, ...params) => {
      const data = params.length > 0 ? { ...params } : null;
      ingest(
        {
          type: 'error',
          msg,
          data,
        },
        'system'
      );
    },
    [ingest]
  );

  return { action, performance, landing, user, log, error, logData };
}
 */
