import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  createSearchParams,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ModalLayout } from '../shared/layout';
import { useBonusesContext } from '../shared/hooks/use-bonus';
import {
  isAndroid,
  isIos,
  isNativeApp,
  isPwa,
  isTelegram,
  millisecondsLeftToStr,
  priceFmt,
  tryToParseJSON,
} from '../shared/utils';
import {
  BtnIcon,
  SelectionList,
  TodoList,
  TodoListDone,
  SuccessScoreRating,
  Confetti,
} from '../components/terminal';
import { InstallDialog } from './install';
import { Loader } from './loader';
import { SystemDialog } from '../shared/dialogs';
import { useLocalStorage, useSessionStorage } from '../shared/hooks/use-hooks';
import { useInterval } from '../shared/hooks/use-interval';
import { useSoundContext } from '../shared/hooks/use-sound';
import {
  loadPersistentState,
  persistSessionState,
  persistState,
} from '../shared/utils/persist';
import { useUserContext } from '../shared/hooks/use-user';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import { claimBonusMachine } from '../shared/machines/bonus';
import { useMachine } from '@xstate/react';
import {
  BonusBlackBoxSvg,
  BonusBlackLidSvg,
  BonusBoxSvg,
} from '../components/svg';
import { getChallengeBonusStats } from '../shared/api/bonus';
import { AdsContainer } from '../shared/hooks/use-ads';

function BonusListDialog({
  logger,
  email,
  onClaimBonus,
  onClaimBonusError,
  onLogin,
  onRestartGame,
  challengeBonusProgress,
}) {
  const { isLogged } = useUserContext();

  const [bonus, setBonus] = useState(null);
  const {
    isLoading,
    getActualBonuses,
    visibleBonuses: bonuses,
  } = useBonusesContext();
  useState(() => {
    //getActualBonuses();
  }, []);

  const location = useLocation();

  function doClaimBonus(claimedBonus, isBoosted = false) {
    setBonus(claimedBonus);
    navigate(`./claim?isBoosted=${btoa(isBoosted)}`, { replace: true });
  }

  const navigate = useNavigate();
  function doPlay() {
    persistSessionState(location.pathname, null); //Prevent <Back
    navigate('/app', { replace: true });
  }

  return (
    <>
      <Routes>
        {isLogged && (
          <>
            <Route
              path="/"
              element={
                isLoading ? (
                  <Loader overlay />
                ) : (
                  <BonusList
                    bonuses={bonuses}
                    logger={logger}
                    email={email}
                    onSetBonus={setBonus}
                  />
                )
              }
            />

            <Route
              path="/challenge"
              element={
                false && isLoading ? (
                  <Loader overlay />
                ) : (
                  <ChallengeBonusDialog
                    onClaimBonus={doClaimBonus}
                    onPlay={doPlay}
                    challengeBonusProgress={challengeBonusProgress}
                  />
                )
              }
            />

            <Route
              path="/welcome"
              element={
                <WelcomeBonusDialog
                  logger={logger}
                  onClaimBonus={doClaimBonus}
                  hidePushNotificationsTask={true}
                  isLoading={isLoading}
                />
              }
            />
            <Route
              path="/push"
              element={
                <WelcomeBonusDialog
                  logger={logger}
                  onClaimBonus={doClaimBonus}
                  hidePushNotificationsTask={false}
                  isLoading={isLoading}
                />
              }
            />
            <Route
              path="/recovery"
              element={
                <RecoveryBonusDialog
                  onClaimBonus={doClaimBonus}
                  onPlay={doPlay}
                />
              }
            />

            <Route
              path="/success"
              element={
                <SuccessBonusDialog
                  bonus={bonus}
                  onClaimedBonus={() => {
                    onClaimBonus(bonus);
                  }}
                />
              }
            />
            <Route
              path="/health"
              element={<HealthBonusDialog onClaimBonus={doClaimBonus} />}
            />
            <Route
              path="/streak"
              element={<StreakBonusDialog onClaimBonus={doClaimBonus} />}
            />
            <Route
              path="/sponsored"
              element={<SponsoredBonusDialog onClaimBonus={doClaimBonus} />}
            />
            <Route
              path="/claim"
              element={
                <ClaimBonusDialog
                  bonus={bonus}
                  onClaimedBonus={onClaimBonus}
                  onClaimBonusError={onClaimBonusError}
                />
              }
            />

            <Route path="/*" element={<NoBonusDialog />} />
          </>
        )}

        <Route
          path="/welcome/token/:token"
          element={
            <WelcomeBonusDialog
              logger={logger}
              onClaimBonus={doClaimBonus}
              hidePushNotificationsTask={true}
              onLogin={onLogin}
              isLoading={isLoading}
            />
          }
        />

        <Route
          path="/no-auth"
          element={
            <WelcomeBonusDialog logger={logger} onRestartGame={onRestartGame} />
          }
        />

        <Route path="/tutorial" element={<TutorialBonusDialog />} />
        <Route
          path="/tutorial/claim"
          element={<TutorialClaimBonusDialog onClaimBonus={onClaimBonus} />}
        />

        <Route
          path="/tutorial/lose"
          element={<TutorialLoseDialog onRestartGame={onRestartGame} />}
        />
        <Route
          path="/tutorial/win"
          element={
            <TutorialWinDialog
              onClaimBonus={onClaimBonus}
              multiplier={challengeBonusProgress}
            />
          }
        />

        <Route path="/*" element={<WelcomeBonusDialog logger={logger} />} />
      </Routes>
    </>
  );
}

function BonusList({ bonuses, onSetBonus }) {
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/app', { replace: true });
  };

  const location = useLocation();

  function bonusOnClick(bonus) {
    if (!bonus) return;
    onSetBonus(bonus);
    if (
      bonus.typeId === '2ndWelcomeBonusFreeBoxes' ||
      bonus.typeId === 'WelcomeBonusFreeBoxes'
    ) {
      navigate('./welcome', {
        replace: true,
        state: location.pathname,
      });
    }

    if (bonus.typeId === 'PushNotificationsBonus') {
      navigate('./push', {
        replace: true,
        state: location.pathname,
      });
    }

    if (bonus.typeId === 'ChallengeBonus') {
      navigate('./challenge', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'RecoveryBonus') {
      navigate('./recovery', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'AdBonus') {
      navigate('./health', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'StreakBonus') {
      navigate('./streak', {
        replace: true,
        state: location.pathname,
      });
    }
  }

  return (
    <>
      {!bonuses?.length && <NoBonusDialog onClose={onClose} />}

      {bonuses?.length > 0 && (
        <ModalLayout onClose={onClose}>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Get bonus')}</h1>
          </div>
          <div className="modal-body">
            {bonuses && (
              <SelectionList
                items={bonuses.map((bonus) => {
                  const { name, descr, imgUrl, disabled } = bonus;
                  return {
                    name,
                    descr,
                    imgUrl,
                    disabled,
                    //tag: bonus?.last_seen_at === null ? t('New') : null,
                    tag: bonus?.isNew ? t('New') : null,
                    type: 'menu',
                  };
                })}
                onClick={(idx) => bonusOnClick(bonuses[idx])}
              />
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function WelcomeBonusDialog({
  logger,
  onClaimBonus,
  hidePushNotificationsTask = true,
  onLogin,
  onRestartGame,
}) {
  const {
    isLoading,
    setBonusSeen,
    getActualWelcomeBonus,
    getActualPushNotificationsBonus,
  } = useBonusesContext();
  const { user } = useUserContext();
  const email = user?.email;
  const [bonus, setBonus] = useState(null);
  const [expired, setExpired] = useLocalStorage('WelcomeBonusExpiredAt', null);

  useEffect(() => {
    async function update() {
      if (!email) {
        const amount = 500;
        const newExpired =
          !expired || expired <= Date.now()
            ? Date.now() + 60 * 60 * 24 * 1000
            : expired;
        const newBonus = {
          params: { amount },
          expired: newExpired,
        };
        setExpired(newExpired);
        setBonus(newBonus);
      } else {
        const newBonus = hidePushNotificationsTask
          ? await getActualWelcomeBonus()
          : await getActualPushNotificationsBonus();
        setBonus(newBonus);
        setBonusSeen(newBonus);
      }
    }
    if (isLoading) return;
    update();
  }, [email, isLoading]);

  const navigate = useNavigate();
  const [isInstallDialogShowing, setIsInstallDialogShowing] = useState(false);
  const [enablePushBonus] = useLocalStorage('enable_push_bonus', null);
  const location = useLocation();
  const params = useParams();
  const { token } = params;
  const { initUser } = useUserContext();

  useEffect(() => {
    if (!token) return;
    if (email && isIos() && !isPwa()) {
      //Waiting for install in ios Safari
      //URL is already updated as a start_url for ios PWA
      //Ask user to Add to home screen
      setIsInstallDialogShowing(true);
    }
    const isIosPwaInstalled = loadPersistentState('isIosPwaInstalled');
    if (isIosPwaInstalled) {
      //alert('Normal ios start');
      //It's a normal start of PWA app on iOS
      //PWA's start_url is app/bonus/welcome/token/...
      //Going home
      navigate('/', { replace: true });
    } else {
      //First time PWA start on ios:
      if (
        (!email && isIos() && isPwa()) ||
        (!email && process.env.NODE_ENV !== 'production')
      ) {
        //alert('First ios pwa start');
        //Auth token is received!
        //This is one time code on PWA
        const newToken = tryToParseJSON(atob(token));
        const { email: newEmail, session } = newToken;
        persistState('session', session);
        persistState('email', newEmail);
        persistState('isIosPwaInstalled', true);
        initUser(newEmail).then(() => {
          onLogin(newEmail, '/app/bonus/welcome');
        });
      }
    }
  }, [token]);

  const onInstall = () => {
    logger?.event('click', { target: 'InstallForBonus' });
    if (!token && isIos()) {
      //Generate token for URL
      const session = loadPersistentState('session');
      const newToken = btoa(JSON.stringify({ email, session }));
      const startUrl = `/app/bonus/welcome/token/${newToken}`;
      window.location.replace(startUrl);
    } else if (isAndroid() || isIos()) setIsInstallDialogShowing(true);
    else alert('Please open this web site in mobile browser');
  };

  const onClose = () => {
    navigate('/app', { replace: true });
  };

  const onSignUpNavigate = () => {
    navigate('/app/auth/sign-up', { replace: true });
  };

  const onNotificationsNavigate = () => {
    navigate('/app/settings/notifications', {
      replace: true,
      state: location.pathname,
    });
  };

  const [adsWatched, setAdsWatched] = useState(false);
  const [showAds, setShowAds] = useSessionStorage('show-ads', false);

  useEffect(() => {
    if (adsWatched) onRestartGame && onRestartGame();
  }, [adsWatched]);

  return (
    <>
      {!bonus && !isLoading && <NoBonusDialog onClose={onClose} />}
      {!bonus && isLoading && <Loader overlay />}

      {bonus && (
        <ModalLayout
          hideNavigation={showAds}
          onClose={
            !email || (email && token && isIos() && !isPwa()) ? null : onClose
          }
        >
          {showAds && (
            <AdsContainer
              format="Long"
              onDone={() => {
                setAdsWatched(true);
                setShowAds(false);
              }}
              onFail={() => {
                setAdsWatched(true);
                setShowAds(false);
              }}
            />
          )}
          {!showAds && (
            <>
              <div className="modal-header">
                <h1 className="text-center text-default">
                  {hidePushNotificationsTask
                    ? `${t('Welcome bonus')}!`
                    : t('Notifications bonus')}
                </h1>
                {!!email && bonus.expired - Date.now() > 0 && (
                  <div className="text-center">
                    <img
                      src="/img/time.svg"
                      alt=""
                      style={{
                        width: '11px',
                        marginTop: '0px',
                        marginLeft: '0px',
                        marginRight: '6px',
                      }}
                    />

                    <span>
                      <small>
                        {millisecondsLeftToStr(
                          Math.max(0, bonus.expired - Date.now())
                        )}
                      </small>
                    </span>
                  </div>
                )}
              </div>
              <div className="modal-body">
                {hidePushNotificationsTask && (
                  <>
                    <BonusCard
                      name={t('Welcome bonus')}
                      //img="/img/black-box-flat.svg"
                      img="/img/gift.svg"
                      color="ruby"
                      amount={bonus?.params?.amount}
                      todoList={null}
                    />

                    <div className="mt-4">
                      <TodoList
                        header={
                          !email
                            ? t('Create account to get bonus')
                            : t('How to get bonus')
                        }
                        items={
                          !email
                            ? [
                                {
                                  name: t('Join leagues'),
                                  //descr: t('Compete & climb the ranks'),
                                  isDone: true,
                                },
                                {
                                  name: t('Get daily bonuses'),
                                  //descr: t('Free rewards every day'),
                                  isDone: true,
                                },
                                {
                                  name: t('Save progress'),
                                  //descr: t('Pick up right where you left off'),
                                  isDone: true,
                                },
                              ]
                            : [
                                {
                                  name: t('Create account'),
                                  isDone: !!email,
                                },
                                !isNativeApp() && {
                                  name: isTelegram()
                                    ? t('Start bot')
                                    : isIos() && !isNativeApp()
                                    ? t('Add to Home Screen')
                                    : t('Install app'),
                                  isDone:
                                    isPwa() || isNativeApp() || isTelegram(),
                                },
                              ]
                        }
                      />
                    </div>
                  </>
                )}
                {!hidePushNotificationsTask && (
                  <BonusCard
                    name={t('Notifications bonus')}
                    img="/img/black-plane-flat.svg"
                    color="crystal"
                    amount={bonus?.params?.amount}
                    todoList={[
                      {
                        name: isTelegram()
                          ? t('Start Telegram bot')
                          : t('Install app'),
                        isDone: isPwa() || isNativeApp() || isTelegram(),
                      },
                      {
                        name: `${t('Enable notifications')}: "${t('Bonuses')}"`,
                        isDone: enablePushBonus === 'true',
                      },
                    ]}
                  />
                )}
              </div>

              <div className="modal-footer no-mobile-keyboard justify-content-center">
                {!email && (
                  <>
                    <div
                      className="btn btn-primary"
                      role="button"
                      onClick={onSignUpNavigate}
                    >
                      {t(`Create account`)}
                    </div>

                    {!location.state && (
                      // <div className="mt-3 mb-3 text-center">
                      //   <small className="text-muted">
                      //     {t('Reset balance and ')}
                      //   </small>
                      //   {'  '}
                      //   <small
                      //     className="text-primary btn-link"
                      //     role="button"
                      //     onClick={() => {
                      //       logger?.event('click', {
                      //         target: 'RestartDemo',
                      //       });
                      //       setAdsWatched(true); // TODO: add interstitial
                      //     }}
                      //   >
                      //     {t(`restart`)}
                      //   </small>
                      // </div>

                      <div
                        className="btn btn-secondary"
                        role="button"
                        onClick={() => {
                          logger?.event('click', {
                            target: 'RestartDemo',
                          });
                          //setShowAds(true);
                          setAdsWatched(true); // TODO: add interstitial
                        }}
                      >
                        {/* <BtnIcon src="/img/btn-play-video.svg" /> */}
                        {t(`Restart`)}
                      </div>
                    )}
                  </>
                )}
                {!!email && !isPwa() && !isNativeApp() && !isTelegram() && (
                  <div
                    className="btn btn-primary"
                    role="button"
                    onClick={onInstall}
                  >
                    {isIos() ? t('Add to Home Screen') : t(`Install`)}
                  </div>
                )}
                {!hidePushNotificationsTask &&
                  !!email &&
                  (isNativeApp() || isPwa() || isTelegram()) &&
                  enablePushBonus !== 'true' && (
                    <div
                      className="btn btn-primary"
                      role="button"
                      onClick={() => onNotificationsNavigate()}
                    >
                      {t(`Enable notifications`)}
                    </div>
                  )}
                {!!email &&
                  (isPwa() || isNativeApp() || isTelegram()) &&
                  (enablePushBonus === 'true' || hidePushNotificationsTask) && (
                    <div
                      className="btn btn-primary"
                      role="button"
                      onClick={() =>
                        onClaimBonus({
                          ...bonus,
                          bonusColor: hidePushNotificationsTask ? 'gold' : null,
                        })
                      }
                    >
                      {t(`Claim`)}
                    </div>
                  )}
              </div>
            </>
          )}
        </ModalLayout>
      )}

      {isIos() && (
        <SystemDialog
          title={t('Add to Home Screen')}
          show={isInstallDialogShowing}
          actions={[`OK`]}
          message={
            <>
              <div className="text-nowrap">
                <br />
                1. {t('Press the Share button')}
                <img
                  src="/img/ios/share.svg"
                  alt=""
                  style={{
                    width: '17px',
                    marginTop: '-9px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                />
              </div>
              <div className="text-nowrap">
                2. {t(`Press 'Add to Home Screen'`)}
                <img
                  src="/img/ios/add.svg"
                  alt=""
                  style={{
                    width: '18px',
                    marginTop: '4px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                />
              </div>
            </>
          }
          onAction={() => setIsInstallDialogShowing(false)}
        />
      )}
      {isAndroid() && (
        <InstallDialog
          logger={logger}
          show={isInstallDialogShowing}
          onClose={() => setIsInstallDialogShowing(false)}
        />
      )}
    </>
  );
}

function ClaimBonusDialog({ bonus, onClaimedBonus, onClaimBonusError }) {
  const { user } = useUserContext();
  const isPremium = user?.premiumExpiresAt >= Date.now();

  const logger = useLoggerContext();
  const [params] = useSearchParams();
  const isBoosted = params.get('isBoosted')
    ? atob(params.get('isBoosted')) === 'true'
    : false;
  const { claimBonus } = useBonusesContext();
  const [claimBonusState, claimBonusSend] = useMachine(claimBonusMachine, {
    state: {
      ...claimBonusMachine.initialState,
      context: {
        ...claimBonusMachine.initialState.context,
        bonus,
        boostUsed: isPremium,
      },
    },
    services: {
      claimBonus: () =>
        new Promise(async (resolve, reject) => {
          try {
            //Prevent or allow claiming on dev env
            if (false && process.env.NODE_ENV !== 'production') {
              //throw new Exception('test');
              return resolve({
                claimedAmount: claimBonusState.context.boostUsed
                  ? bonus.params.amount * bonus.adBoost
                  : bonus.params.amount,
              });
            }
            claimBonus(
              bonus, //context.boostUsed ? bonus.adBoost : 1
              (amount) => resolve({ claimedAmount: amount }),
              (error, e) => reject({ error: `${error}${e ? ' ' + e : ''}` }),
              claimBonusState.context.boostUsed
            );
          } catch (e) {
            onClaimBonusError(bonus, `${e.msg}`);
            logger?.event('error', { msg: 'failed to claim bonus', bonus, e });
            reject();
          }
        }),
    },
  });

  useEffect(() => {
    if (!bonus) onClaimBonusError(null);

    if (claimBonusState.value === 'idle') {
      if (isPremium) {
        claimBonusSend('START_PREMIUM', { payload: bonus });
      } else if (isBoosted) {
        claimBonusSend('START_BOOSTED', { payload: bonus });
      } else {
        claimBonusSend('START', { payload: bonus });
      }
    }

    if (claimBonusState.value === 'exit')
      onClaimedBonus({
        ...bonus,
        claimedAmount: claimBonusState.context.claimedAmount,
      });
  }, [bonus, claimBonusState.value]);

  const bonusColor = claimBonusState.context.boostUsed
    ? '#c7a841d9' //'var(--theme-yellow)'
    : 'var(--theme-primary)';

  return (
    <>
      {claimBonusState.hasTag('loading') && <Loader />}
      {bonus && (
        <ModalLayout>
          {claimBonusState.hasTag('ads') &&
            (true || process.env.NODE_ENV === 'production') && (
              <AdsContainer
                format={
                  claimBonusState.hasTag('boost')
                    ? bonus.useAdBoost
                    : bonus.useAds
                }
                onDone={() => claimBonusSend('DONE')}
                onFail={(error) =>
                  claimBonusSend('FAIL', { payload: { error } })
                }
              />
            )}
          {claimBonusState.hasTag('success') && (
            <>
              <div className="modal-header">
                <h1 className="text-center text-default">{t('Congrats!')}</h1>
                <div className="text-center text-muted">
                  <small>{t('Bonus is credited')}</small>
                </div>
              </div>
              <div className="modal-body">
                <BonusBox color={bonusColor} lidOpen={true} />
                <div className="mt-4">
                  <TodoListDone
                    bgColor={bonusColor}
                    header={
                      claimBonusState.hasTag('boost')
                        ? t('Total bonus')
                        : t('Bonus details')
                    }
                    caption={
                      claimBonusState.hasTag('boost')
                        ? t('You earned a total of {{amount}} BOXO', {
                            amount: claimBonusState.hasTag('boost')
                              ? claimBonusState.context.claimedAmount
                              : bonus.params.amount,
                          })
                        : t('You earned {{amount}} BOXO', {
                            amount: claimBonusState.hasTag('boost')
                              ? claimBonusState.context.claimedAmount
                              : bonus.params.amount,
                          })
                    }
                  />
                </div>
              </div>
            </>
          )}
          {claimBonusState.hasTag('failed') && (
            <>
              <div className="modal-header">
                <h1 className="text-center text-default">{t('Failed')}</h1>
                <div className="text-center text-muted">
                  <small>{t('Please try again later')}</small>
                </div>
              </div>
              <div className="modal-body">
                <img
                  src="/img/failed.svg"
                  alt=""
                  style={{
                    display: 'block',
                    margin: '3vh auto',
                  }}
                />
                <div className="invalid-feedback text-center">
                  {claimBonusState.context.error}
                </div>
              </div>
            </>
          )}

          {claimBonusState.hasTag('boostFailed') && (
            <>
              <div className="modal-header">
                <h1 className="text-center text-default">
                  {/* {t('Boost failed')} */}
                  {t('Oops...')}
                </h1>
                <div className="text-center text-muted">
                  <small>{t('Can not boost your bonus')}</small>
                </div>
              </div>
              <div className="modal-body">
                <img
                  src="/img/caution.svg"
                  alt=""
                  style={{
                    display: 'block',
                    margin: '3vh auto',
                  }}
                />
                <div className="warning-feedback text-center">
                  {claimBonusState.context.error}
                </div>
              </div>
            </>
          )}
          {claimBonusState.hasTag('boost') &&
            claimBonusState.hasTag('select') && (
              <>
                <div className="modal-header">
                  <h1 className="text-center text-default">
                    {t('Boost your bonus')}!
                  </h1>
                </div>
                <div className="modal-body">
                  <BonusCard
                    name={t('Bonus boost')}
                    img="/img/black-rocket.svg"
                    showPlus={true}
                    amount={`${
                      bonus.adBoost
                        ? (bonus.adBoost - 1) * bonus?.params?.amount
                        : 2
                    }`}
                    color="obsidian"
                    todoList={null}
                  />
                </div>
              </>
            )}

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {claimBonusState.hasTag('boost') &&
              claimBonusState.hasTag('select') && (
                <>
                  <div
                    className="btn btn-primary"
                    role="button"
                    onClick={() => claimBonusSend('CLAIM_X2')}
                  >
                    <BtnIcon src="/img/btn-play-video-primary.svg" />
                    {t(`Claim`)}
                  </div>
                  <div
                    className="btn btn-secondary"
                    role="button"
                    onClick={() => claimBonusSend('CLAIM_X1')}
                  >
                    {t(`Skip`)}
                  </div>
                </>
              )}

            {claimBonusState.hasTag('success') && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => claimBonusSend('DONE')}
              >
                {t(`Continue`)}
              </div>
            )}

            {claimBonusState.hasTag('failed') && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={
                  () => onClaimBonusError(bonus, claimBonusState.context.error) //onBackNavigate()
                }
              >
                {t(`Continue`)}
              </div>
            )}

            {claimBonusState.hasTag('boostFailed') && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={
                  () => claimBonusSend('CONTINUE') //onClaimBonusError(bonus, claimBonusState.context.error)
                }
              >
                {t(`Continue`)}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function SuccessBonusDialog({ bonus, onClaimedBonus }) {
  return (
    <>
      {bonus && (
        <ModalLayout>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Congrats!')}</h1>
            <div className="text-center text-muted">
              <small>{t('Bonus is credited')}</small>
            </div>
          </div>
          <div className="modal-body">
            <BonusBox color={bonus.bonusColor} lidOpen={true} />

            <div className="mt-4">
              <TodoList
                header={t('Bonus details')}
                items={[
                  {
                    name: t('You earned {{amount}} BOXO', {
                      amount: bonus.claimedAmount,
                    }),
                    isDone: true,
                  },
                ]}
              />
            </div>
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {true && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onClaimedBonus(bonus)}
              >
                {t(`Continue`)}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function ChallengeBonusDialog({
  onClaimBonus,
  onPlay,
  challengeBonusProgress,
}) {
  const { setBonusSeen, getActualChallengeBonus } = useBonusesContext();
  const [bonus, setBonus] = useState(null);

  const [stats, setStats] = useState(null);
  useEffect(() => {
    async function update() {
      const newBonus = await getActualChallengeBonus();
      setBonus(newBonus);
      if (newBonus) {
        const statsResp = await getChallengeBonusStats(newBonus?.id);
        if (statsResp) setStats(statsResp.data);
      }
      setBonusSeen(newBonus);
    }
    update();
  }, []);

  const left =
    !bonus || !bonus.params
      ? 100
      : Math.max(0, bonus.params.limit - challengeBonusProgress);

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });
  const unlocked = left <= 0;

  const { levelUp } = useSoundContext();
  const nativeApp = useNativeAppContext();
  useEffect(() => {
    if (!unlocked) return;
    levelUp();
    nativeApp.haptics('success');
  }, [unlocked]);

  const level = bonus?.params?.amount
    ? Math.round(
        !bonus?.params?.limitIncrement
          ? bonus?.params?.amount / 50 //old version
          : (bonus?.params?.limit - bonus?.params?.minLimit) /
              bonus?.params?.limitIncrement +
              1
      )
    : '';

  const todoList = unlocked
    ? null
    : [
        {
          name: t('Collect {{limit}} successful boxes', {
            limit: bonus?.params?.limit,
          }),
          isDone: challengeBonusProgress > 0,
        },
        {
          name: t('Wins: {{progress}}', {
            progress: challengeBonusProgress,
          }),
          isDone: challengeBonusProgress > 0,
        },
        {
          name: t('Left: {{left}}', { left }),
          isDone: unlocked,
        },
      ];

  const { user } = useUserContext();
  const isPremium = user?.premiumExpiresAt >= Date.now();

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}
      {bonus && (
        <ModalLayout onBack={bonus?.useAds ? onBack : null}>
          <div className="modal-header">
            <h1 className="text-center text-default">
              {t('Get bonus')}
              {false && t('Achievement bonus')}
              {false && !unlocked && `${t('Level {{num}}', { num: level })}`}
              {false &&
                !!unlocked &&
                `${t('Level {{num}} completed', { num: level })}!`}
            </h1>

            {bonus.expired - Date.now() > 0 && !unlocked && (
              <div className="text-center">
                <img
                  src="/img/time.svg"
                  alt=""
                  style={{
                    width: '11px',
                    marginTop: '0px',
                    marginLeft: '0px',
                    marginRight: '6px',
                  }}
                />
                <span>
                  <small>
                    {millisecondsLeftToStr(
                      Math.max(0, bonus.expired - Date.now()),
                      true
                    )}
                  </small>
                </span>
              </div>
            )}
          </div>
          <div className="modal-body">
            <BonusCard
              //name={t('Achievement bonus')}
              subTitle={unlocked ? 'BOXO' : null}
              header={unlocked ? t('You win') : ''}
              img="/img/black-target.svg"
              amount={bonus?.params?.amount}
              color="emerald" //amethyst-velvet
              todoList={null}
            />

            <div className="mt-4">
              <TodoList
                header={false ? t('Complete the level') : t('How to get bonus')}
                items={todoList}
              />
            </div>

            {unlocked && stats && (
              <SuccessScoreRating
                value={stats.win_ratio * 1}
                header={t('Success ratio')}
              />
            )}

            {
              //JSON.stringify(stats)
            }
          </div>
          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {!unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onPlay()}
              >
                {t(`Continue`)}
              </div>
            )}

            {unlocked && !isPremium && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onClaimBonus(bonus)}
              >
                {t(`Claim`)}
              </div>
            )}

            {unlocked && isPremium && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)}
              >
                <BtnIcon src="/img/black-rocket.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}

            {false && unlocked && !!bonus?.useAdBoost && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)} //setShowAds(true)}
              >
                <BtnIcon src="/img/btn-play-video-secondary.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function BonusBox({ color = '#3FEE76', lidOpen = false }) {
  const { bonus: bonusSound } = useSoundContext();
  const [lidTop, setLidTop] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [show, setShow] = useState(false);
  const lidOpenPos = 50;
  const lidClosePos = 0;

  const nativeApp = useNativeAppContext();

  useEffect(() => {
    setTimeout(() => setShow(true), 200);

    if (lidOpen) {
      setTimeout(() => {
        setLidTop(lidOpenPos);
        setAnimate(true);
        //nativeApp.vibrate(400);
        nativeApp.haptics('success');
        bonusSound && bonusSound();
      }, 700);
    } else setLidTop(lidClosePos);
  }, [lidOpen, bonusSound]);

  useInterval(
    () => {
      if (lidOpen)
        setLidTop(lidTop === lidOpenPos ? lidOpenPos - 8 : lidOpenPos);
    },
    animate ? 1000 : null
  );

  const imgStyle = {
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%',
    transition: 'all 1s ease-in-out',
  };
  return (
    <>
      <div
        style={{
          position: 'relative',
          height: '30vh',
          minHeight: '30vh',
          width: '250px',
          left: '50%',
          transform: 'translateX(-50%)',
          opacity: show ? 1 : 0,
          transition: 'opacity 0.2s',
        }}
      >
        <BonusBlackBoxSvg
          width="55%"
          style={{
            ...imgStyle,
            top: `${82}px`,
          }}
        />

        <BonusBoxSvg
          style={{
            ...imgStyle,
            top: `${85 - lidTop + Math.ceil((2 * lidTop) / 3)}px`,
          }}
          width="55%"
          color={color}
        />
        <BonusBoxSvg
          style={{
            ...imgStyle,
            top: `${85 - lidTop + Math.ceil(lidTop / 3)}px`,
          }}
          width="55%"
          color={color}
        />
        <BonusBoxSvg
          style={{
            ...imgStyle,
            top: `${85}px`,
          }}
          width="55%"
          color={color}
        />
        <BonusBlackLidSvg
          width="55%"
          style={{
            ...imgStyle,
            top: `${55 - lidTop}px`,
          }}
        />
      </div>
    </>
  );
}

function RecoveryBonusDialog({ onPlay, onClaimBonus }) {
  const { user, balance } = useUserContext();
  const isPremium = user?.premiumExpiresAt >= Date.now();

  const { setBonusSeen, getActualRecoveryBonus, isLoading } =
    useBonusesContext();
  const [bonus, setBonus] = useState(null);
  useEffect(() => {
    !isLoading &&
      getActualRecoveryBonus().then((newBonus) => {
        setBonus(newBonus);
        setBonusSeen(newBonus);
      });
  }, [isLoading]);

  const lowBalance = balance < Number(bonus?.params?.minBalance);
  const unlocked =
    bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}
      {bonus && (
        <ModalLayout>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Recovery bonus')}</h1>
          </div>
          <div className="modal-body">
            <BonusCard
              name={t('Recovery bonus')}
              img="/img/black-heart-flat.svg"
              amount={bonus?.params?.amount ? bonus?.params?.amount : 10}
              color="ruby-red"
              todoList={[
                {
                  name: t(`Recover your balance smaller than {{amount}}`, {
                    amount: bonus?.params?.minBalance
                      ? bonus?.params?.minBalance
                      : 10,
                  }),
                  isDone: bonus?.params?.minBalance
                    ? balance < Number(bonus?.params?.minBalance)
                    : false,
                },
                {
                  name: `${t('Claim the bonus once per hour')}${
                    bonus?.availableAt - Date.now() < 0
                      ? ''
                      : `. ${millisecondsLeftToStr(
                          Math.max(0, bonus?.availableAt - Date.now())
                        )}`
                  }`,
                  isDone:
                    bonus?.availableAt === null ||
                    bonus?.availableAt - Date.now() < 0,
                },
              ]}
            />
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {!unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onBack()}
              >
                {t(`Back`)}
              </div>
            )}
            {!lowBalance && unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onPlay()}
              >
                {t(`Continue`)}
              </div>
            )}
            {!isPremium && lowBalance && unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onClaimBonus(bonus)}
              >
                {t(`Claim`)}
              </div>
            )}
            {!isPremium && lowBalance && unlocked && !!bonus?.useAdBoost && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)}
              >
                <BtnIcon src="/img/btn-play-video-secondary.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}

            {isPremium && lowBalance && unlocked && !!bonus?.useAdBoost && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)}
              >
                <BtnIcon src="/img/black-rocket.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

export function BonusCard({
  color = 'ruby-pink',
  showPlus = false,
  img = '/img/black-box-flat.svg',
  amount = '',
  header = null,
  subTitle = 'BOXO',
  todoList = [
    { name: 'Task 1', isDone: true },
    { name: 'Task 2', isDone: false },
  ],
}) {
  const txt = `${showPlus ? '+' : ''}${amount}`;
  const txtFontSize =
    `${txt}`.length <= 3
      ? '3.5rem'
      : `${txt}`.length <= 4
      ? '2.5rem'
      : `${txt}`.length <= 5
      ? '2rem'
      : `${txt}`.length <= 6
      ? '1.8rem'
      : `${txt}`.length <= 7
      ? '1.5rem'
      : '1.2rem';

  return (
    <>
      <div
        style={{
          transform: 'translateX(-50%)',
          position: 'relative',
          left: '50%',
          height: '28vh',
          width: '30vh',
          minHeight: '28vh',
          background: `
          radial-gradient(ellipse at 55% 55%, #00000000, #0000001f 40%, #00000044 70%, #000000 200%),  
          linear-gradient(80.96deg, var(--theme-${color}-dark) -1.34%, var(--theme-${color}-light) 210%)            
          `,
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.7)',
          borderRadius: '24px',
          position: 'relative',
          opacity: 0.9,
        }}
      >
        <div
          style={{
            position: 'absolute',
            borderTopLeftRadius: '24px',
            borderBottomLeftRadius: '24px',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            left: '0px',
            top: 0,
          }}
        >
          <svg
            height="100%"
            width="50%"
            viewBox="0 0 167 231"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-0.00390625 2.06836C-0.00390625 0.963793 0.891524 0.0683594 1.99609 0.0683594H166.38L157.068 9.47002C150.636 15.9638 143.261 21.4501 135.192 25.7437C114.692 36.6525 99.1452 54.9957 91.7448 77.0071L62.5909 163.721C55.4893 184.844 41.5358 202.989 22.9451 215.277L-0.00390625 230.446V2.06836Z"
              fill="white"
              fillOpacity="0.1"
              style={{ mixBlendMode: 'lighten' }}
            />
          </svg>
        </div>

        <div className="row g-0 h-100 flex-nowrap">
          {img && (
            <div className={`col-${!amount ? '12' : '6'}`}>
              <img
                src={img}
                alt=""
                style={{
                  transform: `translate(${!amount ? '-50%' : '-40%'}, -50%)`,
                  top: '50%',
                  left: '50%',
                  height: '40%',
                  position: 'relative',
                }}
              />
            </div>
          )}
          {!!amount && (
            <div className={img ? 'col-6' : 'col-12'}>
              <div
                style={{
                  transform: `translate(${
                    showPlus || !img ? '-50%' : '-60%'
                  }, ${header ? '-47%' : '-30%'})`,
                  top: '50%',
                  left: '50%',
                  position: 'relative',
                  fontWeight: '400',
                }}
              >
                {header && (
                  <div className="text-center">
                    <span>{header}</span>
                  </div>
                )}
                <div
                  className="text-center"
                  style={{
                    lineHeight: '80%',
                    fontSize: txtFontSize,
                    fontWeight: '600',
                    letterSpacing: '-0.03em',
                    color: '#ffffff',
                  }}
                >
                  {showPlus && (
                    <span
                      style={{
                        position: 'absolute',
                        transform: 'translateX(-100%)',
                      }}
                    >
                      +
                    </span>
                  )}
                  {amount}
                </div>
                <div
                  className="text-center"
                  style={
                    {
                      //transform: 'translate(-60%, -50%)',
                      //top: '42%',
                      //left: '50%',
                      //position: 'relative',
                    }
                  }
                >
                  <small>{subTitle ? subTitle : <br />}</small>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {todoList && (
        <div className="mt-4">
          <TodoList header={t('How to get bonus')} items={todoList} />
        </div>
      )}
    </>
  );
}

function BonusCalendarCard({
  dayIndex = 0,
  daysCount = 10,
  amountIncrement = 2,
  minAmount = 25,
  disabled = false,
}) {
  const calendar = Array(daysCount)
    .fill(0)
    .map((_, idx) => ({
      amount: minAmount * amountIncrement ** idx,
      day: idx + 1,
    }));
  return (
    <div style={{ padding: '0 30px' }}>
      <div className="row g-2">
        {calendar.map((c, idx) => (
          <SmallBox
            key={idx}
            disbled={disabled ? idx <= dayIndex : idx < dayIndex}
            color={idx === dayIndex ? 'ruby-pink' : 'silver'}
            amount={c.amount}
            day={c.day}
          />
        ))}
      </div>
    </div>
  );
}

function SmallBox({
  color = 'ruby-pink',
  amount = '',
  day = '1',
  disbled = false,
}) {
  return (
    <>
      <div
        className="card"
        style={{
          height: '8.6vh',
          width: '25%',
          backgroundColor: 'transparent',
          opacity: disbled ? 0.5 : 1,
        }}
      >
        <div
          style={{
            left: '0',
            height: '100%',
            width: '100%',
            background: `
          radial-gradient(ellipse at 40% 40%, #00000000, #0000001f 60%, #00000054 80%, #000000 200%),  
          linear-gradient(80.96deg, var(--theme-${color}-dark) -1.34%, var(--theme-${color}-light) 210%)            
          `,
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.7)',
            borderRadius: '12px',
            position: 'relative',
            opacity: 0.9,
          }}
        >
          <div
            style={{
              position: 'absolute',
              borderTopLeftRadius: '12px',
              borderBottomLeftRadius: '12px',
              overflow: 'hidden',
              height: '100%',
              width: '50%',
              left: '0px',
              top: 0,
            }}
          >
            <svg
              height="100%"
              width="100%"
              viewBox="0 0 167 231"
              fill="none"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-0.00390625 2.06836C-0.00390625 0.963793 0.891524 0.0683594 1.99609 0.0683594H166.38L157.068 9.47002C150.636 15.9638 143.261 21.4501 135.192 25.7437C114.692 36.6525 99.1452 54.9957 91.7448 77.0071L62.5909 163.721C55.4893 184.844 41.5358 202.989 22.9451 215.277L-0.00390625 230.446V2.06836Z"
                fill="white"
                fillOpacity="0.2"
                style={{ mixBlendMode: 'lighten' }}
              />
            </svg>
          </div>

          <div className="row g-0 h-100 flex-nowrap">
            <div>
              <div
                style={{
                  transform: 'translate(-50%, calc(-50% - 1px))',
                  top: '50%',
                  left: '50%',
                  position: 'relative',
                }}
              >
                <div
                  className="text-center"
                  style={
                    {
                      //opacity: 0.8,
                    }
                  }
                >
                  <small>{t('Day {{day}}', { day })}</small>
                </div>

                <div
                  className="text-center"
                  style={{
                    lineHeight: '100%',
                    marginTop: '1px',
                    fontWeight: '400',
                    letterSpacing: '-0.04em',
                    color: '#ffffff',
                  }}
                >
                  {priceFmt(amount)}
                </div>

                <div
                  className="text-center"
                  style={{
                    fontSize: '0.5rem',
                    opacity: 0.25,
                    fontWeight: '100',
                  }}
                >
                  <small>BOXO</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function NoBonusDialog() {
  const logger = useLoggerContext();
  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  const [show, setShow] = useState(false);
  const clearInterval = useInterval(() => {
    setShow(true);
    clearInterval();

    logger?.event('error', {
      msg: `No bonus at ${window.location.pathname}`,
    });
  }, 2000);

  const { getActualBonuses, invalidateBonuses } = useBonusesContext();

  return (
    <ModalLayout onBack={show ? onBack : null} onClose={null}>
      {show && (
        <>
          <div className="modal-header">
            <h1 className="text-center"></h1>
            <div
              className="text-center text-muted"
              style={{ marginTop: '30vh' }}
            >
              <img
                src="/img/time.svg"
                alt=""
                style={{
                  width: '11px',
                  marginTop: '-2px',
                  marginLeft: '0px',
                  marginRight: '6px',
                  opacity: 0.4,
                }}
              />
              {t('You have no bonus yet')}
            </div>
          </div>
          <div className="modal-footer no-mobile-keyboard justify-content-center">
            <div
              className="btn btn-primary"
              role="button"
              onClick={() => {
                logger?.event('click', {
                  target: `Refresh bonus`,
                });
                invalidateBonuses();
                getActualBonuses();
              }}
            >
              {t(`Refresh`)}
            </div>
          </div>
        </>
      )}
    </ModalLayout>
  );
}

function HealthBonusDialog({ onClaimBonus }) {
  const { setBonusSeen, getActualAdsBonus, isLoading } = useBonusesContext();
  const [bonus, setBonus] = useState(null);

  useEffect(() => {
    !isLoading &&
      getActualAdsBonus().then((newBonus) => {
        setBonus(newBonus);
        setBonusSeen(newBonus);
      });
  }, [isLoading]);

  const unlocked =
    bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  const { user } = useUserContext();
  const isPremium = user?.premiumExpiresAt >= Date.now();

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}

      {bonus && (
        <ModalLayout>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Vitamins')}</h1>
          </div>
          <div className="modal-body">
            <BonusCard
              name={t('Vitamins')}
              img="/img/pill.svg"
              amount={bonus?.params?.amount ? bonus?.params?.amount : 10}
              color="crystal" //ruby-pink
              todoList={[
                {
                  name: `${t('Claim the bonus once per hour')}${
                    bonus?.availableAt - Date.now() < 0
                      ? ''
                      : `. ${millisecondsLeftToStr(
                          Math.max(0, bonus?.availableAt - Date.now())
                        )}`
                  }`,
                  isDone:
                    bonus?.availableAt === null ||
                    bonus?.availableAt - Date.now() < 0,
                },
              ]}
            />
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {!unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onBack()}
              >
                {t(`Back`)}
              </div>
            )}

            {!isPremium && unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onClaimBonus(bonus)} //setShowAds(true)}
              >
                {t(`Claim`)}
              </div>
            )}
            {!isPremium && unlocked && !!bonus?.useAdBoost && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)} //setShowAds(true)}
              >
                <BtnIcon src="/img/btn-play-video-secondary.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}

            {isPremium && unlocked && !!bonus?.useAdBoost && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)}
              >
                <BtnIcon src="/img/black-rocket.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function StreakBonusDialog({ onClaimBonus }) {
  const { setBonusSeen, getActualStreakBonus, isLoading } = useBonusesContext();
  const [bonus, setBonus] = useState(null);

  useEffect(() => {
    !isLoading &&
      getActualStreakBonus().then((newBonus) => {
        setBonus(newBonus);
        setBonusSeen(newBonus);
      });
  }, [isLoading]);

  const unlocked =
    bonus?.expired - Date.now() > 0 &&
    (bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0);

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  const { user } = useUserContext();
  const isPremium = user?.premiumExpiresAt >= Date.now();

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}

      {bonus && (
        <ModalLayout>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Daily bonus')}</h1>

            {unlocked && bonus?.params?.dayIndex > 0 && (
              <div className="text-center">
                <img
                  src="/img/time.svg"
                  alt=""
                  style={{
                    width: '11px',
                    marginTop: '0px',
                    marginLeft: '0px',
                    marginRight: '6px',
                  }}
                />
                <span>
                  <small>
                    {t('Claim now')}
                    {'! '}
                    {millisecondsLeftToStr(
                      Math.max(0, bonus.expired - Date.now()),
                      true
                    )}
                  </small>
                </span>
              </div>
            )}
          </div>
          <div className="modal-body">
            <BonusCalendarCard
              //name={t('Health bonus')}
              //img="/img/black-health.svg"
              amount={bonus?.params?.amount ? bonus?.params?.amount : 10}
              color="ruby-pink"
              dayIndex={bonus?.params?.dayIndex}
              daysCount={bonus?.params?.daysCount}
              amountIncrement={bonus?.params?.amountIncrement}
              minAmount={bonus?.params?.minAmount}
              disabled={
                bonus?.availableAt && bonus?.availableAt - Date.now() > 0
              }
            />
            <div className="mt-4">
              {bonus?.disabled && (
                <TodoList
                  header={t('How to get bonus')}
                  items={[
                    {
                      name: `${t('Claim the bonus once per day')}${
                        bonus?.availableAt - Date.now() < 0
                          ? ''
                          : `. ${millisecondsLeftToStr(
                              Math.max(0, bonus?.availableAt - Date.now())
                            )}`
                      }`,
                      isDone:
                        bonus?.availableAt === null ||
                        bonus?.availableAt - Date.now() < 0,
                    },
                  ]}
                />
              )}
            </div>
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {!unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onBack()}
              >
                {t(`Back`)}
              </div>
            )}

            {!isPremium && unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onClaimBonus(bonus)}
              >
                {t(`Claim`)}
              </div>
            )}
            {!isPremium && unlocked && !!bonus?.useAdBoost && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)}
              >
                <BtnIcon src="/img/btn-play-video-secondary.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}

            {isPremium && unlocked && !!bonus?.useAdBoost && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)}
              >
                <BtnIcon src="/img/black-rocket.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function SponsoredBonusDialog({ onClaimBonus }) {
  const { setBonusSeen, getActualSponsoredBonus, isLoading } =
    useBonusesContext();
  const [bonus, setBonus] = useState(null);

  useEffect(() => {
    !isLoading &&
      getActualSponsoredBonus().then((newBonus) => {
        setBonus(newBonus);
        setBonusSeen(newBonus);
      });
  }, [isLoading]);

  const unlocked =
    bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  const { user } = useUserContext();
  const isPremium = user?.premiumExpiresAt >= Date.now();

  const nativeApp = useNativeAppContext();

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}

      {bonus && (
        <ModalLayout>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Giveaway')}</h1>
          </div>
          <div className="modal-body">
            <BonusCard
              name={t('Giveaway')}
              img="/img/gift.svg"
              amount={bonus?.params?.amount ? bonus?.params?.amount : 10}
              color="ruby-pink"
              todoList={[
                {
                  name: `${t("Open the sponsor's link")}${
                    bonus?.availableAt - Date.now() < 0
                      ? ''
                      : `. ${millisecondsLeftToStr(
                          Math.max(0, bonus?.availableAt - Date.now())
                        )}`
                  }`,
                  descr: bonus?.params?.sponsor
                    ? `${t('Sponsored by')}: ${bonus?.params?.sponsor}`
                    : null,
                  isDone: false,
                },
              ]}
            />
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {!unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onBack()}
              >
                {t(`Back`)}
              </div>
            )}

            {!isPremium && unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => {
                  nativeApp.haptics('success');
                  nativeApp.openWebBrowser(bonus?.params?.url);
                  onClaimBonus(bonus);
                }}
              >
                {t(`Open`)}
              </div>
            )}
            {!isPremium && unlocked && !!bonus?.useAdBoost && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)} //setShowAds(true)}
              >
                <BtnIcon src="/img/btn-play-video-secondary.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}

            {isPremium && unlocked && !!bonus?.useAdBoost && (
              <div
                className="btn btn-secondary"
                role="button"
                onClick={() => onClaimBonus(bonus, true)}
              >
                <BtnIcon src="/img/black-rocket.svg" />
                {t(`Claim`)} {`× ${bonus.adBoost}`}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function TutorialBonusDialog({}) {
  const [params] = useSearchParams();
  const amount = params.get('amount') * 1 ?? 50;
  const limit = params.get('limit') * 1 ?? 15;
  const progress = params.get('progress') * 1 ?? 0;
  const name = params.get('name') ?? 0;
  const backName = params.get('backName');
  const color = params.get('color');

  const bonus = { params: { amount } };

  const unlocked = limit <= progress;

  const nativeApp = useNativeAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  const { bonus: bonusSound } = useSoundContext();
  useEffect(() => {
    if (unlocked) {
      bonusSound(); //success()
      nativeApp.haptics('success');
      nativeApp.vibrate(400);
    }
  }, []);

  return (
    <>
      {bonus && (
        <ModalLayout>
          {unlocked && <Confetti />}

          <div className="modal-header">
            <h1 className="text-center text-default text-capitalize">
              {name ? name : t('Bonus')}
            </h1>
          </div>
          <div className="modal-body">
            <BonusCard
              showPlus
              header={unlocked ? t('You win') : t('Bonus')}
              //img="/img/black-target.svg"
              img="/img/gift.svg"
              amount={bonus?.params?.amount ?? 10}
              color={color} // !== '' ? color : 'ruby-pink'} //"crystal"
              todoList={
                unlocked
                  ? null
                  : [
                      {
                        name: t('Collect {{limit}} successful boxes', {
                          limit,
                        }),
                        isDone: false,
                      },
                    ]
              }
            />
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center text-capitalize">
            {!unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onBack()}
              >
                {backName && backName !== '' ? backName : t(`Continue`)}
              </div>
            )}
            {unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() =>
                  navigate({
                    pathname: './claim',
                    search: createSearchParams({
                      amount,
                    }).toString(),

                    replace: true,
                  })
                }
              >
                {t(`Claim`)}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function TutorialClaimBonusDialog({ onClaimBonus }) {
  const navigate = useNavigate();
  const nativeApp = useNativeAppContext();
  const [params] = useSearchParams();
  const amount = params.get('amount') ?? 50;
  const bonusColor = 'var(--theme-primary)';
  const bonus = { typeId: 'tutorial', amount };

  const { levelUp } = useSoundContext();
  useEffect(() => {
    levelUp(); //bonusSound();
    nativeApp.vibrate(400);
    nativeApp.haptics('success');
  }, []);

  return (
    <ModalLayout>
      <div className="modal-header">
        <h1 className="text-center text-default">{t('Congrats!')}</h1>
        <div className="text-center text-muted">
          <small>{t('Bonus is credited')}</small>
        </div>
      </div>
      <div className="modal-body">
        {/* <BonusBox color={bonusColor} lidOpen={true} /> */}
        <img
          src="/img/fox/win.png"
          style={{
            height: 'auto',
            width: '100%',
            margin: '2vh 0',
            margin: '2svh 0',
          }}
        />

        <div
          className="text-nowrap"
          style={{
            position: 'absolute',
            width: '90%',
            top: '70%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 1px)',
            backdropFilter: 'blur(2px)',
          }}
        >
          <TodoListDone
            bgColor={bonusColor}
            caption={t('You earned {{amount}} BOXO', {
              amount,
            })}
          />
        </div>
      </div>
      <div className="modal-footer no-mobile-keyboard justify-content-center">
        <div
          className="btn btn-primary"
          role="button"
          onClick={() => {
            onClaimBonus({ ...bonus, claimedAmount: amount });
            navigate('/app', { replace: true });
          }}
        >
          {t(`Continue`)}
        </div>
      </div>
    </ModalLayout>
  );
}

function TutorialLoseDialog({}) {
  const navigate = useNavigate();
  const { fail } = useSoundContext();
  useEffect(() => {
    fail();
  }, []);

  return (
    <ModalLayout>
      <div className="modal-header">
        <h1 className="text-center text-default">{t('Failed')}!</h1>
        <div className="text-center text-red">
          <small>{t('You are out of coins')}</small>
        </div>
      </div>
      <div className="modal-body text-center">
        <img
          src="/img/fox/lose.png"
          style={{
            height: 'auto',
            width: '100%',
            margin: '2vh 0',
            margin: '2svh 0',
          }}
        />

        <div
          className="text-nowrap"
          style={{
            position: 'absolute',
            width: '90%',
            top: '70%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 1px)',
            backdropFilter: 'blur(2px)',
          }}
        >
          <TodoListDone
            bgColor="var(--theme-red-dark-transparent)"
            caption={t('Try again')}
          />
        </div>
      </div>
      <div className="modal-footer no-mobile-keyboard justify-content-center">
        <div
          className="btn btn-primary"
          role="button"
          onClick={() => {
            //onRestartGame();
            navigate('/app', { replace: true });
          }}
        >
          {t(`Restart`)}
        </div>
      </div>
    </ModalLayout>
  );
}

function TutorialWinDialog({ onClaimBonus, multiplier }) {
  const [params] = useSearchParams();
  const amount = params.get('amount') * 1 ?? 50;
  const limit = params.get('limit') * 1 ?? 15;
  const progress = params.get('progress') * 1 ?? 0;
  const name = params.get('name') ?? 0;
  const backName = params.get('backName');
  const color = params.get('color');

  const bonus = { params: { amount } };

  const unlocked = limit <= progress;

  const nativeApp = useNativeAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  const { bonus: bonusSound } = useSoundContext();

  const [mult, setMult] = useState(1);
  useEffect(() => {
    if (multiplier) setMult(multiplier);
  }, [multiplier]);

  useEffect(() => {
    if (unlocked) {
      bonusSound(); //success()
      nativeApp.haptics('success');
      nativeApp.vibrate(400);
    }
  }, []);

  return (
    <>
      {bonus && (
        <ModalLayout>
          {unlocked && <Confetti />}

          <div className="modal-header">
            <h1 className="text-center text-default text-capitalize">
              {name ? name : t('Bonus')}
            </h1>
          </div>
          <div className="modal-body">
            <BonusCard
              header={unlocked ? t('You win') : t('Bonus')}
              img={null} //"/img/gift.svg"
              amount={bonus?.params?.amount * mult ?? 10}
              color={color} // !== '' ? color : 'ruby-pink'} //"crystal"
              todoList={
                unlocked
                  ? null
                  : [
                      {
                        name: t('Collect {{limit}} successful boxes', {
                          limit,
                        }),
                        isDone: false,
                      },
                    ]
              }
            />
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center text-capitalize">
            {!unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onBack()}
              >
                {backName && backName !== '' ? backName : t(`Continue`)}
              </div>
            )}
            {unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() =>
                  onClaimBonus({
                    typeId: 'tutorial',
                    amount: 0,
                    claimedAmount: 0,
                  })
                }
              >
                {t(`Continue`)}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

export { BonusListDialog };
