import { useEffect } from 'react';
import Terminal from './pages/terminal';
import Landing from './pages/landing';
import Header from './shared/header';
import { useLoggerContext } from './shared/hooks/use-logger';
import { Route, Routes, useLocation } from 'react-router-dom';
import NotFound from './pages/404';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useLocalStorage } from './shared/hooks/use-hooks';
import { SoundContext } from './shared/hooks/use-sound';
import { InstallPromptContext, Install } from './pages/install';
import Quiz from './pages/quiz';
import { isNativeApp, isTelegram } from './shared/utils';
import { useTelegramContext } from './shared/hooks/use-telegram';
import { useNativeAppContext } from './shared/hooks/use-native-app';
import { Site } from './site';

export default function App() {
  const [lng, setLng] = useLocalStorage('lng', null);
  const [_, setLngDetected] = useLocalStorage('lng-detected', null);

  useEffect(() => {
    if (lng && lng !== 'auto') i18next.changeLanguage(lng); //remove auto legacy
    else {
      window.localStorage.removeItem('i18nextLng');
      i18next.use(LanguageDetector).init({
        keySeparator: '>',
        nsSeparator: '|',
      });
      setLngDetected(i18next.language);
    }
    const newLng = i18next.language;
    document.documentElement.lang = newLng;
    setLng(newLng);
  }, [lng]);

  const logger = useLoggerContext();

  const nativeApp = useNativeAppContext();
  const location = useLocation();
  useEffect(() => {
    nativeApp.haptics('light');
  }, [location.pathname]);

  // const height = window.screen.availHeight;
  // const width = window.screen.availWidth;
  //  const isSite = !(isNativeApp() || (isTelegram() && width < height));

  return (
    <InstallPromptContext>
      <SoundContext>
        <Routes>
          <Route path="/install" element={<Install logger={logger} />} />
          <Route
            path="/installing"
            element={<Install installing={true} logger={logger} />}
          />
          <Route
            path="/installed"
            element={<Install hasInstalled={true} logger={logger} />}
          />
          <Route
            path="/autoInstall"
            element={<Install autoInstall={true} logger={logger} />}
          />

          {/* {isSite && <Route path="/" element={<Site />} />}
          <Route path="/site/*" element={<Site />} /> */}
          <Route path="/" element={<Site />} />
          <Route path="/site/*" element={<Site />} />

          <Route path="*" element={<AppLayout logger={logger} />} />
        </Routes>
      </SoundContext>
    </InstallPromptContext>
  );
}

function AppLayout({}) {
  const { inset } = useTelegramContext();

  return (
    <div
      style={{
        '--inset-top': `${isTelegram() ? inset?.top : 0}px`,
        '--inset-bottom': `${isTelegram() ? inset?.bottom : 0}px`,
      }}
      className="app"
      id="boxo-app"
    >
      <Header
        title=""
        description={`Play a fun & easy stock trading simulator! Trade Bitcoin, crypto & stocks in a realistic stock market game. No risk, just pure trading excitement!`}
      />

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/campaign" element={<Landing isLandingForAds />} />
        <Route path="/home" element={<Landing />} />
        <Route path="/app/*" element={<Terminal inset={inset} />} />
        <Route path="/quiz/*" element={<Quiz />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
