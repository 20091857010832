import { QRCodeSVG } from 'qrcode.react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { GooglePlayButton, AppStoreButton } from 'react-mobile-app-button';
import { Route, Routes } from 'react-router-dom';
import { Loader } from '../pages/loader';
import Header from '../shared/header';
import { isNativeApp, isPwa, isTelegram } from '../shared/utils';
import ReactMarkdown from 'react-markdown';
import './styles/style.scss';
import NotFound from '../pages/404';
import { useLoggerContext } from '../shared/hooks/use-logger';
import rehypeExternalLinks from 'rehype-external-links';

const androidUrl =
  'https://play.google.com/store/apps/details?id=com.BoxoTradeApp';
const iosUrl =
  'https://apps.apple.com/app/trading-simulator-boxo-game/id6725853622';
const downloadUrl = isTelegram()
  ? 'https://t.me/boxo_game_bot'
  : 'https://boxo.trade/download?src=qrCodeSite';

const blogs_ = [
  {
    title: 'Stock Market Simulator',
    description:
      'BOXO is the best stock market simulator for learning how to trade',
    md: './blog/1.md',
    img: '/site/img/top-1.jpg',
  },

  {
    title: 'Learn Stock Trading Risk-Free',
    description:
      'Practice Investing Risk-Free! Stock simulators help students learn trading with virtual money. Try Boxo Game, Investopedia & more!',
    md: './blog/2.md',
    img: '/site/img/top-2.jpg',
  },

  {
    title: 'Stock Market Game vs Real Trading',
    description:
      'Stock market simulators like Boxo offer risk-free trading practice, while real trading builds experience. Which is better for learning?',
    md: './blog/3.md',
    img: '/site/img/top-3.jpg',
  },

  {
    title: 'Trading Game',
    description:
      'Master trading with Trading Game—a fun, risk-free simulation to sharpen your market skills and compete globally!',
    md: './blog/4.md',
    img: '/site/img/top-4.jpg',
  },

  {
    title: 'Stock Market Game',
    description:
      'Explore stock market games like Boxo, a fast-paced Bitcoin prediction game, and learn how simulations can sharpen your trading skills risk-free!',
    md: './blog/5.md',
    img: '/site/img/slider-2.jpg',
  },
];

const staticUrl = (url) => url; //process.env.NODE_ENV === 'production' ? `${url}.html` : url;

const blogs = blogs_.map((b) => ({
  ...b,
  url: staticUrl(`/site/blog/${b.title.replaceAll(' ', '-').toLowerCase()}`),
}));

export function Site({}) {
  const height = window.screen.availHeight;
  const width = window.screen.availWidth;
  const isApp = isNativeApp() || (isTelegram() && width < height) || isPwa();

  useEffect(() => {
    if (isApp && window.location.pathname === '/')
      window.location.href = '/home';
  }, []);
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {blogs.map((b, idx) => {
          return (
            <Route
              key={idx}
              path={b.url.replace('/site', '').replace('.html', '')}
              element={
                <BlogPage
                  idx={idx}
                  title={b.title}
                  description={b.description}
                  md={b.md}
                  img={b.img}
                />
              }
            />
          );
        })}
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/sitemap" element={<SitemapPage />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

function SitemapPage() {
  const appUrls = [
    '/home',
    '/app',
    '/app/auth/login',
    '/app/auth/sign-up',
    '/app/auth/reset',
    '/app/price',
    '/app/settings/language',
  ];

  const siteUrls = ['/site.html', '/site/404.html', '/site/contact.html'];
  const blogUrls = blogs.map((b) => `${b.url}.html`);
  const urls = [...appUrls, ...siteUrls, ...blogUrls];

  const baseUrl = 'https://boxo.trade';
  return (
    <>
      <div style={{ overflow: 'auto', height: '100svh', color: '#fff' }}>
        <div style={{ padding: '10px 0' }}>Blog links: {blogUrls.length}</div>
        <div style={{ userSelect: 'all' }}>
          {[...siteUrls, ...blogUrls].map((u) => (
            <div>"{u.replace('.html', '')}",</div>
          ))}
        </div>
        <div style={{ padding: '10px 0' }}>Total links: {urls.length}</div>
        <div style={{ userSelect: 'all' }}>
          <div>{'<?xml version="1.0" encoding="UTF-8"?>'}</div>
          <div>
            {'<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">'}
          </div>
          <div>
            {urls.map((u) => (
              <>
                <div>{'  <url>'}</div>
                <div>{`    <loc>${baseUrl}${u}</loc>`}</div>
                <div>{`    <lastmod>${new Date()
                  .toISOString()
                  .slice(0, 10)}</lastmod>`}</div>
                <div>{'  </url>'}</div>
              </>
            ))}
          </div>
          <div>{'</urlset>'}</div>
        </div>
      </div>
    </>
  );
}
function HomePage({}) {
  return (
    <div>
      <SiteLayout
        title=""
        description={`Play a fun & easy stock trading simulator! Trade Bitcoin, crypto & stocks in a realistic stock market game. No risk, just pure trading excitement!`}
      >
        <Slider img="/site/img/slider-1.jpg" />
        <FeaturesSection />
      </SiteLayout>
    </div>
  );
}

function ContactPage({}) {
  const title = 'Contact Us';
  const description = 'We are here to help you!';
  const img = '/site/img/slider-3.jpg';

  const nameRef = useRef();
  const subjectRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const logger = useLoggerContext();
  return (
    <>
      <SiteLayout title={title} description={description}>
        <HeroImage title={title} subTitle={description} img={img} />
        <section className="page-section spad contact-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <h4 className="comment-title">{title}</h4>
                <p>
                  Have a question or need assistance? You can reach us via
                  email, fill out our contact form, or{' '}
                  <a
                    style={{ color: 'var(--theme-primary)' }}
                    href="https://boxo.on.spiceworks.com"
                    rel="external nofollow noopener"
                  >
                    create a support ticket
                  </a>
                  . We're here to help!
                </p>
                <div className="row">
                  <div className="col-md-9">
                    <ul className="contact-info-list">
                      <li>
                        <div className="cf-left">Support</div>
                        <div className="cf-right">support@boxo.trade</div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="social-links">
                  <a href="#">
                    <i className="fa fa-pinterest"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-dribbble"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-behance"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div> */}
              </div>
              <div className="col-lg-8">
                <div className="contact-form-warp">
                  <h4 className="comment-title">Leave a Reply</h4>
                  <form
                    className="comment-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      const name = nameRef.current.value;
                      const message = messageRef.current.value;
                      const email = emailRef.current.value;
                      const subject = subjectRef.current.value;

                      nameRef.current.value = '';
                      messageRef.current.value = '';
                      emailRef.current.value = '';
                      subjectRef.current.value = '';

                      logger?.event('messsage', {
                        name,
                        email,
                        subject,
                        message,
                      });
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <input type="text" placeholder="Name" ref={nameRef} />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          placeholder="Email"
                          ref={emailRef}
                        />
                      </div>
                      <div className="col-lg-12">
                        <input
                          type="text"
                          placeholder="Subject"
                          ref={subjectRef}
                        />
                        <textarea
                          placeholder="Message"
                          ref={messageRef}
                        ></textarea>
                        <button className="site-btn btn-sm">Send</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
}

function BlogPage({ idx, md, img, title, description }) {
  const searchText = useRef();

  const [mdFile, setMdFile] = useState();
  useEffect(() => {
    try {
      fetch(require(`${md}`)).then((r) =>
        r.text().then((text) => setMdFile(text))
      );
    } catch (e) {
      setMdFile(e);
    }
  }, [md]);

  return (
    <div>
      <SiteLayout title={title} description={description}>
        <HeroImage title={title} subTitle={description} img={img} />

        <section
          className="page-section recent-game-page spad"
          style={{ backgroundColor: 'white' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-md-12 blog">
                    <ReactMarkdown
                      rehypePlugins={[
                        [
                          rehypeExternalLinks,
                          {
                            rel: 'external nofollow noopener',
                            target: '_blank',
                          },
                        ],
                      ]}
                    >
                      {mdFile}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-7 sidebar pt-5 pt-lg-0">
                <div className="widget-item">
                  <form
                    className="search-widget"
                    onSubmit={(e) => {
                      e.preventDefault();
                      const value = searchText.current.value;
                      const url = `https://www.google.com/search?q=site%3Aboxo.trade+${value}`;
                      if (value) window.open(url, '_blank');
                    }}
                  >
                    <input ref={searchText} type="text" placeholder="Search" />
                    <button>
                      <i className="fa fa-search" />
                    </button>
                  </form>
                </div>

                <div className="widget-item">
                  <h4 className="widget-title">Read More</h4>
                  <div className="latest-blog">
                    {[1, 2, 3].map((i) => (
                      <ReadMoreItem key={i} idx={idx + i} />
                    ))}
                  </div>
                </div>

                <FeaturedReadMoreItem idx={idx + 4} />

                <div className="widget-item">
                  <div
                    className="review-item text-center"
                    style={{
                      justifyItems: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        height: '200px',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          top: '40px',
                        }}
                      >
                        <GooglePlayButton
                          title=""
                          className="store-btn"
                          url={androidUrl}
                          theme={'dark'}
                        />
                        <br />
                        <AppStoreButton
                          title=""
                          className="store-btn"
                          url={iosUrl}
                          theme={'dark'}
                        />
                      </div>
                    </div>
                    <div className="review-text">
                      <QRCodeSVG size="150px" value={downloadUrl} />

                      <h5>Download</h5>
                      <p>
                        BOXO GAME is also available in the{' '}
                        <a className="text-primary" href="/home">
                          browser
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </div>
  );
}

export function Slider({ img = '' }) {
  return (
    <>
      <section className="hero-section">
        <div className="hero-slider owl-carousel--">
          <div
            className="hs-item set-bg"
            style={{ backgroundImage: `url("${img}")` }}
          >
            <div className="hs-text">
              <div className="container">
                {!isTelegram() && (
                  <h2>
                    The Best <span>Trading</span> Game
                  </h2>
                )}

                {isTelegram() && <h2>Scan QR code</h2>}

                {!isTelegram() && (
                  <p>
                    Experience BOXO GAME, the ultimate stock trading simulator
                    {/* <br />
                  Trade Bitcoin and crypto in a realistic stock market simulator */}
                    <br />
                    Play, learn, and win!
                  </p>
                )}

                {isTelegram() && (
                  <p>Play Boxo Game telegram mini app on mobile</p>
                )}

                <div
                  className="hide-on-mobile hide-on-tablet"
                  style={{
                    width: '180px',
                    padding: '15px',
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    position: 'relative',
                    top: '-60px',
                  }}
                >
                  <QRCodeSVG size="150px" value={downloadUrl} />
                </div>

                {!isTelegram() && (
                  <div className="">
                    <div className="row g-4">
                      <div className="col-auto">
                        <GooglePlayButton
                          title=""
                          className="store-btn"
                          url={androidUrl}
                          theme={'dark'}
                        />
                      </div>
                      <div className="col-auto">
                        <AppStoreButton
                          title=""
                          className="store-btn"
                          url={iosUrl}
                          theme={'dark'}
                        />
                      </div>
                      <div className="col-auto">
                        <a
                          href="/home"
                          className="site-btn store-btn"
                          style={{ opacity: 1, top: 0 }}
                        >
                          Play In Browser
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div
            className="hs-item set-bg"
            style={{ backgroundImage: 'url("img/slider-2.jpg")' }}
          >
            <div className="hs-text">
              <div className="container">
                <h2>
                  Fun <span>Trading</span> Simulator
                </h2>
                <p>
                  Experience BOXO, the ultimate stock trading simulator. <br />
                  Trade stocks, Bitcoin, and crypto in a realistic stock market
                  simulator. <br />
                  Play, learn, and win!
                </p>
                <a
                  href="https://boxo.trade/download/src=website"
                  className="site-btn"
                >
                  Start Trading Now
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

function FeaturesSection() {
  return (
    <>
      <section
        className="recent-game-section spad set-bg"
        style={{ backgroundImage: 'url("/site/img/recent-game-bg.png")' }}
      >
        <div className="container">
          <div className="section-title">
            {/* <div className="cata racing">How To Play</div> */}
            <h2>Feature Highlights</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="recent-game-item">
                <div
                  className="rgi-thumb set-bg"
                  style={{
                    backgroundImage: 'url("/site/img/recent-game/1.jpg")',
                  }}
                >
                  <div className="cata new">Trade</div>
                </div>
                <div className="rgi-content">
                  <h5>Follow Live Bitcoin Price</h5>
                  <p>
                    Stay ahead with real-time Bitcoin prices and make informed
                    trading decisions in a dynamic market.
                  </p>
                  <br />
                  {/* <a className="comment">
                    3 Comments
                  </a>
                  <div className="rgi-extra">
                    <div className="rgi-star">
                      <img src="/site/img/icons/star.png" alt="" />
                    </div>
                    <div className="rgi-heart">
                      <img src="/site/img/icons/heart.png" alt="" />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="recent-game-item">
                <div
                  className="rgi-thumb set-bg"
                  style={{
                    backgroundImage: 'url("/site/img/recent-game/2.jpg")',
                  }}
                >
                  <div className="cata racing">Predict</div>
                </div>
                <div className="rgi-content">
                  <h5>Guess Bitcoin's Next Move</h5>
                  <p>
                    Test your instincts by forecasting Bitcoin’s next move and
                    sharpen your market prediction skills.
                  </p>
                  <br />
                  {/* 
                  <a className="comment">
                    3 Comments
                  </a>
                  <div className="rgi-extra">
                    <div className="rgi-star">
                      <img src="img/icons/star.png" alt="" />
                    </div>
                    <div className="rgi-heart">
                      <img src="img/icons/heart.png" alt="" />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="recent-game-item">
                <div
                  className="rgi-thumb set-bg"
                  style={{
                    backgroundImage: 'url("/site/img/recent-game/3.jpg")',
                  }}
                >
                  <div className="cata adventure">Win</div>
                </div>
                <div className="rgi-content">
                  <h5>Master Daily Challenges</h5>
                  <p>
                    Complete daily challenges, refine your strategy, and rise to
                    the top!
                  </p>
                  <br />
                  <br />
                  {/* <a className="comment">
                    3 Comments
                  </a>
                  <div className="rgi-extra">
                    <div className="rgi-star">
                      <img src="img/icons/star.png" alt="" />
                    </div>
                    <div className="rgi-heart">
                      <img src="img/icons/heart.png" alt="" />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export function SiteLayout({ title = '', description = '', children }) {
  const [showMainMenu, setShowMainMenu] = useState(false);
  return (
    <>
      <div id="site">
        <Header title={title} description={description} />

        <header className="header-section">
          <div className="container">
            <a className="site-logo" href="/">
              <img src="/site/img/logo.png" alt="" />
            </a>

            {!isTelegram() && (
              <div className="user-panel">
                <a href="/app/auth/login">Login</a> /{' '}
                <a href="/app/auth/sign-up">Register</a>
              </div>
            )}

            <div
              className="nav-switch"
              onClick={() => setShowMainMenu(!showMainMenu)}
            >
              <i className="fa fa-bars" />
            </div>

            <nav
              className={`main-menu ${showMainMenu ? 'main-menu-visible' : ''}`}
            >
              <ul>
                {/* <li>
                  <a href="/">Home</a>
                </li> */}
                {/* <li>
                  <a href="/home">Play</a>
                </li> */}
                <li>
                  <a href={blogs[4].url}>Blog</a>
                </li>
                <li>
                  <a href={staticUrl('/site/contact')}>Contact</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        {children}

        {/* <div className="latest-news-section">
        <div className="ln-title">Crypto Market</div>
        <div className="news-ticker">
          <div className="news-ticker-contant">
            <div className="nt-item">
              <span className="new">BTC/USD -2%</span>Bitcoin $99,1234
            </div>
            <div className="nt-item">
              <span className="strategy">ETH/USD +4%</span>Ethereum $2,912
            </div>
            <div className="nt-item">
              <span className="strategy">SLN/USD +0.3%</span>Solana $634
            </div>
            <div className="nt-item">
              <span className="new">LTC/USD -5%</span>Litecoin $134
            </div>
          </div>
        </div>
      </div> */}

        <section className="footer-top-section">
          <div className="container">
            <div className="footer-top-bg">
              <img src="/site/img/footer-top-bg.png" alt="" />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-logo text-white">
                  <img src="/site/img/footer-logo.png" alt="" />
                  <p>
                    Play a fun and easy trading game with real market dynamics.
                    Learn strategies, test your skills, and win by making smart
                    trades!
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="footer-widget mb-5 mb-md-0">
                  <h4 className="fw-title">Latest Posts</h4>
                  <div className="latest-blog">
                    {[0, 1, 2].map((i) => (
                      <ReadMoreItem key={i} idx={blogs.length - i} />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Latest Comments</h4>
                  <div className="top-comment">
                    <div className="tc-item">
                      <div
                        className="tc-thumb set-bg"
                        style={{
                          backgroundImage: 'url("/site/img/authors/1.jpg")',
                        }}
                      ></div>
                      <div className="tc-content">
                        <p>
                          <span className="tc-date">Yannis</span>
                          <span> on </span>
                          Predicting BTC and winning is so fun!!! 📈💰
                        </p>
                        <div className="tc-date">Feb 3, 2025, 13:36</div>
                      </div>
                    </div>
                    <div className="tc-item">
                      <div
                        className="tc-thumb set-bg"
                        style={{
                          backgroundImage: 'url("/site/img/authors/3.jpg")',
                        }}
                      ></div>
                      <div className="tc-content">
                        <p>
                          <span className="tc-date">Alec Shala</span>
                          <span> on </span>can you cash out the rewards earned?
                        </p>
                        <div className="tc-date">Dec 29, 2024, 08:23</div>
                      </div>
                    </div>
                    <div className="tc-item">
                      <div
                        className="tc-thumb set-bg"
                        style={{
                          backgroundImage: 'url("/site/img/authors/4.jpg")',
                        }}
                      ></div>
                      <div className="tc-content">
                        <p>
                          <span className="tc-date">TURTLE Showalter</span>
                          <span> on </span>Not too Shabby, and Love the Live
                          Bitcoin Price aspect... Jus sayin'....
                        </p>
                        <div className="tc-date">Dec 28, 2024, 02:09</div>
                      </div>
                    </div>

                    <div className="tc-item">
                      <div
                        className="tc-thumb set-bg"
                        style={{
                          backgroundImage: 'url("/site/img/authors/2.jpg")',
                        }}
                      ></div>
                      <div className="tc-content">
                        <p>
                          <span className="tc-date">
                            Angel Efia (Baby Efia)
                          </span>
                          <span> on </span>The game is lots of fun ❣️
                        </p>
                        <div className="tc-date">Dec 13, 2024, 04:31</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer-section">
          <div className="container">
            <ul className="footer-menu">
              {/* <li>
                <a href="/">Home</a>
              </li> */}
              <li>
                <a href={blogs[4].url}>Blog</a>
              </li>
              <li>
                <a href={staticUrl('/site/contact')}>Contact</a>
              </li>
            </ul>
            <p className="copyright">
              Copyright &copy;
              {new Date().getFullYear()} Boxo Inc. All rights reserved
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

function ReadMoreItem({ idx }) {
  const imgIdx = (idx % 6) + 1;
  const blogIdx = idx % blogs.length;

  const blog = blogs[blogIdx];

  return (
    <>
      <div className="lb-item">
        <div
          className="lb-thumb set-bg"
          style={{
            backgroundImage: `url("/site/img/latest-blog/${imgIdx}.jpg")`,
          }}
        ></div>
        <div className="lb-content">
          <a href={blog?.url} className="lb-date">
            {blog?.title}
          </a>
          <p>{blog?.description}</p>
          <div className="lb-author">By Admin</div>
        </div>
      </div>
    </>
  );
}

function HeroImage({ img = '', title, subTitle }) {
  return (
    <>
      <section
        className="page-info-section set-bg"
        style={{ backgroundImage: `url("${img}")` }}
      >
        <div className="pi-content">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6 text-white">
                <h2>{title}</h2>
                <p>{subTitle}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function FeaturedReadMoreItem({ idx = 2 }) {
  const blogIdx = idx % blogs.length;
  const blog = blogs[blogIdx];
  return (
    <>
      <div className="widget-item">
        <div
          className="feature-item set-bg"
          style={{
            backgroundImage: `url("/site/img/features/featured-1.jpg")`,
          }}
        >
          <span className="cata new">featured</span>
          <div className="fi-content text-white">
            <h5>
              <a href={blog?.url}>{blog?.title}</a>
            </h5>
            <p>{blog?.description}</p>
            <div className="fi-comment">by Admin</div>
          </div>
        </div>
      </div>
    </>
  );
}
