import { assign, log, createMachine } from 'xstate';

export const tutorialMachine = createMachine({
  id: 'tutorial',
  context: {
    error: '',
    restartAmount: 5,
    amount: 25,
    color: 'ruby-pink',
  },
  initial: 'waitForStart',
  states: {
    waitForStart: {
      entry: [log((context, _) => ['waitToStart', context])],
      tags: ['hideProgress', 'waitClick', 'fixedGrid'],
      on: {
        ACCEPT: [
          {
            target: 'waitFor1stWin',
          },
        ],
      },
    },

    waitFor1stWin: {
      entry: [log((context, _) => ['waitFor1stWin', context])],
      tags: ['waitAcceptChallenge', 'waitBoxes', 'hideProgress', 'fixedGrid'],
      on: {
        WIN: [
          {
            actions: assign({
              restartAmount: 25,
              amount: 100,
              color: 'crystal',
            }),
            target: 'claimFirstBonus',
          },
        ],
      },
    },
    claimFirstBonus: {
      entry: [log((context, _) => ['claimFirstBonus', context])],
      tags: ['hideProgress', 'fixedGrid'],
      on: {
        CLAIMED: [
          {
            target: 'waitAcceptChallenge',
          },
        ],
      },
    },
    waitAcceptChallenge: {
      entry: [log((context, _) => ['waitAcceptChallenge', context])],
      tags: ['tutorialChallenge', 'waitClick', 'slowChangesGrid'],
      on: {
        ACCEPT: [
          {
            target: 'waitFor1stChallenge',
          },
        ],
      },
    },
    waitFor1stChallenge: {
      entry: [log((context, _) => ['waitFor1stChallenge', context])],
      tags: ['tutorialChallenge', 'waitBoxes', 'slowChangesGrid'],
      on: {
        WIN: [
          {
            actions: assign({
              restartAmount: 100,
              amount: 500, //welcome
            }),
            target: 'claim2ndBonus',
          },
        ],
      },
    },

    claim2ndBonus: {
      entry: [log((context, _) => ['claim2ndBonus', context])],
      tags: [],
      on: {
        CLAIMED: [
          {
            target: 'waitIncreaseBet',
          },
        ],
      },
    },

    waitIncreaseBet: {
      entry: [log((context, _) => ['waitIncreaseBet', context])],
      tags: ['showBetControl'],
      on: {
        INCREASED: [
          {
            target: 'done',
          },
        ],
      },
    },

    done: {
      entry: [log((context, _) => ['done', context])],
      tags: ['waitBoxes', 'showBetControl'],
      type: 'final',
    },
  },
});
