import { t } from 'i18next';
import { SelectionList, TodoList } from '../components/terminal';
import { useBonusesContext } from '../shared/hooks/use-bonus';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalLayout } from '../shared/layout';
import {
  boxoToUsd,
  isDev,
  isNativeApp,
  isTelegram,
  millisecondsLeftToStr,
  priceFmt,
} from '../shared/utils';
import { useUserContext } from '../shared/hooks/use-user';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import { Loader } from './loader';
import { useEffect, useState } from 'react';
import { BonusCard } from './bonus';

export function Shop({ shortView = false }) {
  const { products, purchaseProduct, setOnPurchaseError, haptics } =
    useNativeAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { balance, user } = useUserContext();
  const premiumExpiresAt = user?.premiumExpiresAt;

  const { getActualBonuses, visibleBonuses: bonuses } = useBonusesContext();
  useEffect(() => {
    getActualBonuses();
  }, []);

  function Done() {
    navigate('/app', { replace: true });
  }

  const [isLoading, setIsLoading] = useState(false);

  const onPurchaseError = (error) => {
    setIsLoading(false);
    if (error?.code === 'E_USER_CANCELLED') return;

    if (!!error?.messge)
      alert(
        t('Something went wrong') +
          ': ' +
          JSON.stringify(error.messge) +
          '. ' +
          t('Please try again')
      );
  };
  setOnPurchaseError(onPurchaseError);

  function bonusOnClick(bonus) {
    if (!bonus) return;
    //onSetBonus(bonus);
    if (
      bonus.typeId === '2ndWelcomeBonusFreeBoxes' ||
      bonus.typeId === 'WelcomeBonusFreeBoxes'
    ) {
      navigate('/app/bonus/welcome', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'PushNotificationsBonus') {
      navigate('/app/bonus/push', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'ChallengeBonus') {
      navigate('/app/bonus/challenge', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'RecoveryBonus') {
      navigate('/app/bonus/recovery', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'AdBonus') {
      navigate('/app/bonus/health', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'StreakBonus') {
      navigate('/app/bonus/streak', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'SponsoredBonus') {
      navigate('/app/bonus/sponsored', {
        replace: true,
        state: location.pathname,
      });
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <ModalLayout
        // actionName={t('Settings')}
        // onAction={() => {
        //   navigate('/app/settings', {
        //     replace: true,
        //     state: location.pathname,
        //   });
        // }}
        onClose={() => {
          if (location.state) navigate(location.state, { replace: true });
          else navigate('/app', { replace: true });
        }}
      >
        {!shortView && (
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Balance')}</h1>
            <div className="subtitle text-center text-muted">
              {/* <small className="text-center text-muted">BOXO</small> */}
              <small className="text-center text-muted">
                ${priceFmt(boxoToUsd(balance), 5, 0)}
              </small>
            </div>

            <h1
              className="text-center text-default -primary-accent"
              style={{
                fontSize: '7vh',
                fontWeight: 300,
                lineHeight: '7vh',
                //marginTop: '0vh',
              }}
            >
              {priceFmt(balance, balance < 1000 ? 1 : 0)}
            </h1>

            {/*           <div className="subtitle text-center text-muted">
           ≈${priceFmt((balance * 1) / 1000, balance < 0.01 ? 4 : 2)}             
          </div>
          */}
          </div>
        )}

        {shortView && (
          <div className="modal-header">
            <h1 className="text-center text-default">
              {t('Need more coins')}?
            </h1>
            <div className="subtitle text-center text-muted">
              <small className="text-center text-muted">
                {t('Buy BOXO coins')}
              </small>
            </div>
          </div>
        )}

        <div className="modal-body">
          {isTelegram() && (
            <SelectionList
              header={t('Boxo shop')}
              items={[
                {
                  name: t('Buy'),
                  descr: t('Deposit crypto'),
                  imgUrl: '/img/deposit.svg',
                  type: 'menu',
                },
              ]}
              onClick={() => {
                navigate('/app/deposit', {
                  replace: true,
                  state: location.pathname,
                });
              }}
            />
          )}

          <br />
          {(isNativeApp() || isDev()) &&
            products?.filter((p) => p.type === 'shop').length > 0 && (
              <SelectionList
                header={t('Boxo shop')}
                items={products
                  .filter((p) => p.type === 'shop')
                  .map((p) => {
                    return {
                      id: p.id,
                      name: p.name,
                      descr: `${priceFmt(p.amount, 0)} BOXO`,
                      imgUrl: `/img/${p.id?.toLowerCase()}.svg`,
                      type: 'button',
                      tagMuted: `${p.price}`,
                    };
                  })}
                onClick={(id) => {
                  setIsLoading(true);
                  purchaseProduct(id);
                  haptics('selection');
                }}
              />
            )}

          <br />

          {!shortView && bonuses?.length > 0 && (
            <SelectionList
              header={t('Bonuses')}
              items={bonuses.map((bonus) => {
                const { name, descr, imgUrl, disabled } = bonus;
                return {
                  name,
                  descr,
                  imgUrl,
                  disabled,
                  tag: bonus?.isNew ? t('New') : null,
                  type: 'menu',
                };
              })}
              onClick={(idx) => bonusOnClick(bonuses[idx])}
            />
          )}

          <br />
          {!shortView &&
            (isNativeApp() || isDev()) &&
            (products?.filter((p) => p.type === 'premium').length > 0 ||
              premiumExpiresAt >= Date.now()) && (
              <SelectionList
                header={t('Premium')}
                items={[
                  {
                    id: 0,
                    name: `${t('No ads')}!`,
                    descr:
                      premiumExpiresAt >= Date.now()
                        ? millisecondsLeftToStr(
                            Math.max(0, premiumExpiresAt - Date.now()),
                            false
                          )
                        : `${t('Enjoy boosted bonuses')}`,
                    imgUrl: `/img/black-rocket.svg`,
                    type: 'menu',
                    tagBlack:
                      premiumExpiresAt >= Date.now() ? `${t('Active')}` : null,
                  },
                ]}
                onClick={() => {
                  navigate('/app/premium', {
                    replace: true,
                    state: location.pathname,
                  });
                }}
              />
            )}
        </div>
        <div className="modal-footer no-mobile-keyboard justify-content-center">
          <div
            className="btn btn-secondary desktop-only"
            role="button"
            onClick={() => {
              Done();
            }}
          >
            {t('Close')}
          </div>
        </div>
      </ModalLayout>
    </>
  );
}

export function Premium({}) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    products: nativeProducts,
    purchaseProduct,
    setOnPurchaseError,
    haptics,
  } = useNativeAppContext();
  const products = nativeProducts?.filter((p) => p.type === 'premium');

  const { user } = useUserContext();
  const premiumExpiresAt = user?.premiumExpiresAt;

  const onPurchaseError = (error) => {
    setIsLoading(false);
    if (error?.code === 'E_USER_CANCELLED') return;

    if (!!error?.messge)
      alert(
        t('Something went wrong') +
          ': ' +
          JSON.stringify(error.messge) +
          '. ' +
          t('Please try again')
      );
  };
  setOnPurchaseError(onPurchaseError);

  return (
    <>
      {isLoading && <Loader />}
      <ModalLayout>
        <div className="modal-header">
          <h1 className="text-center text-default">
            {premiumExpiresAt >= Date.now()
              ? t('Premium is active')
              : t('Premium')}
          </h1>
          <div className="subtitle text-center text-muted mb-2 mt-1">
            {premiumExpiresAt >= Date.now() && (
              <>
                <small
                  className="text-center text-muted"
                  style={{ marginTop: '30vh' }}
                >
                  <img
                    src="/img/time.svg"
                    alt=""
                    style={{
                      width: '11px',
                      marginTop: '-2px',
                      marginLeft: '0px',
                      marginRight: '6px',
                      opacity: 0.4,
                    }}
                  />
                  <small>
                    {millisecondsLeftToStr(
                      Math.max(0, premiumExpiresAt - Date.now()),
                      true
                    )}
                  </small>
                </small>
                <br />

                <small className="text-center text-muted">
                  {t('No ads')}. {t('Instant bonus boosts')}!
                </small>
              </>
            )}
          </div>
        </div>
        <div className="modal-body no-scroll">
          <BonusCard
            name={t('Bonus boost')}
            img="/img/black-rocket.svg"
            color="obsidian"
            subTitle={null}
            amount={null}
            todoList={null}
          />

          {false && (
            <div className="mt-4">
              <TodoList
                header={t('Advantages')}
                items={[
                  {
                    name: t('No ads'),
                    isDone: true,
                  },
                  {
                    name: t('Boosted bonuses'),
                    isDone: true,
                  },
                ]}
              />
            </div>
          )}

          <br />

          <SelectionList
            header={t('Enjoy boosted bonuses')}
            items={products.map((p) => {
              return {
                id: p.id,
                name: p.name,
                //descr: t('No ads. Boosted bonuses.'),
                imgUrl: `/img/noads.svg`,
                type: 'button',
                tagMuted: `${p.price}`,
              };
            })}
            onClick={(id) => {
              setIsLoading(true);
              purchaseProduct(id);
              haptics('selection');
            }}
          />
        </div>

        {false && (
          <div className="modal-footer no-mobile-keyboard justify-content-center">
            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                //Done();
              }}
            >
              {t('Close')}
            </div>
          </div>
        )}
      </ModalLayout>
    </>
  );
}
