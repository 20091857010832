import { t } from 'i18next';
import { useState } from 'react';
import { ModalLayout } from '../../shared/layout';
import { useLocalStorage } from '../../shared/hooks/use-hooks';
import { logout } from '../../shared/api/auth';
import { SelectionList } from '../../components/terminal';
import { useUserContext } from '../../shared/hooks/use-user';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SystemDialog } from '../../shared/dialogs';
import { Avatar } from '../../components/icons';
import { useTelegramContext } from '../../shared/hooks/use-telegram';
import { useNativeAppContext } from '../../shared/hooks/use-native-app';
import { isDev, isNativeApp, millisecondsLeftToStr } from '../../shared/utils';

export default function Account({ logger, onBack, onClose, onLogout }) {
  const [nickname] = useLocalStorage('nickname', '');
  const [showDialog, setShowDialog] = useState(false);
  const { user } = useUserContext();
  const email = user?.email;
  //const userName = nickname ? nickname : email?.split('@').shift();

  const navigate = useNavigate();
  const location = useLocation();

  const { user: tgUser } = useTelegramContext();

  const { products } = useNativeAppContext();
  const premiumExpiresAt = user?.premiumExpiresAt;

  return (
    <>
      <ModalLayout onClose={onClose}>
        <div className="modal-header">
          <h1 className="text-center text-default">{t('Account')}</h1>
        </div>
        <div className="modal-body">
          <div
            style={{
              left: '50%',
              position: 'relative',
              transform: 'translateX(-50%)',
              width: '130px',
              height: '130px',
              borderRadius: '50%',
              backgroundColor: 'var(--theme-overlay-transparent)',
              padding: '20px',
            }}
          >
            <Avatar
              username={email}
              style={{
                height: '90px',
                width: '90px',
              }}
            />
          </div>

          {/* <br />
          <SelectionList
            items={[
              {
                name: t('Name'),
                descr: nickname,
                imgUrl: '/img/user.svg',
                type: 'menu',
              },
              {
                name: t('Settings'),
                imgUrl: '/img/settings.svg',
                type: 'menu',
              },
            ]}
            onClick={(idx) => {
              if (idx === 0)
                navigate('./profile', {
                  replace: true,
                  state: location.pathname,
                });
              if (idx === 1)
                navigate('/app/settings', {
                  replace: true,
                  state: location.pathname,
                });
            }}
          /> */}

          <br />
          {(isNativeApp() || isDev()) &&
            (products?.filter((p) => p.type === 'premium').length > 0 ||
              premiumExpiresAt >= Date.now()) && (
              <SelectionList
                header={t('Premium')}
                items={[
                  {
                    id: 0,
                    name: `${t('No ads')}!`,
                    descr:
                      premiumExpiresAt >= Date.now()
                        ? millisecondsLeftToStr(
                            Math.max(0, premiumExpiresAt - Date.now()),
                            false
                          )
                        : `${t('Enjoy boosted bonuses')}`,
                    imgUrl: `/img/black-rocket.svg`,
                    type: 'menu',
                    tagBlack:
                      premiumExpiresAt >= Date.now() ? `${t('Active')}` : null,
                  },
                ]}
                onClick={() => {
                  navigate('/app/premium', {
                    replace: true,
                    state: location.pathname,
                  });
                }}
              />
            )}

          <br />
          <SelectionList
            header={tgUser?.username ? `@${tgUser?.username}` : email}
            items={[
              {
                name: t('Change name'),
                tagValue: nickname,
                imgUrl: '/img/user.svg',
                type: 'menu',
              },
              {
                name: t('Settings'),
                imgUrl: '/img/settings.svg',
                type: 'menu',
              },

              {
                name: t('Logout'),
                imgUrl: '/img/logout.svg',
                type: 'button',
              },
            ]}
            onClick={(idx) => {
              if (idx === 0)
                navigate('./profile', {
                  replace: true,
                  state: location.pathname,
                });
              if (idx === 1)
                navigate('/app/settings', {
                  replace: true,
                  state: location.pathname,
                });
              if (idx === 2) setShowDialog(true);
            }}
          />
        </div>

        <div className="modal-footer no-mobile-keyboard">
          <div className="mt-2 text-center">
            <img
              src="/img/shield.svg"
              alt=""
              style={{
                marginTop: '-2px',
                marginRight: '4px',
                height: '16px',
              }}
            />
            <small className="text-primary btn-link">
              <Link state={location.pathname} to="./privacy" replace>
                Privacy policy
              </Link>
            </small>
            {'  '}
            <small className="text-muted">
              {`Boxo © ${new Date().getFullYear()} Inc. All Rights Reserved`}
            </small>
          </div>
        </div>
      </ModalLayout>

      <SystemDialog
        title={`${t('Logout')}?`}
        show={showDialog}
        message={t('Are you sure you want to logout?')}
        actions={[t(`Cancel`), t('Logout')]}
        onAction={async (idx) => {
          setShowDialog(false);
          if (idx === 1) {
            logger?.event('click', { target: 'logout' });
            await logout();
            onLogout && onLogout('click');
          }
        }}
      />
    </>
  );
}
