import { t } from 'i18next';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './header';
import { useSessionStorage } from './hooks/use-hooks';
import { useTelegramContext } from './hooks/use-telegram';
import { isTelegram } from './utils';

function Layout({ title, children }) {
  return (
    <div className="app">
      <Header title={title} />
      {children}
    </div>
  );
}

function ModalLayout({
  children,
  onBack = 'default',
  onClose,
  onAction,
  actionName,
  hideNavigation = false,
  hideBackground = false,
  isTransparent = false,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [backUrl, setBackUrl] = useSessionStorage(location.pathname, null);

  useEffect(() => {
    if (location.state || (!backUrl && !location.state))
      setBackUrl(location.state);
  }, []);

  const onCloseNavigate = () => {
    setBackUrl(null);
    onClose && onClose();
  };

  const showBack =
    (onBack !== 'default' && !!onBack) || !!backUrl || (actionName && onClose);

  const onBackNavigate = useCallback(() => {
    if (onBack !== 'default' && !!onBack) {
      onBack();
    } else if (backUrl) {
      const oldBackUrl = backUrl;
      setBackUrl(null);
      navigate(oldBackUrl, { replace: true });
    } else if (actionName && onClose) onCloseNavigate();
  }, [backUrl, onBack, actionName, onClose]);

  const { setOnBackButton } = useTelegramContext();
  useEffect(() => {
    if (!isTelegram()) return;
    if (hideNavigation) {
      setOnBackButton(null);
    } else if (showBack) {
      setOnBackButton(onBackNavigate);
    } else if (onClose) {
      setOnBackButton(onCloseNavigate);
    } else {
      setOnBackButton(null);
    }
  }, [showBack]);

  return (
    <>
      <div
        className="modal"
        style={{ backgroundColor: isTransparent ? 'transparent' : null }}
      >
        <div
          className=""
          style={{
            overflow: 'hidden',
            position: 'absolute',
            zIndex: 0,
            width: '100%',
            height: '100%',
            top: 0,
            background:
              hideBackground || isTransparent
                ? ''
                : `radial-gradient(circle at 0% 70%, #b1f5ff10, transparent 70%),
          radial-gradient(circle at 100% 70%, #b1f5ff10, transparent 70%) 
          `,
          }}
        />

        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className={`modal-mobile-navigation row ${
                hideNavigation || isTelegram() ? 'd-none' : ''
              }`}
            >
              <div className="col-6">
                {showBack && (
                  <div
                    className={`text-start text-primary`}
                    role="button"
                    onClick={() => onBackNavigate()}
                  >
                    <span className="back-symbol" /> {t('Back')}
                  </div>
                )}
              </div>

              <div className="col-6">
                <div
                  className={`${onAction || onClose ? '' : ' invisible'}`}
                  role="button"
                  onClick={() => {
                    if (onAction) onAction();
                    else onCloseNavigate();
                  }}
                >
                  <div className="text-end text-primary">
                    {actionName || t('Close')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`modal-content`}>{children}</div>
        </div>
      </div>
    </>
  );
}

export { Layout, ModalLayout };
