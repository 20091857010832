import { createContext, useContext, useMemo, useEffect } from 'react';
import { useSessionStorage } from './use-hooks';
import { useLoggerContext } from './use-logger';
import { useInterval } from './use-interval';
import {
  compareVersions,
  isAndroid,
  isIos,
  isNativeApp,
  tryToParseJSON,
} from '../utils';
import { useState } from 'react';
import { persistState } from '../utils/persist';
import { useNativeAppContext } from './use-native-app';
import { api_middleware } from '../api/api-client';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

const Updater = createContext();
export function UpdaterContext({ children }) {
  const updater = useUpdater();
  const persistedUpdater = useMemo(() => updater, [updater]);
  return (
    <Updater.Provider value={persistedUpdater}>{children}</Updater.Provider>
  );
}
export const useUpdaterContext = () => useContext(Updater);

function useUpdater() {
  const nativeApp = useNativeAppContext();

  const logger = useLoggerContext();
  const [lastUpdateVersion, setLastUpdateVersion] = useSessionStorage(
    'lastUpdateVersion',
    null
  );
  const [updateIsRequired, setUpdateIsRequired] = useState(false);

  async function fetchSettings() {
    try {
      const response = await api_middleware().get(
        `/api/settings?t=${Date.now()}`
      );
      const newSettings = tryToParseJSON(response?.data?.settings);
      persistState('settings', newSettings);
      return newSettings;
    } catch (e) {
      logger?.event('error', { msg: 'Failed to fetch settings', e });
      return { version: '0.0.0' };
    }
  }

  function isNativeAppReadyForUpdate(settings) {
    if (!isNativeApp()) return false;
    const version = nativeApp?.version;
    const minAppVersion = isIos()
      ? settings?.minAppVersion?.ios
      : isAndroid()
      ? settings?.minAppVersion?.android
      : null;
    if (!minAppVersion || !version) return false;
    if (compareVersions(minAppVersion, version) <= 0) return false;
    return true;
  }

  function isWebClientReadyForUpdate(minVersion) {
    if (process.env.NODE_ENV !== 'production') return false;
    const version = process.env.REACT_APP_VERSION;
    if (!minVersion) return false;
    if (compareVersions(minVersion, version) <= 0) return false;
    if (lastUpdateVersion === minVersion) {
      return false;
    }
    return true;
  }

  const navigate = useNavigate();

  function updateApp() {
    fetchSettings().then((settings) => {
      const appVersion = nativeApp?.version;
      const version = process.env.REACT_APP_VERSION;
      const minVersion = settings?.minVersion;
      const minAppVersion = isIos()
        ? settings?.minAppVersion?.ios
        : isAndroid()
        ? settings?.minAppVersion?.android
        : null;

      //Native app
      if (isNativeAppReadyForUpdate(settings)) {
        setUpdateIsRequired(true);
        //Do not update app at landing page
        if (
          window.location.pathname !== '/' &&
          window.location.pathname !== '/home'
        ) {
          logger.event('updating', {
            type: 'app',
            version,
            minVersion,
            appVersion,
            minAppVersion,
          });

          isAndroid() &&
            window.open('market://details?id=com.BoxoTradeApp', '_blank');
          isIos() &&
            (window.location =
              'https://apps.apple.com/us/app/trading-simulator-boxo-game/id6725853622');
          // openWebBrowser is supported since 1.8.0
          // const version = nativeApp?.version;
          // if (compareVersions(version, '1.8.0') < 0) {
          // } else {
          //   //Normal update
          //   isIos() &&
          //     nativeApp.openWebBrowser(
          //       'https://apps.apple.com/us/app/crypto-game-boxo/id6725853622'
          //     );
          //   isAndroid() &&
          //     nativeApp.openWebBrowser(
          //       //'https://play.google.com/store/apps/details?id=com.BoxoTradeApp'
          //       'market://details?id=com.BoxoTradeApp'
          //     );
          //   return;
          // }
          navigate('/home', { replace: true });
        }
      }

      //Web client
      if (isWebClientReadyForUpdate(minVersion)) {
        setLastUpdateVersion(minVersion);
        logger.event('updating', {
          type: 'web',
          version,
          minVersion,
          appVersion,
          minAppVersion,
        });
        /* 
            .then((names) => {
              const promises = names.map((name) => caches?.delete(name));
              Promise.all(promises)
                .then(() => {
                  window.location.href = '/';
                  if (isNativeApp()) nativeApp?.updateVersion();
                })
                .catch(() => {
                  window.location.href = '/';
                  if (isNativeApp()) nativeApp?.updateVersion();
                });
            })
        */
        if (process.env.NODE_ENV === 'production')
          //window.location.href = '/';
          caches
            ?.keys()
            .then((names) => {
              names.map((name) => caches?.delete(name));
            })
            .then(() => {
              window.location.href = '/home';
              if (isNativeApp()) nativeApp?.updateVersion();
            })
            .catch(() => {
              logger?.event('error', {
                msg: `Failed to clear cache`,
              });
              window.location.href = '/home';
            });
      }
    });
  }

  function checkUpdate() {
    fetchSettings().then((settings) => {
      const minVersion = settings?.minVersion;
      const newUpdateIsRequired =
        isWebClientReadyForUpdate(minVersion) ||
        isNativeAppReadyForUpdate(settings);

      setUpdateIsRequired(newUpdateIsRequired);
    });
  }

  //Check updates 1 minute
  useInterval(() => checkUpdate(), 60000);

  useEffect(() => {
    if (
      nativeApp?.version &&
      (window.location.pathname === '/' || window.location.pathname === '/home')
    )
      updateApp();
  }, [nativeApp?.version]);

  return { updateApp: updateApp, updateIsRequired };
}
