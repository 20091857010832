import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  isNativeApp,
  isTelegram,
  millisecondsLeftToStr,
  priceFmt,
  priceFmtThousands,
} from '../shared/utils';
import { useInterval } from '../shared/hooks/use-interval';
import { useRef } from 'react';
import { useLocalStorage, useWindowSize } from '../shared/hooks/use-hooks';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import { Rating } from 'react-simple-star-rating';
import Webcam from 'webcam-easy';
import ReactConfetti from 'react-confetti';
import {
  EventType,
  RiveEventType,
  useRive,
  useStateMachineInput,
} from '@rive-app/react-canvas';
import { boxStatus } from '../shared/machines/terminal';
import { useSoundContext } from '../shared/hooks/use-sound';

function DebugControl({ text1, text2, text3, onPause = () => {} }) {
  const style = {
    right: '4vw',
    width: '120px',
    tranform: 'translateX(-50%)',
    position: 'absolute',
    height: '40px',
    zIndex: 100,
    lineHeight: '42px',
    textAlign: 'center',
    top: '150px',
  };

  return (
    <>
      <div style={style}>
        <button onClick={() => onPause()} height="10px">
          Pause price
        </button>
        <div>{text1}</div>
        <div>{text2}</div>
        <div>{text3}</div>
      </div>
    </>
  );
}

function BackgoundLightWebCam({ top, height }) {
  const camera = useRef(null);
  const canvas = useRef(null);
  const audio = useRef(null);
  const [webcam, setWebcam] = useState();

  useEffect(() => {
    const newWebcam = new Webcam(
      camera?.current,
      'user',
      canvas?.current,
      audio?.current
    );
    setWebcam(newWebcam);
    newWebcam?.start().then(() => {
      newWebcam?.stream();
    });
  }, []);

  return (
    <>
      <div
        className=""
        style={{
          transform: 'translateX(-25%)',
          overflow: 'hidden',
          position: 'absolute',
          zIndex: 0,
          width: '100%',
          height: `${height - 15}px`,
          top: top,
          //transform: 'scale(-1)',
        }}
      >
        <video
          //style={{ backgroundColor: 'red' }}
          ref={camera}
          id="webcam"
          autoplay
          playsinline
        />
        <canvas ref={canvas} width="100%" height="100%" class="d-none" />
        <audio ref={audio} src="" preload="auto" />
      </div>
    </>
  );
}

function BackgoundLight({ top, height }) {
  return (
    <div
      className=""
      style={{
        overflow: 'hidden',
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        height: height,
        top: top,
        background: `radial-gradient(circle at 0% 0, #b1f5ff10, transparent 30%),
          radial-gradient(circle at 100% 0%, #b1f5ff10, transparent 30%) 
          `,
      }}
    />
  );
}

function BlurPanel({ top, height = '50vh' }) {
  return (
    <div
      className="blur-filter"
      style={{
        position: 'absolute',
        zIndex: 9,
        width: '100%',
        height: height,
        minHeight: height,
        top: top,
      }}
    />
  );
}

function Hand({ y, x, width, height, type, animateSelection }) {
  const handImgWidth = 70;
  return (
    <div
      style={{
        position: 'absolute',
        top: y,
        left: x,
        width: type === 'point' ? 0 : width,
        height: height,
      }}
    >
      {animateSelection && (
        <div
          style={{
            width: `${
              animateSelection.xBoxes * animateSelection.boxSizePx + 2
            }px`,
            height: `${
              (animateSelection.yBoxes ?? 1) * animateSelection.boxSizePx + 3
            }px`,
            position: 'absolute',
            top: `-${animateSelection.boxSizePx / 2 + 5}px`,
            left: `-${animateSelection.boxSizePx / 2}px`,
            zIndex: 15,
          }}
        >
          <div
            className={
              type === 'horizontal'
                ? 'animated-hand-selection-horizontal'
                : 'animated-hand-selection-area'
            }
            style={{}}
          />
        </div>
      )}
      <div
        className={
          type === 'horizontal'
            ? 'animated-hand-horizontal'
            : type === 'area'
            ? 'animated-hand-area'
            : 'animated-hand-point'
        }
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 15,
        }}
      >
        <img src="/img/hand.svg" width={`${handImgWidth}px`} />
      </div>
    </div>
  );
}

function AnimatedHand({
  y = '90%',
  x = '25%',
  width = '50%',
  height = '10px',
  type = 'horizontal',
  overlay = false,
  zIndex = 100,
  animateSelection,
}) {
  if (!overlay)
    return (
      <Hand
        y={y}
        x={x}
        width={width}
        height={height}
        type={type}
        animateSelection={animateSelection}
      />
    );

  if (overlay)
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#00000000',
          transform: 'translate3d(0, 0, 0)',
          zIndex,
          backdropFilter: 'blur(2px)',
        }}
      >
        <Hand
          y={y}
          x={x}
          width={width}
          height={height}
          type={type}
          animateSelection={animateSelection}
        />
      </div>
    );
}

function Confetti({}) {
  const { width, height } = useWindowSize();
  const [recycle, setRecycle] = useState(true);
  useState(() => setTimeout(() => setRecycle(false), 4000), []);

  const drawShape = (context) => {
    const cx = 20;
    const cy = 24;
    const rx = 15;
    const ry = 13;
    context.save();
    context.beginPath();
    context.translate(cx - rx, cy - ry);
    context.scale(rx, ry);
    context.arc(1, 1, 1, 0, 2 * Math.PI, false);
    context.restore();
    context.fill(); //context.stroke();
  };

  return (
    <ReactConfetti
      width={width}
      height={height}
      recycle={recycle}
      drawShape={drawShape}
      colors={['#f8c621']}
      friction={1.001}
      confettiSource={{ x: 0, y: -50, w: width, h: 0 }}
    />
  );
}

function Speedometr({ fireSpeedUp, zIndex, top, showing = true }) {
  const { rive, RiveComponent } = useRive({
    src: '/riv/boxo.riv',
    stateMachines: 'State Machine',
    autoplay: true,
    artboard: 'Speedometr',
    // layout: new Layout({
    //   fit: Fit.Cover,
    //   alignment: Alignment.Center,
    // }),
    // onStateChange: (event) => {
    //   const skinName = event.data.filter((data) => data.indexOf('Skin_') > -1);
    //   const skinTextDisplay = skinMapping[skinName];
    //   if (skinTextDisplay) {
    //     setSkinText(skinTextDisplay);
    //   }
    // },
  });
  const input = useStateMachineInput(rive, 'State Machine', 'SpeedUp');
  useEffect(() => {
    try {
      input?.fire();
    } catch (e) {}
  }, [fireSpeedUp]);

  const labelStyle = {
    left: '4vw',
    width: '120px',
    tranform: 'translateX(-50%)',
    position: 'absolute',
    height: '40px',
    zIndex: zIndex ? zIndex : 1,
    lineHeight: '42px',
    textAlign: 'center',
  };

  const topLabelStyle = {
    top: `calc(${top} - 34px)`,
  };
  return (
    <div className={showing ? 'visible' : 'hidden'}>
      <small
        className="text-muted text-uppercase"
        style={{ ...labelStyle, ...topLabelStyle }}
      >
        {t('Intensity')}
      </small>

      <div
        className="blur-filter--"
        style={{
          position: 'absolute',
          borderRadius: '40px',
          left: '4vw',
          top: top,
          width: 120,
          height: 40,
          zIndex: zIndex ? zIndex : 1,
        }}
      >
        <RiveComponent />
      </div>
    </div>
  );
}

function GiftJump({
  zIndex,
  top,
  starting = false,
  showing = true,
  size,
  onClick,
  giftType = 'gift',
}) {
  const { rive, RiveComponent } = useRive({
    src: '/riv/boxo.riv',
    stateMachines: 'State Machine 1',
    autoplay: true,
    artboard: 'Gift',
  });
  const start = useStateMachineInput(rive, 'State Machine 1', 'Start');
  //const type = useStateMachineInput(rive, 'State Machine 1', 'Gift/Type');

  const { notification } = useSoundContext();
  const nativeApp = useNativeAppContext();

  useEffect(() => {
    if (rive) {
      rive.on(EventType.RiveEvent, onRiveEventReceived);
      const giftTypeId = giftType === 'gift' ? 0 : giftType === 'pill' ? 1 : 0;
      rive.setNumberStateAtPath('Type', giftTypeId, 'Gift Layout');
    }
  }, [rive, giftType]);

  const onRiveEventReceived = (riveEvent) => {
    const eventData = riveEvent.data;

    if (eventData.type === RiveEventType.General) {
      if (eventData.name === 'onClick') onClick && onClick();
      if (eventData.name === 'onJump') {
        notification();
        nativeApp.haptics('success');
      }

      //const eventProperties = eventData.properties;
      //console.log('Message', eventProperties.message);
    }
  };

  useEffect(() => {
    if (starting && !!start) {
      try {
        start?.fire();
      } catch (e) {}
    }
  }, [starting, start]);

  return (
    <div className={showing ? 'visible' : 'hidden'}>
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: top - size / 3,
          width: size,
          height: size,
          zIndex: zIndex ? zIndex : 1,
          //backgroundColor: 'red',
        }}
      >
        <RiveComponent />
      </div>
    </div>
  );
}

//value: number | boolean
function setRiveInput(rive, name, value = NaN, path = '') {
  try {
    if (path === '') {
      const input = rive
        ?.stateMachineInputs('State Machine 1')
        .find((i) => i.name === name);
      if (input) {
        if (typeof value === 'number' && isNaN(value)) input?.fire();
        else input.value = value;
      }
    } else {
      if (typeof value === 'number')
        if (isNaN(value)) rive?.fireStateAtPath(name, path);
        else rive?.setNumberStateAtPath(name, value, path);
      if (typeof value === 'boolean')
        rive?.setBooleanStateAtPath(name, value, path);
    }
  } catch (e) {}
}

function BetControl({
  label = '', //'SELECT BET SIZE', //Select a box to make a bet
  showing = true,
  onSetBet,
  onError,
  bet = 1,
  minBet = 1,
  maxBet = 1000000,
  bets = [1, 5, 10, 50, 100, 500, 1000, 5000, 10000, 50000, 100000],
  top = 46.8,
  zIndex,
  fireSpeedUp,
  ...props
}) {
  const [idx, setIdx] = useState(0);
  const { rive, RiveComponent } = useRive({
    src: '/riv/boxo.riv',
    stateMachines: 'State Machine 1',
    autoplay: true,
    artboard: 'Bet',
  });

  useEffect(() => {
    const idx = bets.findIndex((b) => b >= bet);
    const betTxt = priceFmtThousands(bets[idx], 0);
    rive?.setTextRunValueAtPath(
      'Bet',
      betTxt,
      'Bet Control/Bet Control Layout'
    );

    setIdx(idx >= 0 ? idx : 0);
  }, [bet]);

  const style = {
    right: '4vw',
    top: `${top}`,
    tranform: 'translateX(-50%)',
    zIndex,
    display: !rive ? 'none' : 'block',
  };

  const labelStyle = {
    right: '4vw',
    width: '120px',
    tranform: 'translateX(-50%)',
    position: 'absolute',
    height: '40px',
    zIndex: zIndex ? zIndex : 1,
    lineHeight: '42px',
    textAlign: 'center',
    display: !rive ? 'none' : 'block',
  };

  useEffect(() => {
    if (fireSpeedUp !== 'submitting') return;
    setRiveInput(rive, 'SpeedUp', NaN, 'Bet Control');
  }, [fireSpeedUp]);

  const topLabelStyle = {
    top: `calc(${top} - 34px)`,
  };

  const bottomLabelStyle = {
    top: `calc(${top} + 34px)`,
  };

  const maxBetRef = useRef(maxBet);
  const minBetRef = useRef(minBet);

  useEffect(() => {
    maxBetRef.current = maxBet;
    minBetRef.current = minBet;
  }, [maxBet, minBet]);

  /* const onRiveEventReceived = useCallback(
    (riveEvent) => {
      const eventData = riveEvent.data;
      if (eventData?.type === RiveEventType.General) {
        setIdx((prevIdx) => {
          let newIdx = prevIdx;

          if (eventData?.name === 'Plus') {
            newIdx = prevIdx >= bets.length - 1 ? prevIdx : prevIdx + 1;
            if (bets[newIdx] <= maxBetRef.current) {
              return newIdx;
            } else {
              setRiveInput(rive, 'Error', NaN, 'Bet Control');
              onError?.();
              return 0;
            }
          }
          if (eventData?.name === 'Minus') {
            newIdx = prevIdx <= 0 ? 0 : prevIdx - 1;
            if (prevIdx > 0 && bets[newIdx] >= minBet) {
              return newIdx;
            }
            return bets.findIndex((b) => b >= maxBetRef.current) - 1;
          }

          return prevIdx;
        });
      }
    },
    [bets, onError]
  );

  useEffect(() => {
    if (!rive) return;
    rive.on(EventType.RiveEvent, onRiveEventReceived);
    return () => {
      rive.off(EventType.RiveEvent, onRiveEventReceived);
    };
  }, [rive]);
 */

  return (
    <>
      <div className={showing ? 'visible' : 'hidden'}>
        <small
          className="text-muted text-uppercase"
          style={{ ...labelStyle, ...topLabelStyle }}
        >
          {t('Box price')}
        </small>

        <small
          className="text-muted text-uppercase"
          style={{ ...labelStyle, ...bottomLabelStyle }}
        >
          {label}
        </small>

        <div
          {...props}
          className={`bet-controller blur-filter-- ${props.className}`}
          style={{ ...style, height: '120px' }}
        >
          <RiveComponent />

          <div
            style={{
              //backgroundColor: 'red',
              position: 'absolute',
              left: 0,
              top: 0,
            }}
            onClick={() => {
              setRiveInput(
                rive,
                'Minus',
                NaN,
                'Bet Control/Bet Control Layout'
              );

              setIdx((prevIdx) => {
                let newIdx = prevIdx;
                newIdx = prevIdx <= 0 ? 0 : prevIdx - 1;
                const resultIdx =
                  prevIdx > 0 && bets[newIdx] >= minBet
                    ? newIdx
                    : bets.findIndex((b) => b >= maxBetRef.current) - 1;
                const betTxt = priceFmtThousands(bets[resultIdx], 0);
                onSetBet && onSetBet(bets[resultIdx]);
                rive?.setTextRunValueAtPath(
                  'Bet',
                  betTxt,
                  'Bet Control/Bet Control Layout'
                );
                return resultIdx;
              });
            }}
          />
          <div
            style={{
              //backgroundColor: 'red',
              position: 'absolute',
              right: 0,
              top: 0,
            }}
            onClick={() => {
              setRiveInput(rive, 'Plus', NaN, 'Bet Control/Bet Control Layout');
              setIdx((prevIdx) => {
                let newIdx = prevIdx;
                newIdx = prevIdx >= bets.length - 1 ? prevIdx : prevIdx + 1;
                const resultIdx =
                  bets[newIdx] <= maxBetRef.current ? newIdx : 0;
                if (resultIdx === 0) {
                  setRiveInput(rive, 'Error', NaN, 'Bet Control');
                  onError?.();
                }
                const betTxt = priceFmtThousands(bets[resultIdx], 0);
                onSetBet && onSetBet(bets[resultIdx]);
                rive?.setTextRunValueAtPath(
                  'Bet',
                  betTxt,
                  'Bet Control/Bet Control Layout'
                );

                return resultIdx;
              });
            }}
          />
        </div>
      </div>
    </>
  );
}

function BetControl_old({
  label = '', //'SELECT BET SIZE', //Select a box to make a bet
  showing = true,
  onSetBet,
  onError,
  bet = 1,
  minBet = 1,
  maxBet = 1000000,
  bets = [1, 5, 10, 50, 100, 500, 1000, 5000, 10000, 50000, 100000],
  top = 46.8,
  zIndex,
  ...props
}) {
  const [idx, setIdx] = useState(0);
  const maxIdx = bets.length - 1;

  useEffect(() => {
    const idx = bets.findIndex((b) => b >= bet);
    setIdx(idx >= 0 ? idx : 0);
  }, [bet]);

  const style = {
    right: '4vw',
    top: `${top}`,
    tranform: 'translateX(-50%)',
    zIndex,
  };

  const labelStyle = {
    right: '4vw',
    width: '120px',
    tranform: 'translateX(-50%)',
    position: 'absolute',
    height: '40px',
    zIndex: zIndex ? zIndex : 1,
    lineHeight: '42px',
    textAlign: 'center',
  };

  const topLabelStyle = {
    top: `calc(${top} - 34px)`,
  };

  const bottomLabelStyle = {
    top: `calc(${top} + 34px)`,
  };

  return (
    <>
      <div className={showing ? 'visible' : 'hidden'}>
        <small
          className="text-muted text-uppercase"
          style={{ ...labelStyle, ...topLabelStyle }}
        >
          {t('Box price')}
        </small>

        <small
          className="text-muted text-uppercase"
          style={{ ...labelStyle, ...bottomLabelStyle }}
        >
          {label}
        </small>

        <div
          {...props}
          className={`bet-controller blur-filter-- ${props.className}`}
          style={style}
        >
          <div
            role="button"
            className="btn-- btn-secondary-- btn-left"
            onClick={() => {
              const newIdx = idx <= 0 ? 0 : idx - 1;
              if (idx > 0 && bets[newIdx] >= minBet) {
                setIdx(newIdx);
                onSetBet && onSetBet(bets[newIdx]);
              }
            }}
          >
            –
          </div>
          <div className="text-primary glow">
            {priceFmtThousands(bets[idx], 0)}
          </div>
          <div
            role="button"
            disabled={idx >= maxIdx}
            className="btn-- btn-secondary-- btn-right"
            onClick={() => {
              const newIdx = idx >= maxIdx ? idx : idx + 1;
              if (bets[newIdx] <= maxBet) {
                setIdx(newIdx);
                onSetBet && onSetBet(bets[newIdx]);
              } else onError && onError();
            }}
          >
            +
          </div>
        </div>
      </div>
    </>
  );
}

function SoundControl({ top, ...props }) {
  useEffect(() => {}, []);
  const style = {
    left: '4vw',
    top: `${top}`,
    tranform: 'translateX(-50%)',
  };

  const [enableSonuds, setEnableSonuds] = useLocalStorage(
    'enable-sounds',
    true
  );

  return (
    <>
      <div>
        <div
          {...props}
          className={`sound-controller blur-filter ${props.className}`}
          style={style}
          onClick={() => {
            setEnableSonuds(!enableSonuds);
          }}
        >
          <div
            className="text-primary btn btn-secondary"
            role="button"
            style={{ height: '40px' }}
          >
            <img
              src={
                enableSonuds ? '/img/sounds.svg' : '/img/sounds-disabled.svg'
              }
              alt=""
              style={{
                marginTop: '4px',
                maxWidth: '22px',
                transform: 'translateY(-2px)',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function BonusItem({ amount, name, descr, expiration, onClick = () => {} }) {
  const [countDown, setCountDown] = useState('00:00:00');
  useInterval(() => {
    if (expiration) {
      const newCountDown = millisecondsLeftToStr(
        Math.max(0, expiration - Date.now())
      );
      setCountDown(newCountDown);
    }
  }, 500);
  return (
    <div
      role="button"
      style={{
        background:
          'linear-gradient(174deg, rgb(114, 179, 123) -10.34%, rgba(33, 43, 52, 0.61) 107.72%)',
        borderRadius: '8px',
        padding: '8px',
        margin: '10px 0',
      }}
      onClick={() => onClick({ amount, name, descr, expiration })}
    >
      <div className="" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div
          className="col-3"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <div
              style={{
                color: 'fff',
                lineHeight: '70px',
                fontSize: '2.3rem',
                textShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                letterSpacing: '-1px',
              }}
            >
              {amount}
            </div>
          </div>
        </div>
        <div
          className="col"
          style={{
            display: 'grid',
            alignContent: 'center',
            justifyItems: 'start',
          }}
        >
          <div
            style={{
              color: 'fff',
              fontWeight: 300,
              lineHeight: '20px',
              textShadow: '0px 1px 1px rgba(0, 0, 0, 0.4)',
              marginBottom: '4px',
            }}
          >
            {name}
          </div>
          {descr && (
            <div>
              <div
                className="text-default"
                style={{
                  lineHeight: '14px',
                  fontSize: '0.675em',
                  fontWeight: 200,
                  opacity: 0.8,
                }}
              >
                {descr}
              </div>
            </div>
          )}
        </div>

        <div
          className="col-auto text-monospace"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            padding: '0 14px',
          }}
        >
          <span className="text-primary-accent">
            <sup
              style={{
                fontWeight: 100,
                fontSize: '0.7rem',
                top: '-0.14rem',
                left: '-0.3rem',
                //textShadow: '0px 1px 1px rgba(0, 0, 0, 0.4)',
              }}
            >
              ●
            </sup>
          </span>

          <span
            style={{
              opacity: 0.6,
              fontSize: '0.675em',
              fontWeight: 400,
            }}
          >
            {countDown}
          </span>
        </div>
      </div>
    </div>
  );
}

function SelectionList({
  items,
  activeItemIdx = -1,
  height,
  header = '',
  onClick = () => {},
}) {
  /*  items = [{ id, name, descr, imgUrl, hideSubmenu, type: menu | switch | button, tag, tagMuted, tagBlack, tagValue, value, isActive, disabled }]   */
  const scrollerRef = useRef();
  const activeItemRef = useRef();
  const [scrolledToItemIdx, setScrolledToItemIdx] = useState(-1);

  useEffect(() => {
    const current = activeItemRef?.current?.parentElement;
    if (activeItemIdx >= 0 && current) {
      const parent = current.parentElement;
      //console.log('parent.pageYOffset=======', parent.pageYOffset);
      //if (Math.abs(parent.scrollY - current.offset) > 300)
      parent.scroll({
        top: current.offsetTop - 145,
        behavior: scrolledToItemIdx === -1 ? 'instant' : 'instant', //
      });
      setScrolledToItemIdx(activeItemIdx);
    }
  }, [activeItemIdx, activeItemRef?.current]);

  return (
    <>
      {items.length > 0 && header && (
        <div
          className="text-muted text-uppercase"
          style={{ padding: '2px 16px' }}
        >
          <small>{header}</small>
        </div>
      )}
      <ul
        className="list-group list-group-flush"
        style={{
          overflowY: 'scroll',
          overflowScrolling: 'touch',
          scrollbarWidth: 'none',
          height: `${height}`,
          minHeight: `${height}`,
          overscrollBehavior: 'contain', //none
        }}
        ref={scrollerRef}
      >
        {items &&
          items.map((item, idx) => {
            const id = item.id ?? idx;
            const refElement =
              idx === activeItemIdx ? (
                <div
                  style={{ height: '100%', position: 'absolute' }}
                  ref={activeItemRef}
                />
              ) : (
                <div />
              );
            return (
              item?.type && (
                <li
                  key={idx}
                  className={`list-group-item list-group-item-action ${
                    item.isActive ? 'active' : ''
                  }`}
                  role="button"
                  onClick={() => {
                    item.type !== 'switch' && onClick && onClick(id);
                  }}
                >
                  {refElement}
                  <div className="row flex-nowrap">
                    {item?.imgUrl && (
                      <div
                        className="col-2"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={item.imgUrl}
                          alt=""
                          style={{
                            //opacity: item.disabled ? 0.3 : 1,
                            width: '100%',
                            maxWidth: '40px',
                          }}
                        />
                      </div>
                    )}
                    <div className="col" style={{ alignSelf: 'center' }}>
                      <div
                        className={`text-wrap${
                          item.disabled ? ' text-muted' : ''
                        }`}
                        style={{ lineHeight: '20px' }}
                      >
                        {item.name}
                      </div>
                      <div>
                        <small className="text-muted text-nowrap-">
                          {item.descr}
                        </small>
                      </div>
                    </div>

                    <div className="col my-auto">
                      <div
                        className="float-end"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                        }}
                      >
                        {
                          //(item?.type === 'menu' || item?.type === 'button') &&
                          item.tag && <span className="tag">{item.tag}</span>
                        }
                        {item.tagMuted && (
                          <span className="tag-muted">{item.tagMuted}</span>
                        )}
                        {item.tagBlack && (
                          <span className="tag-black">{item.tagBlack}</span>
                        )}
                        {item.tagValue && (
                          <span className="tag-value">{item.tagValue}</span>
                        )}
                        {item?.type === 'menu' && (
                          <span className="forward-symbol text-muted" />
                        )}
                        {item?.type === 'switch' && (
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                              role="button"
                              htmlFor={`flexSwitchCheckDefault${idx}`}
                              style={{
                                position: 'absolute',
                                width: '100%',
                                left: 0,
                                top: 0,
                                height: '100%',
                                zIndex: 1,
                              }}
                            ></label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              name={idx}
                              value={idx}
                              checked={item.value}
                              id={`flexSwitchCheckDefault${idx}`}
                              onChange={(e) => onClick(id, e.target.checked)}
                            ></input>
                          </div>
                        )}

                        {item?.type === 'static' && item.value && (
                          <span className="">{item.value}</span>
                        )}

                        {false && !item.hideSubmenu && (
                          <span className="forward-symbol text-muted" />
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )
            );
          })}
      </ul>
    </>
  );
}

function TodoListDone({
  header = '',
  caption = 'Done',
  bgColor = 'var(--theme-primary)',
}) {
  return (
    <>
      {header && (
        <div
          className="text-muted text-uppercase"
          style={{ padding: '2px 16px' }}
        >
          <small>{header}</small>
        </div>
      )}
      <ul
        className="list-group list-group-flush"
        style={{
          opacity: 0.8,
          '--bs-list-group-item-padding-y': '30px',
          '--bs-list-group-bg': `${bgColor}`,
          '--bs-list-group-color': 'var(--theme-default)',
        }}
      >
        <li
          style={{
            border: 'none',
            padding: '30px',
          }}
          className="list-group-item "
        >
          <div className="row g-2">
            <div className="col text-center text-uppercase-">
              <div style={{ fontWeight: 400, lineHeight: '14px' }}>
                {caption}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
}

function SuccessScoreRating({ value = 0.7, header = null }) {
  return (
    <>
      {false && (
        <div
          className="text-muted text-uppercase"
          style={{ padding: '2px 16px' }}
        >
          <small>{header}</small>
        </div>
      )}

      <div
        style={{
          padding: '30px',
          borderRadius: '10px',
          backgroundColor: 'var(--theme-overlay-transparent)',
          textAlign: 'center',
          verticalAlign: 'middle',
        }}
      >
        <Rating
          readonly
          allowFraction
          size={24}
          initialValue={Math.min(1, value) * 5}
          SVGstrokeColor="#ffffff1c" //"var(--theme-yellow)"
          fillColor="var(--theme-yellow)"
          emptyColor="var(--theme-background)" //transparent
          SVGstorkeWidth={1}
        />
        <br />
        <div className="text-muted" style={{ marginTop: '6px' }}>
          {header ? `${header}: ` : ''}
          {priceFmt(value * 100, 1, 1)}%
        </div>
      </div>
    </>
  );
}

function TodoList({ items: itemsNotFiltered, header = '', sort = true }) {
  /*  items = [{ id, name }]   */

  const items = itemsNotFiltered?.filter((item) => item?.name);
  const sortedItems =
    items && sort
      ? items.sort((a, b) =>
          a.isDone && !b.isDone ? -1 : !a.isDone && b.isDone ? 1 : 0
        )
      : items;
  return (
    <>
      {header && items && (
        <div
          className="text-muted text-uppercase"
          style={{ padding: '2px 16px' }}
        >
          <small>{header}</small>
        </div>
      )}
      <ul
        className="list-group list-group-flush"
        style={{
          '--bs-list-group-item-padding-y': '30px',
        }}
      >
        {sortedItems &&
          sortedItems.map((item, idx) => {
            return (
              <li
                style={{
                  border: 'none',
                  paddingBottom: `${
                    idx === sortedItems.length - 1 ? 'default' : '6px'
                  }`,
                  paddingTop: `${idx === 0 ? 'default' : '6px'}`,
                }}
                key={idx}
                className="list-group-item "
              >
                <div className="row g-2">
                  <div
                    className="col-auto"
                    style={{
                      display: 'flex',
                      alignItems: 'normal',
                      justifyContent: 'center',
                    }}
                  >
                    {item.isDone && (
                      <img
                        src={'/img/success.svg'}
                        alt=""
                        style={{
                          marginTop: '0px',
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    )}
                    {!item.isDone && (
                      <img
                        src={'/img/time.svg'}
                        alt=""
                        style={{
                          marginTop: '2px',
                          width: '13px',
                          height: '13px',
                          marginLeft: '3px',
                          marginRight: '3px',
                        }}
                      />
                    )}
                  </div>
                  <div
                    className={`col ${
                      item.isDone ? 'text-primary' : 'text-default'
                    }`}
                  >
                    <div style={{ lineHeight: '14px' }}>
                      <small> {item.name}</small>
                    </div>
                    <div>
                      <small className="text-muted">{item.descr}</small>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </>
  );
}

function ProgressBar({
  show = true,
  max = 35,
  position = 0,
  barsCount = 25,
  y = 100,
  onClick = () => {},
  header = t('Bonus'),
  width = 180,
  //...props
}) {
  const [bars, setBars] = useState([]);

  useEffect(() => {
    setBars(Array(barsCount).fill(0));
  }, [position]);

  const padding = 15;
  const progressWidth = width;
  const barSize = Math.ceil(
    (progressWidth - padding * 2 - barsCount * 2) / barsCount
  );
  const height = barSize + padding * 2;
  const currIdx = Math.floor((position / max) * barsCount);
  const isVisibe = show; // && position; //show && currIdx ? 1 : 0
  const opacity = isVisibe ? 1 : 0;

  if (!show) return <></>;
  return (
    <>
      <div
        className="blur-filter--"
        style={{
          position: 'absolute',
          height: `${height}px`,
          zIndex: 102,
          borderRadius: '6px',
          padding: `0 ${padding}px`,
          left: '50%',
          top: `${y}px`,
          transform: 'translateX(-50%)',
          //backgroundColor: 'var(--theme-overlay-transparent)',
          backgroundColor: 'var(--theme-overlay-glass)',
          flexFlow: 'nowrap',
          display: 'flex',
          opacity: opacity,
          transition: 'opacity 1s',
        }}
        onClick={() => (isVisibe ? onClick() : null)}
      >
        <small
          style={{
            alignSelf: 'center',
            textWrap: 'nowrap',
            whiteSpace: 'nowrap',
          }}
          className="text-default text-uppercase"
        >
          {header}
        </small>
        <div
          style={{
            margin: `${padding - 1}px ${padding}px ${padding}px ${padding}px`,
            flexFlow: 'nowrap',
            display: 'flex',
          }}
        >
          {bars.map((_, id) => (
            <div
              key={id}
              style={{
                height: `${barSize}px`,
                width: `${barSize}px`,
                boxShadow: id < currIdx ? '0 0 10px var(--theme-primary)' : '',
                backgroundColor:
                  id < currIdx
                    ? 'var(--theme-primary-accent)'
                    : 'var(--theme-muted)',
                position: 'relative',
                margin: '1px',
                float: 'left',
              }}
            />
          ))}
        </div>
        <small
          style={{
            alignSelf: 'center',
            textWrap: 'nowrap',
            flexWrap: 'nowrap',
            display: 'flex',
          }}
        >
          {false && position >= max ? (
            <>
              <span
                className="glow-small text-primary-accent text-uppercase"
                style={{ minWidth: '15px' }}
              >
                {t('Claim')}
              </span>
            </>
          ) : (
            <>
              <span className="text-primary" style={{ minWidth: '15px' }}>
                {position.toFixed(0)}
              </span>
              <span className="text-default" style={{ margin: '0 5px' }}>
                │
              </span>
              <span className="text-default">{Math.max(max).toFixed(0)}</span>
            </>
          )}
        </small>
      </div>
    </>
  );
}

function SvgStyles({}) {
  const offsetFromMetals = 0.309287;
  const offsetToMetals = 0.757667;

  const offsetFrom = 0;
  const offsetTo = 1;

  return (
    <svg style={{ position: 'absolute' }} width="0" height="0">
      <defs>
        <radialGradient
          id="radial_shadow"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.249 56.4961) rotate(-23.471) scale(41.4276 46.8278)"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.551" stopOpacity="0.3" />
          <stop offset="0.696" stopOpacity="0.4" />
        </radialGradient>

        <linearGradient
          id="gradient-primary"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFromMetals}
            stopColor={`var(--theme-primary-dark)`}
            stopOpacity={0.95}
          />
          <stop
            offset={offsetToMetals}
            stopColor={'var(--theme-primary)'}
            stopOpacity={0.95}
          />
        </linearGradient>

        <linearGradient
          id="gradient-bronze"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFromMetals}
            stopColor="var(--theme-bronze-dark)"
          />
          <stop offset={offsetToMetals} stopColor="var(--theme-bronze-light)" />
        </linearGradient>

        <linearGradient
          id="gradient-silver"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFromMetals}
            stopColor={'var(--theme-silver-dark)'}
          />
          <stop
            offset={offsetToMetals}
            stopColor={'var(--theme-silver-light)'}
          />
        </linearGradient>

        <linearGradient
          id="gradient-muted"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFromMetals}
            stopColor={'var(--theme-background-dark)'}
          />
          <stop
            offset={offsetToMetals}
            stopColor={'var(--theme-background-light)'}
          />
        </linearGradient>

        <linearGradient
          id="gradient-gold"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offsetFromMetals} stopColor="var(--theme-gold-dark)" />
          <stop offset={offsetToMetals} stopColor="var(--theme-gold-light)" />
        </linearGradient>

        <linearGradient
          id="gradient-ruby-pink"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offsetFrom} stopColor={'var(--theme-ruby-pink-dark)'} />
          <stop offset={offsetTo} stopColor={'var(--theme-ruby-pink-light)'} />
        </linearGradient>

        <linearGradient
          id="gradient-ruby-red"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offsetFrom} stopColor={'var(--theme-ruby-red-dark)'} />
          <stop offset={offsetTo} stopColor={'var(--theme-ruby-red-light)'} />
        </linearGradient>

        <linearGradient
          id="gradient-ruby"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offsetFrom} stopColor={'var(--theme-ruby-dark)'} />
          <stop offset={offsetTo} stopColor={'var(--theme-ruby-light)'} />
        </linearGradient>

        <linearGradient
          id="gradient-amethyst-velvet"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFrom}
            stopColor={'var(--theme-amethyst-velvet-dark)'}
          />
          <stop offset={offsetTo} stopColor={'var(--theme-amethyst-velvet)'} />
        </linearGradient>

        <linearGradient
          id="gradient-amethyst"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offsetFrom} stopColor={'var(--theme-amethyst-dark)'} />
          <stop offset={offsetTo} stopColor={'var(--theme-amethyst-light)'} />
        </linearGradient>

        <linearGradient
          id="gradient-crystal"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offsetFrom} stopColor={'var(--theme-crystal-dark)'} />
          <stop offset={offsetTo} stopColor={'var(--theme-crystal-light)'} />
        </linearGradient>

        <linearGradient
          id="gradient-obsidian"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFromMetals}
            stopColor={'var(--theme-obsidian-dark)'}
          />
          <stop
            offset={offsetToMetals}
            stopColor={'var(--theme-obsidian-light)'}
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

function DotCoin({ box, opacity, ...props }) {
  return (
    <svg
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      overflow="visible"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-5 -5) scale(0.21)">
        <path
          d="M45.8297 29.446C42.8243 41.5011 30.6145 48.8375 18.558 45.8314C6.50662 42.826 -0.829849 30.6154 2.17685 18.5614C5.1809 6.50499 17.3907 -0.832024 29.4435 2.17335C41.4991 5.17873 48.8351 17.3906 45.8294 29.4462L45.8296 29.446H45.8297Z"
          fill="#F7931A"
        />
        <path
          d="M33.9256 20.7965C34.3734 17.8019 32.0936 16.1922 28.976 15.1183L29.9874 11.0619L27.5181 10.4466L26.5336 14.3962C25.8844 14.2343 25.2177 14.0818 24.5552 13.9305L25.5468 9.95482L23.0791 9.33954L22.0672 13.3947C21.53 13.2724 21.0024 13.1515 20.4905 13.0241L20.4933 13.0113L17.0881 12.161L16.4313 14.7984C16.4313 14.7984 18.2633 15.2183 18.2247 15.2442C19.2246 15.4937 19.4054 16.1556 19.3754 16.6803L18.2233 21.3015C18.2922 21.319 18.3815 21.3443 18.4801 21.3839C18.3977 21.3635 18.31 21.3411 18.2191 21.3194L16.6043 27.7931C16.4821 28.0969 16.1719 28.5528 15.4728 28.3796C15.4976 28.4155 13.6781 27.9318 13.6781 27.9318L12.4521 30.7583L15.6655 31.5594C16.2633 31.7093 16.8491 31.8661 17.426 32.0136L16.4042 36.1166L18.8706 36.7319L19.8825 32.6725C20.5563 32.8554 21.2102 33.0241 21.8504 33.1831L20.8419 37.2234L23.3113 37.8387L24.333 33.7434C28.5436 34.5403 31.7097 34.219 33.0423 30.4105C34.1162 27.3442 32.9889 25.5755 30.7737 24.4222C32.3871 24.0501 33.6024 22.9889 33.9264 20.7968L33.9257 20.7962L33.9256 20.7965ZM28.2838 28.7076C27.5207 31.7739 22.358 30.1164 20.6842 29.7007L22.0401 24.265C23.7138 24.6829 29.0814 25.5098 28.2839 28.7076H28.2838ZM29.0475 20.752C28.3514 23.5412 24.0544 22.1242 22.6605 21.7767L23.8898 16.8468C25.2837 17.1943 29.7727 17.8428 29.0477 20.752H29.0475Z"
          fill="white"
        />
        <circle cx="24" cy="24" r="21.5" stroke="white" strokeWidth="6" />
      </g>
    </svg>
  );
}

function SuperBox({ box, opacity, isExpired, ...props }) {
  const multiplier = box.multiplier;
  const expired = isExpired; //box.status === boxStatus.expired;
  const executed = box.status === boxStatus.executed;
  const styleId = expired
    ? 'gradient-muted'
    : multiplier >= 40
    ? 'gradient-obsidian'
    : multiplier >= 20
    ? 'gradient-amethyst-velvet'
    : multiplier >= 10
    ? 'gradient-ruby-pink'
    : multiplier >= 8
    ? 'gradient-crystal'
    : multiplier >= 7
    ? 'gradient-ruby-red'
    : multiplier >= 6
    ? 'gradient-gold'
    : multiplier >= 5
    ? 'gradient-silver'
    : multiplier >= 4
    ? 'gradient-bronze'
    : multiplier >= 3
    ? 'gradient-amethyst'
    : multiplier >= 2
    ? 'gradient-ruby'
    : 'gradient-primary';

  /*   return (
    <svg className="svg-box" {...props} fill="none">
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={`url(#${styleId})`}
        stroke="var(--theme-muted)"
      />
    </svg>
  );
 */
  return (
    props.fillOpacity > 0 && (
      <svg
        className="svg-box"
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        viewBox="0 0 40 40"
        preserveAspectRatio="none"
      >
        {executed && (
          <rect
            x="0"
            width="100%"
            height="100%"
            rx="2"
            fill="#80878c6d"
            opacity={opacity}
          />
        )}
        <g
          opacity={opacity}
          transform-origin="center 10"
          style={{
            transform: executed ? 'scale(0)' : 'scale(1)',
          }}
        >
          <rect
            x="0"
            width="100%"
            height="100%"
            rx="2"
            fill={`url(#${styleId})`}
          />
          {!expired && (
            <>
              <rect
                x="0"
                y="40"
                width="100%"
                height="100%"
                rx="2"
                transform="rotate(-90 0 40)"
                fill="url(#radial_shadow)"
                style={{ mixBlendMode: 'hard-light' }}
              />
              <path
                d="M0.745117 2C0.745117 0.895431 1.64055 0 2.74512 0H26.7451L25.29 1.46915C24.2849 2.48391 23.1324 3.34123 21.8716 4.01217V4.01217C18.668 5.71683 16.2387 8.58323 15.0823 12.0229L11.8964 21.4987C9.89893 27.4399 5.97416 32.5438 0.745117 36V36V2Z"
                fill="white"
                fillOpacity="0.2"
                style={{ mixBlendMode: 'hard-light' }}
              />
            </>
          )}
        </g>
      </svg>
    )
  );
}

function BtnIcon({ src }) {
  return (
    <div className="btn-icon">
      <img src={src} />
    </div>
  );
}

function SupportLink() {
  const { openWebBrowser } = useNativeAppContext();
  const showEmailLink = !isTelegram() && !isNativeApp();

  return (
    <>
      {!showEmailLink && (
        <small
          className="text-primary btn-link"
          onClick={() => {
            openWebBrowser('https://boxo.on.spiceworks.com');
          }}
        >
          <span>{t('Submit a ticket')}</span>
        </small>
      )}
      {showEmailLink && (
        <small className="text-primary btn-link">
          <a
            href="https://boxo.on.spiceworks.com"
            target="_blank"
            rel="external nofollow noopener"
          >
            {t('Submit a ticket')}
          </a>
        </small>
      )}
      <small className="text-muted">
        <span>. {t('Email us at {{address}}', { address: '' })}</span>
      </small>
      {!showEmailLink && (
        <small className="text-muted">
          <span>support@boxo.trade</span>
        </small>
      )}
      {showEmailLink && (
        <small className="text-primary btn-link">
          <a href="mailto:support@boxo.trade">support@boxo.trade</a>
        </small>
      )}
    </>
  );
}

function TutorialDialog({ title, message, buttonName, onClick }) {
  return (
    <div
      className="text-center text-default animateTranformY100"
      style={{
        position: 'absolute',
        bottom: '0',
        width: '100%',
        padding: '24px 16px',
        backgroundColor: 'var(--theme-overlay-transparent)',
        borderRadius: '24px 24px 0px 0px',
        zIndex: 103, //Adjusted to animated hand overlay
      }}
    >
      <div>{title}</div>

      <div>
        <img src="/img/fox/fox-face.png" width={`90px`} />
      </div>

      <small>
        {message}
        {/* .{' '}
      {t('You have {{number}} BOXO', {
        number: 5,
      })} */}
      </small>
      <br />
      <br />
      <div
        style={{
          width: '100%',
        }}
        className="btn btn-primary"
        role="button"
        onClick={onClick}
      >
        {buttonName ? buttonName : t(`Accept challenge`)}
      </div>
    </div>
  );
}

export {
  ProgressBar,
  DebugControl,
  BetControl,
  SelectionList,
  TodoList,
  TodoListDone,
  BonusItem,
  SoundControl,
  BlurPanel,
  BackgoundLight,
  SvgStyles,
  SuperBox,
  AnimatedHand,
  BtnIcon,
  SupportLink,
  SuccessScoreRating,
  Confetti,
  DotCoin,
  TutorialDialog,
  Speedometr,
  GiftJump,
};
