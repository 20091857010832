import * as toastify from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CountUpDown } from './countupdown';
import { t } from 'i18next';
import { priceFmt } from './utils';
import {
  loadPersistentSessionState,
  persistSessionState,
} from './utils/persist';
import 'animate.css';

const ErrorZoom = toastify.cssTransition({
  enter: 'animate__animated animate__headShake animate__faster', //'animate__animated animate__bounceIn',
  exit: 'animate__animated animate__fadeOut animate__faster', //
  appendPosition: false,
  collapse: false,
  //collapseDuration: 1,
});

const errorToast = (message, options) =>
  toast(message, {
    transition: ErrorZoom,
    autoClose: 500,
    icon: () => <img width="32" alt="" src="/img/i.svg" />,
    ...options,
  });

const toast = (message, options) => {
  const ToastMsg = ({ closeToast, toastProps }) => (
    <>
      <div
        className={`lh-1 text-default-accent ${
          options?.useMargin ? 'mt3' : ''
        }`}
      >
        <div style={{ fontSize: '0.876rem', fontWeight: 500 }}>
          {options?.header || 'Boxo'}
        </div>
      </div>
      <div
        className={`lh-1 ${
          options?.type === 'error' ? 'invalid-feedback' : 'text-default'
        }`}
      >
        <div style={{ fontSize: '0.876rem', fontWeight: 300 }}>{message}</div>
      </div>
    </>
  );

  toastify.toast(ToastMsg, {
    containerId: 'normalToast',
    type: 'success',
    toastId: message,
    ...options,
  });
};

const winToast = (message, options) => {
  const winAmount = options?.winAmount;
  const winMultiplier = options?.winMultiplier;
  const lastWinToastState = loadPersistentSessionState('lastWinToastState');

  const lastWinToastTime = lastWinToastState?.lastWinToastTime;
  const lastWinMultiplier = lastWinToastState?.lastWinMultiplier;
  const lastWinAmount = lastWinToastState?.lastWinAmount;
  const dateNow = Date.now();

  //supress too many winToasts
  if (
    dateNow - lastWinToastTime < 10 * 1000 &&
    (lastWinMultiplier >= winMultiplier || lastWinAmount >= winAmount)
  ) {
    return;
  }
  persistSessionState('lastWinToastState', {
    lastWinToastTime: dateNow,
    lastWinMultiplier: winMultiplier,
    lastWinAmount: winAmount,
  });

  const ToastMsg = ({ closeToast, toastProps }) => (
    <div>
      <div
        style={{
          marginTop: '7vh',
          marginBottom: '8vh',
        }}
      >
        <div className="text-primary text-center" style={{ fontWeight: 400 }}>
          <div>{message || 'Congrats!'}</div>
        </div>
        <div className="text-default text-center">
          <div
            style={{
              fontSize: '4vh',
              lineHeight: '4vh',
              fontWeight: 600,
              marginLeft: '-25px',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            ×<CountUpDown duration={1} value={winMultiplier} decimals={1} />
          </div>
        </div>
        <div
          className="text-primary text-center"
          style={{
            fontSize: '0.675em',
            fontWeight: '500',
            letterSpacing: '-0.01em',
          }}
        >
          {`${t('Win')}: ${priceFmt(winAmount, 1, 1)}`}
        </div>
        <img
          alt=""
          src="/img/decoration.svg"
          style={{
            position: 'absolute',
            height: '90%',
            width: '90%',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
          }}
        />
      </div>
    </div>
  );

  toastify.toast.clearWaitingQueue({ containerId: 'winToastContainer' });
  toastify.toast(ToastMsg, {
    containerId: 'winToastContainer',
    className: 'toastify-congrats-message',
    type: 'success',
    toastId: message,
    ...options,
  });
};

const clearToasts = () => {
  toastify.toast.dismiss();
};

const ToastContainer = () => {
  return (
    <toastify.ToastContainer
      style={{ '--toastify-z-index': 10000 }}
      containerId="normalToast"
      enableMultiContainer
      position="top-center"
      autoClose={4500}
      closeButton={false}
      limit={2}
      icon={() => <img width="32" alt="" src="/favicon.ico" />}
      transition={toastify.Zoom}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      hideProgressBar
      pauseOnFocusLoss
      draggable
      draggablePercent="130"
      draggableDirection="y"
      pauseOnHover
      theme="dark"
    />
  );
};

const WinToastContainer = () => {
  return (
    <toastify.ToastContainer
      style={{ '--toastify-z-index': 103 }} //Higher than overlay 102 and progress bar 101
      containerId="winToastContainer"
      enableMultiContainer
      position="top-center"
      autoClose={1200}
      closeButton={false}
      limit={1}
      icon={false}
      transition={toastify.Bounce}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      hideProgressBar
      pauseOnFocusLoss
      stacked
      draggable
      draggablePercent="20"
      draggableDirection="y"
      pauseOnHover
      theme="dark"
    />
  );
};

export {
  ToastContainer,
  errorToast,
  toast,
  clearToasts,
  WinToastContainer,
  winToast,
};
