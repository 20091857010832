import { t } from 'i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SelectionList, SupportLink } from '../../components/terminal';
import { logout } from '../../shared/api/auth';
import Header from '../../shared/header';
import { useLocalStorage } from '../../shared/hooks/use-hooks';
import { useLoggerContext } from '../../shared/hooks/use-logger';
import { useTelegramContext } from '../../shared/hooks/use-telegram';
import { useUserContext } from '../../shared/hooks/use-user';
import { ModalLayout } from '../../shared/layout';
import About from './about';
import Account from './account';
import Delete from './delete';
import Lang, { getCurrentLangName } from './lang';
import Notifications from './notifications';
import Privacy from './privacy';
import Profile from './profile';

export default function Settings({ onLogout }) {
  const logger = useLoggerContext();
  const { user } = useUserContext();
  const email = user?.email;

  const navigate = useNavigate();
  const location = useLocation();

  const onClose = () => {
    navigate('/app', { replace: true });
  };

  const onBack = location.state
    ? () => {
        navigate(location.state, { replace: true });
      }
    : null;

  return (
    <>
      <Header title="Settings" description={`Boxo App Settings`} />
      <Routes>
        <Route
          path="/"
          element={
            <Main
              email={email}
              onBack={onBack}
              onClose={onClose}
              onLogout={onLogout}
            />
          }
        />
        <Route
          path="/account"
          element={
            <Account
              logger={logger}
              email={email}
              onBack={onBack}
              onClose={onClose}
              onLogout={onLogout}
            />
          }
        />
        <Route
          path="/account/profile"
          element={
            <Profile
              logger={logger}
              email={email}
              onBack={onBack}
              onClose={onClose}
              onLogout={onLogout}
            />
          }
        />

        <Route
          path="/account/privacy"
          element={<Privacy onClose={onClose} />}
        />

        <Route path="/about" element={<About />} />

        <Route
          path="/account/delete"
          element={<Delete onLogout={onLogout} onClose={onClose} />}
        />
        <Route
          path="/notifications"
          element={
            <Notifications
              logger={logger}
              email={email}
              onBack={onBack}
              onClose={onClose}
              onLogout={onLogout}
            />
          }
        />
        <Route
          path="/language"
          element={
            <Lang
              logger={logger}
              email={email}
              onBack={onBack}
              onClose={onClose}
              onLogout={onLogout}
            />
          }
        />
      </Routes>
    </>
  );
}

function Main({ email, onClose }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [lang] = useLocalStorage('lng', null);
  const [langName, setLangName] = useState('Language');
  useEffect(() => {
    const l = getCurrentLangName();
    setLangName(l);
  }, [lang]);

  const [enableSonuds, setEnableSonuds] = useLocalStorage(
    'enable-sounds',
    true
  );

  const [enableMusic, setEnableMusic] = useLocalStorage('enable-music', true);

  const { user: tgUser } = useTelegramContext();

  return (
    <>
      <ModalLayout onClose={onClose}>
        <div className="modal-header text-center">
          <h1>{t(`Settings`)}</h1>
        </div>
        <div className="modal-body">
          <SelectionList
            items={[
              {
                name: t('Language'),
                descr: langName,
                imgUrl: '/img/lang.svg',
                type: 'menu',
              },
              {
                name: t('Sounds'),
                //descr: t('In-app sounds'),
                imgUrl: '/img/sounds.svg',
                value: enableSonuds,
                type: 'switch',
              },
              {
                name: t('Music'),
                imgUrl: '/img/music.svg',
                value: enableMusic,
                type: 'switch',
              },

              email && {
                name: t('Notifications'),
                descr: t('Bonuses, V.I.P., news'),
                imgUrl: '/img/bell.svg',
                type: 'menu',
              },
            ]}
            onClick={(idx) => {
              if (idx === 0)
                navigate('./language', {
                  replace: true,
                  state: location.pathname,
                });
              if (idx === 1) {
                setEnableSonuds(!enableSonuds);
              }

              if (idx === 2) {
                setEnableMusic(!enableMusic);
              }
              if (idx === 3)
                navigate('./notifications', {
                  replace: true,
                  state: location.pathname,
                });
            }}
          />

          <br className="mt-2" />
          {email && (
            <SelectionList
              header={tgUser?.username ? `@${tgUser?.username}` : email}
              items={[
                {
                  name: t('Delete account'),
                  imgUrl: '/img/delete.svg',
                  type: 'menu',
                },
              ]}
              onClick={() => {
                navigate('./account/delete', {
                  replace: true,
                  state: location.pathname,
                });
              }}
            />
          )}
        </div>
        <div className="modal-footer no-mobile-keyboard">
          <div className="mt-2 text-center">
            <small className="text-muted">{t('Have a question?')}</small>
            <br />
            <SupportLink />
          </div>
        </div>
      </ModalLayout>
    </>
  );
}
