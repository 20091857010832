import {
  handleMiddlewareError as handleError,
  maybe_refresh_auth_token_middlware,
} from './utils';
import { api_middleware } from './api-client';
import { loadPersistentState } from '../utils/persist';
import { isNativeApp } from '../utils';

export async function logSession(sessionId, deviceUserId, data) {
  if (process.env.NODE_ENV === 'production') {
    const sessionData = {
      sessionId,
      deviceUserId,
      data,
    };
    const session = loadPersistentState('session');
    let resp;
    if (session?.token)
      resp = await maybe_refresh_auth_token_middlware((api) =>
        api.post('/api/log/session', sessionData)
      ).catch(handleError);
    else
      resp = await api_middleware()
        .post('/api/log/session', sessionData)
        .catch(handleError);
    return resp;
  } else {
    console.log('logSession', data);
    return {
      data: { success: true },
    };
  }
}

/* 
  export async function logSession_short(sessionId, deviceUserId, data) {
  if (true || process.env.NODE_ENV === 'production') {
    const sessionData = {
      sessionId,
      deviceUserId,
      data,
    };
    const d = { d: btoa(JSON.stringify(sessionData)) };
    const session = loadPersistentState('session');
    if (session?.token)
      await maybe_refresh_auth_token_middlware((api) =>
        api.post('/api/l/s', d)
      ).catch(handleError);
    else await api_middleware().post('/api/l/s', d).catch(handleError);
  } else console.log('logSession', data);
}

export async function logEvent_short(event) {
  if (true || process.env.NODE_ENV === 'production' || isNativeApp()) {
    const d = { d: btoa(JSON.stringify(event)) };

    const session = loadPersistentState('session');
    if (session?.token)
      await maybe_refresh_auth_token_middlware((api) =>
        api.post('/api/l/e', d)
      ).catch(handleError);
    else await api_middleware().post('/api/l/e', d).catch(handleError);
  } else {
    //Debug
    if (event?.name === 'error') console.error('logEvent', event);
    else console.log('logEvent', event);
  }
}
*/

export async function logEvent(event) {
  if (process.env.NODE_ENV === 'production' || isNativeApp()) {
    const session = loadPersistentState('session');
    if (session?.token)
      await maybe_refresh_auth_token_middlware((api) =>
        api.post('/api/log/event', event)
      ).catch(handleError);
    else
      await api_middleware().post('/api/log/event', event).catch(handleError);
  } else {
    //Debug
    if (event?.name === 'error') console.error('logEvent', event);
    else console.log('logEvent', event);
  }
}

export async function logStartRegistration(sessionId, email) {
  if (process.env.NODE_ENV === 'production')
    await api_middleware().post('/api/log/register', {
      sessionId,
      email,
    });
}

export async function logFinishRegistration() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.post('/api/log/registered', {})
  ).catch(handleError);
  return data;
}
