import { Logger } from 'sass';
import { assign, send, log, createMachine } from 'xstate';

export const boxSelectionMachine = createMachine({
  id: 'boxSelection',
  context: {
    selection: [],
  },
  initial: 'idle',
  states: {
    idle: {
      //entry: [log((context, _) => ['idle', context])],
      on: {
        ADD: [
          {
            internal: true,
            cond: (context, event) =>
              !event.payload.box ||
              context.selection?.findIndex(
                (b) => b.id === event.payload.box.id
              ) >= 0,
          },
          {
            internal: true,
            actions: [
              assign({
                selection: (context, event) => [
                  ...context.selection,
                  event.payload.box,
                ],
              }),
              'notifyAdding',
            ],
          },
        ],
        SUBMIT: [
          {
            internal: true,
            cond: (context) => context.selection.length === 0,
          },
          {
            target: 'submitting',
          },
        ],
        RESET: {
          internal: true,
          actions: [
            assign({
              selection: [],
            }),
          ],
        },
      },
    },
    submitting: {
      entry: [log((context, _) => ['submitting', context])],
      on: {
        ADD: {
          //ignore
          internal: true,
        },
        RESET: {
          //ignore
          internal: true,
        },
      },
      invoke: {
        src: 'submitBoxes',
        onDone: {
          target: 'idle',
          actions: [
            assign({
              selection: [],
            }),
            'notifySubmission',
          ],
        },
        onError: {
          target: 'idle',
          actions: [
            assign({
              selection: [],
            }),
            'notifyError',
          ],
        },
      },
    },
  },
});

export const boxesMachine = createMachine(
  {
    id: 'boxes',
    context: {
      price: 0,
      time: Date.now(),
      timeHistory: 0,
      boxes: [],
      pendingCount: 0,
    },
    initial: 'idle',
    states: {
      idle: {
        on: {
          ADD: {
            internal: true,
            actions: [
              assign({
                pendingCount: (_, event) => event.payload.boxes.length,
                boxes: (context, event) =>
                  context.boxes
                    .concat(event.payload.boxes)
                    .sort((a, b) =>
                      a.prize === b.prize ? a.y1 - b.y1 : a.prize - b.prize
                    ),
              }),
              'notifyAdded',
            ],
          },
          RESET: {
            internal: true,
            actions: [
              assign({
                pendingCount: 0,
                boxes: [],
              }),
            ],
          },
          UPDATE: {
            internal: true,
            target: 'updating',
          },
        },
      },
      updating: {
        invoke: {
          src: 'updateBoxes',
          onDone: {
            target: 'idle',
            actions: [
              assign({
                boxes: (_, event) => event.data.boxes,
                time: (_, event) => event.data.time,
                price: (_, event) => event.data.price,
                getCloser: (context, event) =>
                  !event.data.count.getCloser
                    ? undefined
                    : !context.count?.getCloser ||
                      event.data.count.getCloser > context.count.getCloser
                    ? event.data.count.getCloser
                    : context.count.getCloser,
                pendingCount: (_, event) => event.data.count.pending,
                lastExecutedTime: (context, event) =>
                  event.data.count.executed > 0
                    ? Date.now()
                    : context.lastExecutedTime,
              }),
              'notifyUpdate',
            ],
          },
          onError: {
            target: 'idle',
            actions: ['notifyError'],
          },
        },
      },
    },
  },
  {
    services: {
      updateBoxes: (context, event) =>
        new Promise(async (resolve) => {
          const result = executeBoxes(
            context.boxes,
            event.payload.time,
            event.payload.price,
            context.time,
            context.price,
            context.timeHistory
          );
          resolve(result);
        }),
    },
  }
);

export const boxStatus = { pending: 0, executed: 1, expired: -1, outdated: -2 };

function executeBoxes(boxes, time, price, oldTime, oldPrice, timeHistory) {
  let prize = 0;
  let executeBoxes = 0;
  let newPendingBoxes = 0;
  let mainBox = null;
  let maxMultiplier = 0;
  let maxPrize = 0;

  let boxesWinBet = 0;

  let boxesExpired = 0;
  let boxesExpiredBet = 0;

  let emptyBoxesWin = 0; //cnt of won boxes with mult===1
  let emptyBoxesPrize = 0;
  let emptyBoxesExpired = 0; //cnt of expired boxes with mult===1
  let emptyBoxesExpiredBet = 0;

  const newBoxes = boxes
    .map((box) => {
      if (box.status === boxStatus.pending) {
        if (
          //executed
          (box.y1 <= oldTime &&
            oldTime < box.y2 &&
            ((oldPrice <= box.x1 && box.x1 <= price) ||
              (oldPrice <= box.x2 && box.x2 <= price) ||
              (price <= box.x1 && box.x1 <= oldPrice) ||
              (price <= box.x2 && box.x2 <= oldPrice))) ||
          (box.y1 < time && time < box.y2 && price > box.x1 && price < box.x2)
        ) {
          prize = prize + box.prize;
          executeBoxes++;
          boxesWinBet = boxesWinBet + box.bet;
          if (maxMultiplier < box.multiplier) {
            maxMultiplier = box.multiplier;
            maxPrize = box.prize;
          }
          if (box.multiplier === 1) {
            emptyBoxesWin++;
            emptyBoxesPrize = emptyBoxesPrize + prize;
          }
          return { ...box, status: boxStatus.executed, executedAt: Date.now() };
        } else if (box.y2 < time) {
          //expired
          boxesExpired++;
          boxesExpiredBet = boxesExpiredBet + box.bet;

          if (box.multiplier === 1) {
            emptyBoxesExpired++;
            emptyBoxesExpiredBet = emptyBoxesExpiredBet + box.bet;
          }
          return { ...box, status: boxStatus.expired };
        }
        newPendingBoxes++;

        const width = box.x2 - box.x1;
        const height = box.y2 - box.y1;
        const timeDistance = (box.y1 - time) / height;
        const priceDistance = Math.max(price - box.x2, box.x1 - price, 0);

        const minTimeDistance = 1;

        const bestPriceDistance =
          timeDistance >= minTimeDistance
            ? 10000000 //Init price distance for box if it's too long to wait
            : Math.min(
                priceDistance,
                Math.min(box.bestPriceDistance, 10000000) //reset if lost 0 distance
              );

        const isCloser =
          timeDistance >= 0 &&
          timeDistance < minTimeDistance &&
          (priceDistance < box.bestPriceDistance || priceDistance === 0);

        const getCloserCount = isCloser
          ? (box.getCloserCount || 0) + 1
          : box.getCloserCount;

        const newBox = {
          ...box,
          isCloser,
          getCloserCount,
          bestPriceDistance,
          timeDistance,
          priceDistance,
        };

        const canBeMain =
          newBox.multiplier >= 0 && newBox.priceDistance <= 1 * width;

        mainBox =
          (canBeMain && !mainBox) ||
          (canBeMain &&
            (newBox.timeDistance < mainBox.timeDistance ||
              (newBox.timeDistance === mainBox.timeDistance &&
                newBox.priceDistance < mainBox.priceDistance)))
            ? newBox
            : mainBox;

        return newBox; //still pending
      } else {
        //expired or executed
        if (box.y2 < time - timeHistory) {
          return { ...box, status: boxStatus.outdated }; //outdated
        }
        return box; //still expired or executed
      }
    })
    .filter(
      (box) =>
        box.status === boxStatus.expired ||
        box.status === boxStatus.pending ||
        box.status === boxStatus.executed
    );

  return {
    boxes: newBoxes,
    prize,
    time,
    price,
    mainBox,
    count: {
      executed: executeBoxes,
      boxesWinBet,
      boxesExpired,
      boxesExpiredBet,
      emptyBoxesWin,
      emptyBoxesPrize,
      emptyBoxesExpired,
      emptyBoxesExpiredBet,
      pending: newPendingBoxes,
      maxMultiplier,
      maxPrize,
      getCloser: mainBox?.isCloser ? mainBox?.getCloserCount : undefined,
    },
  };
}
