import { useEffect, useRef } from 'react';
import { t } from 'i18next';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import NotFound from './404';
import { useMachine } from '@xstate/react';
import { useSessionStorage } from '../shared/hooks/use-hooks';
import {
  loginMachine,
  resetPasswordMachine,
  resetPasswordRequestMachine,
  signUpMachine,
  signUpVerificationMachine,
} from '../shared/machines';
import { ModalLayout } from '../shared/layout';
import { Loader } from './loader';
import { useLoggerContext } from '../shared/hooks/use-logger';
import {
  AppleSignInButton,
  GoogleSignInButton,
  TelegramSignInButton,
  useSignIn,
} from '../shared/hooks/use-sign-in';
import { signInWithApple, signInWithGoogle } from '../shared/api/auth';
import { useState } from 'react';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import {
  compareVersions,
  isAndroid,
  isIos,
  isNativeApp,
  isTelegram,
  tryToParseJSON,
} from '../shared/utils';
import { useTelegramContext } from '../shared/hooks/use-telegram';
import { useUserContext } from '../shared/hooks/use-user';
import { BtnIcon, TodoList } from '../components/terminal';
import Header from '../shared/header';
import { useSoundContext } from '../shared/hooks/use-sound';

export default function Auth({ logger, onRegister, onLogin, email }) {
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/app', { replace: true });
  };

  const onLoginNavigate = (email = null, url = null) => {
    const emailParam = email ? btoa(email) : '';
    const urlParam = url ? btoa(url) : '';
    navigate(`./login?email=${emailParam}&url=${urlParam}`, {
      replace: true,
    });
  };

  const onSignUpNavigate = () => {
    navigate('./sign-up', { replace: true });
  };

  const onSuccessLogin = (email, url = null) => {
    onLogin(email, url);
  };

  const onSuccessSignUp = (email) => {
    onRegister(email);
    navigate('./success', { replace: true });
  };

  const onResetPasswordNavigate = () => {
    navigate('./reset', {
      replace: true,
      state: location.pathname,
    });
  };

  const onBonusNavigate = () => {
    if (email)
      navigate('/app/bonus/welcome', {
        //state: location.pathname, //no way back
        replace: true,
      });
    else
      setTimeout(() => {
        navigate('/app/bonus/no-auth', {
          state: location.pathname,
          replace: true,
        });
      }, 500);
  };

  const bonus = 500;

  const location = useLocation();

  const onBack = !location.state
    ? null
    : () => {
        navigate(location.state, { replace: true });
      };

  return (
    <>
      <Header title="Register" description={`Create an account`} />
      <Routes>
        {isTelegram() && (
          <>
            <Route
              path="/sign-up"
              element={<SigninWithTelegramStep onClose={onClose} />}
            />
            <Route
              path="/login"
              element={<SigninWithTelegramStep onClose={onClose} />}
            />
          </>
        )}

        <Route
          path="/sign-up"
          element={
            <SignUp
              logger={logger}
              bonus={bonus}
              onClose={onClose}
              onLoginNavigate={onLoginNavigate}
              onBonusNavigate={onBonusNavigate}
              onSuccessLogin={onSuccessLogin}
              onSuccessSignUp={onSuccessSignUp}
            />
          }
        />
        <Route
          path="/sign-up/:email/:code/:loginToken"
          element={
            <SignUpVerification
              logger={logger}
              onLoginNavigate={onLoginNavigate}
              onSuccessSignUp={onSuccessSignUp}
            />
          }
        />

        <Route
          path="/success/"
          element={
            <SignUpVerificationSuccessStep onGetBonus={onBonusNavigate} />
          }
        />

        <Route
          path="/google/"
          element={
            <SignInWithGoogle
              onLoginNavigate={onLoginNavigate}
              onSuccessLogin={onSuccessLogin}
              onSuccessSignUp={onSuccessSignUp}
            />
          }
        />
        <Route
          path="/apple/"
          element={
            <SignInWithApple
              onLoginNavigate={onLoginNavigate}
              onSuccessLogin={onSuccessLogin}
              onSuccessSignUp={onSuccessSignUp}
            />
          }
        />
        <Route
          path="/telegram/"
          element={
            <SignInWithTelegram
              onLoginNavigate={onLoginNavigate}
              onSuccessLogin={onSuccessLogin}
              onSuccessSignUp={onSuccessSignUp}
            />
          }
        />

        <Route
          path="/reset"
          element={<ResetPasswordRequest onClose={onClose} onBack={onBack} />}
        />
        <Route
          path="/reset/:email/:token"
          element={
            <ResetPassword
              logger={logger}
              onClose={onClose}
              onSuccessLogin={onSuccessLogin}
              onSuccessSignUp={onSuccessSignUp}
            />
          }
        />
        <Route
          path="/login"
          element={
            <Login
              logger={logger}
              onClose={onClose}
              onSignUpNavigate={onSignUpNavigate}
              onSuccessLogin={onSuccessLogin}
              onSuccessSignUp={onSuccessSignUp}
              onRegister={onRegister}
              onResetPasswordNavigate={onResetPasswordNavigate}
            />
          }
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </>
  );
}

function ResetPasswordRequest({ onClose, onBack }) {
  const [resetPasswordRequestMachineState, send] = useMachine(
    resetPasswordRequestMachine,
    {
      state: resetPasswordRequestMachine.initialState,
    }
  );
  return (
    <>
      <Header
        title="Reset password"
        description={`Enter your email and we will send you instructions to reset your password`}
      />

      {resetPasswordRequestMachineState.hasTag('loading') && <Loader />}
      {resetPasswordRequestMachineState.hasTag('enterEmail') && (
        <EnterEmailStep
          header={t('Reset password')}
          subHeader={t(
            'Enter your email and we will send you instructions to reset your password'
          )}
          hideSocialLogin
          state={resetPasswordRequestMachineState}
          send={send}
          onClose={onClose}
          onBack={onBack}
        />
      )}
      {resetPasswordRequestMachineState.hasTag('success') && (
        <ResetPasswordRequestSuccessStep
          state={resetPasswordRequestMachineState}
          send={send}
          onClose={onClose}
        />
      )}
    </>
  );
}

function ResetPasswordRequestSuccessStep({ state, send, onClose }) {
  return (
    <ModalLayout
      onClose={onClose}
      onBack={() => {
        send({ type: 'BACK' });
      }}
    >
      <div className="modal-header">
        <h1 className="text-center">{t(`Check your e-mail`)}</h1>
        <div
          className="text-default text-center"
          style={{
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {t(`We have sent email to`)} <b>{state?.context?.email}</b>
        </div>

        <img
          src="/img/letter.svg"
          alt=""
          style={{
            display: 'block',
            margin: '3vh auto',
          }}
        />
        <div className="text-default text-center">
          {t('Follow instructions in your email to reset your password')}
        </div>
      </div>
      <div className="modal-body"></div>

      <div className="modal-footer justify-content-center">
        {false && (
          <div
            className="btn btn-primary"
            role="button"
            onClick={() => {
              send({ type: 'SUBMIT', payload: { email: state.context.email } });
            }}
          >
            {t(`Resend email`)}
          </div>
        )}
      </div>
    </ModalLayout>
  );
}

function ResetPassword({ logger, onClose, onSuccessLogin, onSuccessSignUp }) {
  const [resetPasswordState, send, service] = useMachine(resetPasswordMachine, {
    state: resetPasswordMachine.initialState,
  });

  useEffect(() => {
    service?.subscribe((state) => {
      logger &&
        !state.hasTag('loading') &&
        state?.value &&
        logger.landing('step', { step: state?.value });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params = useParams();
  const token = params.token;
  const email = atob(params.email);

  const onSuccess = () => {
    if (resetPasswordState.context.isRegistration)
      onSuccessSignUp(resetPasswordState.context.email);
    else onSuccessLogin(resetPasswordState.context.email);
  };

  return (
    <>
      {resetPasswordState.hasTag('loading') && <Loader />}
      {resetPasswordState.hasTag('enterPassword') && (
        <EnterNewPasswordStep
          email={email}
          token={token}
          state={resetPasswordState}
          send={send}
          onClose={onClose}
        />
      )}
      {resetPasswordState.hasTag('success') && (
        <ResetPasswordSuccessStep
          state={resetPasswordState}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}

function EnterNewPasswordStep({ email, token, state, send, onClose }) {
  const passwordInputRef = useRef();
  return (
    <>
      <ModalLayout onClose={onClose}>
        <div className="modal-header">
          <h1>{t(`Enter new password`)}</h1>

          <div
            className="text-default"
            style={{
              whiteSpace: 'nowrap',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {t(`E-mail address`)} <b>{email}</b>
          </div>
        </div>
        <div className="modal-body">
          <input
            style={{ lineHeight: '50px', verticalAlign: 'top' }}
            ref={passwordInputRef}
            type="password"
            id="typePassword"
            placeholder={t('Password')}
            onChange={() => {
              send({
                type: 'RESET_ERROR',
              });
            }}
          />
          <div className="invalid-feedback">{state?.context?.error}</div>
        </div>
        <div className="modal-footer">
          <div
            className="btn btn-primary"
            role="button"
            onClick={() => {
              const newPassword = passwordInputRef.current.value;
              send({
                type: 'SUBMIT',
                payload: { password: newPassword, email, token },
              });
            }}
          >
            {t(`Next`)}
          </div>
        </div>
      </ModalLayout>
    </>
  );
}

function ResetPasswordSuccessStep({ state, onSuccess }) {
  return (
    <ModalLayout>
      <div className="modal-header">
        <h1 className="text-center">{t(`Password changed!`)}</h1>

        <img
          src="/img/success.svg"
          alt=""
          style={{
            display: 'block',
            margin: '3vh auto',
          }}
        />
      </div>

      <div className="text-default text-center">
        {t(`Your password has been changed successfully`)}
      </div>
      <div className="modal-body"></div>
      <div className="modal-footer no-mobile-keyboard justify-content-center">
        <div
          className="btn btn-primary"
          role="button"
          onClick={() => {
            onSuccess();
          }}
        >
          {t(`Continue`)}
        </div>
      </div>
    </ModalLayout>
  );
}

function Login({
  logger,
  onClose,
  onSignUpNavigate,
  onResetPasswordNavigate,
  onSuccessLogin,
  onSuccessSignUp,
}) {
  const [loginState, send, service] = useMachine(loginMachine, {
    state: loginMachine.initialState,
  });

  const { isLogged, user } = useUserContext();
  const [params] = useSearchParams();

  //example: /app/auth/login?email=YWRtaW5AYm94by50cmFkZQ==&url=L2FwcC9ib251cy9yZWNvdmVyeQ==
  const [redirectData, setRedirectData] = useState({ url: null, email: null });
  const navigate = useNavigate();

  useEffect(() => {
    if (loginState.matches('success')) {
      if (loginState.context.isRegistration)
        onSuccessSignUp(loginState.context.email);
      //Login confirmation leads to /auth/sign-up
      else
        onSuccessLogin(
          loginState.context.email,
          loginState.context.email === redirectData.email
            ? redirectData.url ?? ''
            : ''
        );
    }
  }, [loginState]);

  useEffect(() => {
    const email_ = params.get('email') ? atob(params.get('email')) : null;
    const email = email_ === 'empty_email' ? '' : email_;
    const url = params.get('url') ? atob(params.get('url')) : null;
    if (isLogged && email === user?.email) {
      navigate(url, { replace: true });
    } else {
      setRedirectData({ email, url });
    }
  }, [isLogged, user?.email]);

  useEffect(() => {
    service?.subscribe((state) => {
      logger &&
        !state.hasTag('loading') &&
        state?.value &&
        logger.landing('step', { step: state?.value });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title="Login" description={`Welcome back!`} />

      {loginState.hasTag('loading') && <Loader />}
      {loginState.hasTag('login') && (
        <EnterCredentialsStep
          state={loginState}
          send={send}
          onClose={onClose}
          onSignUp={onSignUpNavigate}
          onReset={onResetPasswordNavigate}
        />
      )}
      {loginState.hasTag('enterCode') && (
        <EnterCodeStep state={loginState} send={send} onClose={onClose} />
      )}
    </>
  );
}

function SignUpVerification({ logger, onLoginNavigate, onSuccessSignUp }) {
  const [_, setSignUpStatePersisted] = useSessionStorage('signUp-state', '');

  const [
    signUpVerificationStatePersisted,
    setSignUpVerificationStatePersisted,
  ] = useSessionStorage(
    'signUpVerification-state',
    signUpVerificationMachine.initialState
  );
  const [state, send, service] = useMachine(signUpVerificationMachine, {
    state: signUpVerificationStatePersisted,
  });

  const params = useParams();
  const loginToken = params?.loginToken;
  const code = params?.code;
  const email = atob(params?.email);

  useEffect(() => {
    service?.subscribe((state) => {
      setSignUpVerificationStatePersisted(state);
      setSignUpStatePersisted('');
      if (!state?.hasTag('loading') && state?.value)
        logger?.event('sign-up', { step: state?.value });
      if (state.matches('success')) {
        logger?.event('registered', { email });
        onSuccessSignUp(email);
      }
      /*
      if (state.matches('error')) {
        if (state.context.error === 'Unknown error: Method Not Allowed')
          onLoginNavigate(email, '/app/bonus/welcome');
      }
      */
    });
  }, []);

  function checkToken() {
    logger?.event('click', { target: 'VerifyToken' });
    if (!loginToken || !code || !email) return;
    send('SUBMIT', { payload: { loginToken, code, email } });
  }

  return (
    <>
      {state.hasTag('loading') && <Loader />}
      {state.hasTag('verifyEmail') && (
        <SignUpVerificationStep email={email} onContinue={checkToken} />
      )}
      {state.matches('error') && (
        <SignUpVerificationErrorStep
          error={state.context.error}
          onLoginNavigate={onLoginNavigate}
          email={email}
        />
      )}
    </>
  );
}

function SignUpVerificationSuccessStep({ onGetBonus }) {
  return (
    <ModalLayout>
      <div className="modal-header">
        <h1 className="text-center">{t(`Congrats!`)}</h1>
        <div className="text-default text-center">
          {false && t(`You're successfully signed in`)}
          {t(`Your account is successfully created`)}
        </div>
        <img
          src="/img/success.svg"
          alt=""
          style={{
            display: 'block',
            margin: '3vh auto',
          }}
        />
      </div>
      <div className="modal-body"></div>

      <div className="modal-footer no-mobile-keyboard justify-content-center">
        <div className="btn btn-primary" role="button" onClick={onGetBonus}>
          {t(`Continue`)}
        </div>
      </div>
    </ModalLayout>
  );
}

function SignUpVerificationErrorStep({ error, onLoginNavigate, email }) {
  return (
    <ModalLayout>
      <div className="modal-header">
        <h1 className="text-center">{t(`Confirm e-mail`)}</h1>
        <div className="text-muted text-center">{t(`Error`)}</div>
        <img
          src="/img/failed.svg"
          alt=""
          style={{
            display: 'block',
            margin: '3vh auto',
          }}
        />
        <div className="invalid-feedback text-center">{error}</div>
      </div>
      <div className="modal-body"></div>

      <div className="modal-footer no-mobile-keyboard justify-content-center">
        <div
          className="btn btn-primary"
          role="button"
          onClick={() => onLoginNavigate(email)}
        >
          {t(`Login`)}
        </div>
      </div>
    </ModalLayout>
  );
}

function SignUpVerificationStep({ onContinue, email }) {
  return (
    <ModalLayout>
      <div className="modal-header">
        <h1 className="text-center">{t(`Confirm e-mail`)}</h1>
        <div className="text-muted text-center">{email}</div>
        <img
          src="/img/caution.svg"
          alt=""
          style={{
            display: 'block',
            margin: '3vh auto',
          }}
        />
      </div>
      <div className="modal-body"></div>

      <div className="modal-footer no-mobile-keyboard justify-content-center">
        <div className="btn btn-primary" role="button" onClick={onContinue}>
          {t(`Confirm`)}
        </div>
      </div>
    </ModalLayout>
  );
}

function SignUp({ logger, bonus, onClose, onLoginNavigate, onBonusNavigate }) {
  const [signUpStatePersisted, setSignUpStatePersisted] = useSessionStorage(
    'signUp-state',
    signUpMachine.initialState
  );

  const [signUp, send, service] = useMachine(signUpMachine, {
    state: signUpStatePersisted,
  });

  useEffect(() => {
    service?.subscribe((state) => {
      setSignUpStatePersisted(state);
      if (state.matches('enterCode') && state.context?.email)
        logger.startRegistration(state.context?.email);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !signUp.hasTag('loading') &&
      signUp?.value &&
      logger?.event('sign-up', { step: signUp?.value });
  }, [signUp.value]);

  const onAbortFlow = () => {
    setSignUpStatePersisted('');
    onClose();
  };

  const onLoginFlow = (email = null) => {
    setSignUpStatePersisted('');
    onLoginNavigate(email);
  };

  return (
    <>
      {signUp.hasTag('loading') && <Loader />}

      {signUp.hasTag('enterEmail') && (
        <EnterEmailStep
          bonus={bonus}
          state={signUp}
          send={send}
          onClose={onAbortFlow}
          onLogin={onLoginFlow}
          onBonusNavigate={onBonusNavigate}
        />
      )}

      {signUp.hasTag('enterPassword') && (
        <EnterPasswordStep
          bonus={bonus}
          state={signUp}
          send={send}
          onClose={onAbortFlow}
          onLogin={onLoginFlow}
        />
      )}

      {signUp.hasTag('enterCode') && (
        <EnterCodeStep state={signUp} send={send} onClose={onAbortFlow} />
      )}

      {/* signUp.hasTag('success') && (
        <SuccessStep
          bonus={bonus}
          signUp={signUp}
          send={send}
          onClose={onSuccessFlow}
        />
      ) */}
    </>
  );
}

export function HiddenEmailButton({ onClick }) {
  const [isHidden, setIsHidden] = useState(true);
  return (
    <>
      {isHidden && (
        <div
          className="btn btn-secondary mt-2"
          style={{
            '--bs-btn-border-color': 'transparent',
            '--bs-btn-hover-border-color': 'transparent',
            '--bs-btn-active-border-color': 'transparent',
            '--bs-btn-hover-bg': 'transparent',
            '--bs-btn-active-bg': 'transparent',
            //fontWeight: 400,
          }}
          role="button"
          onClick={() => setIsHidden(false)}
        >
          <div className="btn-icon">
            <img
              style={{
                position: 'absolute',
                width: '20px',
                transform: 'translate(-50%, -25px)',
              }}
              src="/img/dropdown.svg"
            />
          </div>
        </div>
      )}

      {!isHidden && (
        <div
          className="btn btn-secondary mt-2"
          role="button"
          onClick={() => onClick && onClick()}
        >
          <BtnIcon src="/img/email.svg" />
          {t(`Continue with Email`)}
        </div>
      )}
    </>
  );
}

function EnterEmailStep({
  bonus,
  state,
  send,
  onClose,
  onBack,
  onLogin,
  onBonusNavigate,
  header,
  subHeader,
  hideSocialLogin,
}) {
  const emailInputRef = useRef();
  const {
    isGoogleSignInSupported,
    isAppleSignInSupported,
    isTelegramSignInSupported,
  } = useSignIn();

  const { isLogged } = useUserContext();
  const [hideEmail, setHideEmail] = useState(
    !hideSocialLogin &&
      !subHeader &&
      !header &&
      !state?.context?.email &&
      !emailInputRef.current?.value
  );
  const [hideMobileKeyboard, setHideMobileKeyboard] = useState(true);

  //console.log('state?.context?.email=' + !state?.context?.email);

  return (
    <ModalLayout onClose={onClose} onBack={onBack}>
      {hideEmail && (
        <div className="modal-header text-center">
          {<h1>{t(`Create an account`)}</h1>}
          {bonus > 0 && !isLogged && (
            <>
              <div className="text-default">
                {t('Register and get bonus of')}{' '}
                <span
                  className="text-primary btn-link"
                  role="button"
                  onClick={onBonusNavigate}
                >
                  {bonus}
                </span>{' '}
                BOXO
              </div>
            </>
          )}
        </div>
      )}

      {!hideEmail && (
        <div className="modal-header">
          {!header && <h1>{t(`Create an account`)}</h1>}
          {!!header && <h1>{header}</h1>}
          {!subHeader && (
            <div className="text-default">{t('Continue with Email')}</div>
          )}
          {!!subHeader && <div className="text-default">{subHeader}</div>}
        </div>
      )}

      {hideEmail && !hideSocialLogin && (
        <>
          <div className="modal-body">
            <img
              src={'/img/fox/welcome.png'}
              style={{
                //height: '50vh',
                //height: '50svh',
                width: '110%',
                left: '50%',
                position: 'absolute',
                transform: 'translate(-50%, 0%)',
              }}
            />
          </div>

          <div className="modal-footer no-mobile-keyboard">
            <div className="w-100">
              {isGoogleSignInSupported && <GoogleSignInButton />}
              {isAppleSignInSupported && <AppleSignInButton />}
              {isTelegramSignInSupported && <TelegramSignInButton />}
              <HiddenEmailButton
                onClick={() => {
                  setHideEmail(false);
                  //setTimeout(() => emailInputRef?.current?.focus(), 500);
                }}
              />
            </div>
            <div className="mt-3 mb-3 text-center">
              {onLogin && (
                <>
                  <small className="text-muted">
                    {t('Already registered?')}
                  </small>
                  {'  '}
                  <small
                    className="text-primary btn-link"
                    role="button"
                    onClick={() => {
                      onLogin();
                    }}
                  >
                    {t(`Login`)}
                  </small>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {(!hideEmail || hideSocialLogin) && (
        <>
          <div className="modal-body">
            <input
              ref={emailInputRef}
              type="email"
              id="typeEmail"
              placeholder={t('E-mail address')}
              defaultValue={state?.context?.email}
              onFocus={() =>
                (isIos() || isAndroid()) &&
                setTimeout(() => setHideMobileKeyboard(false), 800)
              }
              onBlur={() =>
                (isIos() || isAndroid()) &&
                setTimeout(() => setHideMobileKeyboard(true), 400)
              }
              onChange={() => {
                if (state?.context?.error)
                  send({
                    type: 'RESET_ERROR',
                  });
              }}
            />
            <div className="invalid-feedback">{state?.context?.error}</div>
          </div>

          <div
            className={`modal-footer ${
              hideMobileKeyboard ? 'no-mobile-keyboard' : ''
            }`}
          >
            <div
              className="btn btn-primary"
              role="button"
              onClick={() => {
                const newEmail = `${emailInputRef.current.value}`.toLowerCase();
                send({ type: 'SUBMIT', payload: { email: newEmail } });
              }}
            >
              {t(`Next`)}
            </div>

            {/*             <div className="mt-4 mb-1 text-center text-uppercase text-muted">
              {t('Or')}
            </div>
 */}
            {!hideSocialLogin && (
              <div className="mt-5 w-100">
                {isGoogleSignInSupported && <GoogleSignInButton />}
                {isAppleSignInSupported && <AppleSignInButton />}
                {isTelegramSignInSupported && <TelegramSignInButton />}
              </div>
            )}

            <div className="mt-3 mb-3 text-center">
              {onLogin && (
                <>
                  <small className="text-muted">
                    {t('Already registered?')}
                  </small>
                  {'  '}
                  <small
                    className="text-primary btn-link"
                    role="button"
                    onClick={() => {
                      const newEmail =
                        `${emailInputRef.current.value}`.toLowerCase();
                      onLogin(newEmail === '' ? 'empty_email' : newEmail);
                    }}
                  >
                    {t(`Login`)}
                  </small>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </ModalLayout>
  );
}

function EnterPasswordStep({ state, send, onClose, onLogin }) {
  const passwordInputRef = useRef();
  const logger = useLoggerContext();
  return (
    <>
      <ModalLayout
        onClose={onClose}
        onBack={() => {
          send({ type: 'BACK' });
        }}
      >
        <div className="modal-header">
          <h1>{t(`Create password`)}</h1>
          <div
            className="text-default"
            style={{
              whiteSpace: 'nowrap',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {t(`E-mail address`)} <b>{state?.context?.email}</b>
          </div>
        </div>
        <div className="modal-body">
          <input
            style={{ lineHeight: '50px', verticalAlign: 'top' }}
            ref={passwordInputRef}
            type="password"
            id="typePassword"
            placeholder={t('Password')}
            defaultValue={state?.context?.password}
            onChange={() => {
              send({
                type: 'RESET_ERROR',
              });
            }}
          />
          <div className="invalid-feedback">{state?.context?.error}</div>
        </div>
        <div className="modal-footer">
          <div
            className="btn btn-primary"
            role="button"
            onClick={() => {
              logger?.event('click', {
                target: 'verifyEmail',
                email: state?.context?.email,
              });
              const newPassword = passwordInputRef.current.value;
              send({ type: 'SUBMIT', payload: { password: newPassword } });
            }}
          >
            {t(`Next`)}
          </div>

          <div className="mt-3 text-center">
            {!!onLogin && (
              <>
                <small className="text-muted">{t('Already registered?')}</small>
                {'  '}
                <small
                  className="text-primary btn-link"
                  role="button"
                  onClick={() => {
                    onLogin(state?.context?.email);
                  }}
                >
                  {t(`Login`)}
                </small>
              </>
            )}
          </div>
        </div>
      </ModalLayout>
    </>
  );
}

function EnterCodeStep({ state, send, onClose }) {
  const codeInputRef = useRef();
  const { version, openInbox } = useNativeAppContext();
  const logger = useLoggerContext();

  const { stopMusic } = useSoundContext();
  useEffect(() => {
    stopMusic();
  }, [stopMusic]);
  return (
    <>
      <ModalLayout
        onClose={null}
        onBack={() => {
          send({ type: 'BACK' });
        }}
      >
        <div className="modal-header">
          <h1>{t(`Confirm e-mail`)}</h1>
          <div
            className="text-default"
            style={{
              whiteSpace: 'nowrap',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {t(`We have sent email to`)} <b>{state?.context?.email}</b>
          </div>
        </div>

        <div className="modal-body">
          <img
            src="/img/letter.svg"
            alt=""
            style={{
              display: 'block',
              margin: '3vh auto',
            }}
          />
          <div className="text-default text-center">
            {t('Follow instructions in your email to verify your account')}
          </div>

          <div className="text-center">
            <br />
            <small className="text-muted">
              {t('Did not receive email?')} <br />
              {t('You may need to check you spam folder')}
              <br />
              {Date.now() - state?.context?.sendTime < 60 * 1000
                ? ` ${t('Resend in {{time}} sec', {
                    time: Math.max(
                      0,
                      60 - (Date.now() - state?.context?.sendTime) / 1000
                    ).toFixed(0),
                  })}`
                : ''}
            </small>
            <div
              role="button"
              className={`btn btn-link text-muted text-default${
                Date.now() - state?.context?.sendTime < 60000
                  ? ' invisible'
                  : ''
              }`}
              onClick={() => {
                send({
                  type: 'RESEND',
                });
              }}
            >
              <small>{t(`Send again`)}</small>
            </div>
          </div>
        </div>
        <div className="modal-footer no-mobile-keyboard">
          {isNativeApp() && compareVersions(version, '1.8.0') >= 0 && (
            <div
              className="btn btn-primary"
              role="button"
              onClick={() => {
                logger?.event('click', { target: 'OpenEmailApp' });
                openInbox();
              }}
            >
              {t(`Open Email App`)}
            </div>
          )}
        </div>
      </ModalLayout>
    </>
  );
}

function SigninWithTelegramStep({ onClose }) {
  const { user: tgUser } = useTelegramContext();
  return (
    <>
      {isTelegram() && (
        <ModalLayout onClose={onClose}>
          <div className="modal-header">
            <h1>{t(`Sign In`)}</h1>
          </div>

          <div className="modal-body">
            <div
              style={{
                position: 'relative',
                left: '50%',
                top: '40%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <img
                src="/img/btn-telegram.svg"
                alt=""
                style={{
                  width: '30vw',
                  display: 'block',
                  margin: '3vh auto',
                }}
              />
              <div className="text-default text-center">
                {tgUser?.first_name}
              </div>
              <div className="text-muted text-center">@{tgUser?.username}</div>
            </div>
          </div>

          <div className="modal-footer no-mobile-keyboard">
            <TelegramSignInButton />
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function SignInWithTelegram({
  onLoginNavigate,
  onSuccessSignUp,
  onSuccessLogin,
}) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const logger = useLoggerContext();
  const { signIn } = useTelegramContext();

  useEffect(() => {
    const init = async () => {
      const { user, isNewUser, error } = await signIn();
      if (!!error) setError(error);
      else if (isNewUser) onSuccessSignUp(user?.email);
      else onSuccessLogin(user?.email);
      setIsLoading(false);
    };
    init();
  }, []);

  useEffect(() => {
    if (error) logger.event('error', { msg: `SignInWithApple: ${error}` });
  }, [error]);

  return (
    <>
      {isLoading && <Loader overlay />}

      {error && (
        <SignUpVerificationErrorStep
          error={error}
          onLoginNavigate={onLoginNavigate}
        />
      )}
    </>
  );
}

function SignInWithApple({ onLoginNavigate, onSuccessSignUp, onSuccessLogin }) {
  const [params] = useSearchParams();
  const [error, setError] = useState(
    params.get('error') ? atob(params.get('error')) : null
  );
  const [isLoading, setIsLoading] = useState(true);

  const authorization = params.get('authorization')
    ? tryToParseJSON(atob(params.get('authorization')))
    : null;
  const redirectUri = params.get('redirectUri')
    ? atob(params.get('redirectUri'))
    : null;

  const logger = useLoggerContext();

  useEffect(() => {
    const init = async () => {
      if (authorization && redirectUri) {
        const resp = await signInWithApple(authorization, redirectUri);
        if (resp?.success)
          if (resp?.isNewUser) onSuccessSignUp(resp?.email);
          else onSuccessLogin(resp?.email);
        if (resp?.error) setError(JSON.stringify(resp));
      } else if (!error) setError('Missing authorization parameters');
      setIsLoading(false);
    };
    init();
  }, []);

  useEffect(() => {
    if (error) logger.event('error', { msg: `SignInWithApple: ${error}` });
  }, [error]);

  return (
    <>
      {isLoading && <Loader overlay />}

      {error && (
        <SignUpVerificationErrorStep
          error={error}
          onLoginNavigate={onLoginNavigate}
        />
      )}
    </>
  );
}

function SignInWithGoogle({
  onLoginNavigate,
  onSuccessLogin,
  onSuccessSignUp,
}) {
  const logger = useLoggerContext();
  const [params] = useSearchParams();
  const code = params.get('code');
  const [error, setError] = useState(params.get('error'));
  const [isLoading, setIsLoading] = useState(true);
  const nativeApp = useNativeAppContext();
  const redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  useEffect(() => {
    const init = async () => {
      if (code) {
        const resp = await signInWithGoogle(code, redirectUri);
        nativeApp.closeWebBrowser();
        if (resp?.success) {
          if (resp?.isNewUser) {
            onSuccessSignUp(resp?.email);
          } else onSuccessLogin(resp?.email);
        } else if (resp?.error) {
          setError(resp?.error);
        }
      }
      setIsLoading(false);
    };
    init();
  }, []);

  useEffect(() => {
    if (error) logger.event('error', { msg: `SignInWithGoogle: ${error}` });
  }, [error]);

  return (
    <>
      {isLoading && <Loader overlay />}

      {error && (
        <SignUpVerificationErrorStep
          error={error}
          onLoginNavigate={onLoginNavigate}
        />
      )}
    </>
  );
}

/*
function SuccessStep({ bonus, onClose }) {
  return (
    <ModalLayout>
      <div className="modal-header">
        <h1 className="text-primary-accent text-center">{t(`Congrats!`)}</h1>
        <div className="text-muted text-center">
          {t(`Your account is successfully created`)}
        </div>

        <img
          src="/img/success.svg"
          alt=""
          style={{
            display: 'block',
            margin: '3vh auto',
          }}
        />

        <div className="text-default text-center">
          {t('A bonus will be credited to your balance')}
          {': '}
          <span className="text-primary-accent">{bonus.toFixed(2)}</span>
        </div>
      </div>
      <div className="modal-body"></div>
      <div className="modal-footer justify-content-center">
        <div
          className="btn btn-primary btn-flashing"
          role="button"
          onClick={() => {
            onClose();
          }}
        >
          {t(`Continue`)}
        </div>
      </div>
    </ModalLayout>
  );
}
*/

function EnterCredentialsStep({ state, send, onClose, onReset, onSignUp }) {
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const logger = useLoggerContext();

  const {
    isGoogleSignInSupported,
    isAppleSignInSupported,
    isTelegramSignInSupported,
  } = useSignIn();

  const [params] = useSearchParams();
  const email_ = params.get('email') ? atob(params.get('email')) : null;
  const defaultEmail = email_ === 'empty_email' ? '' : email_;
  const [hideEmail, setHideEmail] = useState(defaultEmail === null);
  const [hideMobileKeyboard, setHideMobileKeyboard] = useState(true);

  return (
    <>
      <ModalLayout onClose={onClose}>
        {hideEmail && (
          <div className="modal-header text-center">
            <h1>{t(`Login`)}</h1>

            <div className="text-default">{t('Welcome back')}</div>
          </div>
        )}
        {!hideEmail && (
          <div className="modal-header">
            <h1>{t(`Login`)}</h1>
            <div className="text-default">{t('Continue with Email')}</div>
          </div>
        )}
        <div className="modal-body">
          {hideEmail && (
            <img
              src={'/img/fox/welcome.png'}
              style={{
                //height: '50vh',
                //height: '50svh',
                width: '110%',
                left: '50%',
                position: 'absolute',
                transform: 'translate(-50%, 0%)',
              }}
            />
          )}

          {!hideEmail && (
            <>
              <input
                ref={emailInputRef}
                type="email"
                id="typeEmail"
                placeholder={t('E-mail address')}
                onFocus={() =>
                  (isIos() || isAndroid()) &&
                  setTimeout(() => setHideMobileKeyboard(false), 800)
                }
                onBlur={() =>
                  (isIos() || isAndroid()) &&
                  setTimeout(() => setHideMobileKeyboard(true), 400)
                }
                defaultValue={defaultEmail}
                onChange={() => {
                  send({
                    type: 'RESET_ERROR',
                  });
                }}
              />

              <input
                style={{ lineHeight: '50px', verticalAlign: 'top' }}
                ref={passwordInputRef}
                type="password"
                id="typePassword"
                placeholder={t('Password')}
                onFocus={() =>
                  (isIos() || isAndroid()) &&
                  setTimeout(() => setHideMobileKeyboard(false), 800)
                }
                onBlur={() =>
                  (isIos() || isAndroid()) &&
                  setTimeout(() => setHideMobileKeyboard(true), 400)
                }
                onChange={() => {
                  send({
                    type: 'RESET_ERROR',
                  });
                }}
              />
              <div className="invalid-feedback">{state?.context?.error}</div>
              <div className="mt-3 mb-3 text-center">
                <small
                  className="text-primary btn-link"
                  role="button"
                  onClick={() => {
                    onReset();
                  }}
                >
                  {t(`Forgot password?`)}
                </small>
              </div>
            </>
          )}
        </div>
        <div
          className={`modal-footer ${
            hideMobileKeyboard ? 'no-mobile-keyboard' : ''
          }`}
        >
          {!hideEmail && (
            <>
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => {
                  const newPassword = passwordInputRef.current.value;
                  const newEmail =
                    `${emailInputRef.current.value}`.toLowerCase();
                  send({
                    type: 'SUBMIT',
                    payload: { email: newEmail, password: newPassword },
                  });
                  logger?.event('click', { target: 'login', email: newEmail });
                }}
              >
                {t(`Next`)}
              </div>

              {/*               <div className="mt-4 mb-1 text-center text-uppercase text-muted">
                {t('Or')}
              </div>
 */}
              <div className="mt-5 w-100">
                {isGoogleSignInSupported && <GoogleSignInButton />}
                {isAppleSignInSupported && <AppleSignInButton />}
                {isTelegramSignInSupported && <TelegramSignInButton />}
              </div>
            </>
          )}

          {hideEmail && (
            <div className="w-100">
              {isGoogleSignInSupported && <GoogleSignInButton />}
              {isAppleSignInSupported && <AppleSignInButton />}
              {isTelegramSignInSupported && <TelegramSignInButton />}
              <div
                className="btn btn-secondary mt-2"
                role="button"
                onClick={() => {
                  setHideEmail(false);
                  //setTimeout(() => emailInputRef?.current?.focus(), 500);
                }}
              >
                <BtnIcon src="/img/email.svg" />
                {t(`Continue with Email`)}
              </div>
            </div>
          )}
          <div className="mt-3 mb-3 text-center">
            <small className="text-muted">{t('Have no account?')}</small>
            {'  '}
            <small
              className="text-primary btn-link"
              role="button"
              onClick={() => {
                onSignUp();
              }}
            >
              {t(`Register`)}
            </small>
          </div>
        </div>
      </ModalLayout>
    </>
  );
}
