import { t } from 'i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DotCoin } from '../components/terminal';
import Header from '../shared/header';
import { useInterval } from '../shared/hooks/use-interval';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import { ModalLayout } from '../shared/layout';
import { priceFmt } from '../shared/utils';

export function Loader({ onClick, overlay, onFallback, redirect = '' }) {
  const location = useLocation();
  const navigate = useNavigate();
  const logger = useLoggerContext();
  const [allowFallback, setAllowFallback] = useState(false);
  useEffect(() => {
    redirect &&
      setTimeout(
        () =>
          location.pathname === window.location.pathname &&
          navigate(redirect, { replace: true }),
        1000
      );

    setTimeout(() => setAllowFallback(true), 4000);
  }, []);

  return (
    <div
      className={`loader ${overlay ? 'overlay' : ''}`}
      onClick={() => {
        if (onClick) {
          logger?.event('click', {
            target: 'loader',
            location: location.pathname,
          });
          onClick();
        } else if (allowFallback) {
          logger?.event('click', {
            target: 'loaderFallback',
            location: location.pathname,
          });
          if (onFallback) onFallback();
          else navigate('/app', { replace: true });
        }
      }}
    />
  );
}

function ErrorText({ text, ...props }) {
  return (
    <div
      {...props}
      style={{
        display: 'inline-block',
        backgroundColor: 'var(--theme-red-transparent)',
        borderRadius: '20px',
      }}
    >
      <div className="text-center text-red" style={{ margin: '8px 12px' }}>
        {/* <img
          src="/img/i.svg"
          alt=""
          style={{
            marginTop: '-4px',
            marginRight: '6px',
            height: '26px',
          }}
        /> */}
        <small>{text}</small>
      </div>
    </div>
  );
}

export function ConnectionLoader({
  price = null,
  centralPoint,
  asset = 'Bitcoin',
  onConnected,
  onConnecting,
  skipSplash = false,
}) {
  const nativeApp = useNativeAppContext();
  const logger = useLoggerContext();
  const [step, setStep] = useState(skipSplash ? 2 : 0);
  // useEffect(() => {
  //   if (step === 0) setTimeout(() => setStep(1), 500);
  //   if (step === 1)
  //     setTimeout(
  //       () => setStep(!price ? 0 : 2),
  //       logger?.visits <= 10 ? 1800 : 800
  //     );
  //   // (step === 2) continue button click see below
  //   if (step === 3) setTimeout(() => setStep(4), 800);
  //   if (step === 4) onConnected && onConnected();
  //   else onConnecting && onConnecting();
  // }, [step]);

  const [lastPriceUpdate, setLastPriceUpdate] = useState(Date.now());
  useEffect(() => {
    if (price && !isNaN(price)) setLastPriceUpdate(Date.now());
  }, [price]);

  const [noConnection, setNoConnection] = useState(false);

  useEffect(() => {
    if (noConnection)
      logger?.event('error', { connection: 'timeout', price, lastPriceUpdate });
  }, [noConnection]);

  useInterval(
    () => {
      if (step === 0) setStep(1);
      if (step === 1) setStep(!price || isNaN(price) ? 0 : 2);
      // (step === 2) continue button click see below
      if (step === 3) {
        setTimeout(() => {
          setStep(4);
          onConnected && onConnected();
        }, 500);
      }
      if (step < 4) onConnecting && onConnecting();

      if (Date.now() - lastPriceUpdate > 3000) setNoConnection(true);
      else if (noConnection) setNoConnection(false);
    },
    step <= 3 ? 500 : null
  );

  const navigate = useNavigate();
  return (
    <>
      <Header
        title="Market price"
        description={`Track market data in real time`}
      />

      {step < 4 && (
        <div className={`connection-loader ${step < 3 ? 'overlay' : ''}`}>
          {step <= 2 && (
            <div>
              <ModalLayout onClose={null} onBack={null}>
                <div className="modal-header">
                  <h1 className="text-center text-default">
                    {noConnection
                      ? t('No connection')
                      : `${t('Connecting')}...`}
                  </h1>
                </div>
                <div className="modal-body no-scroll">
                  <img
                    src={
                      '/img/fox/calling.png'
                      /*  TODO - No image offlie. Preload is not always working.
                        noConnection
                        ? '/img/fox/calling-no-connection.png'
                        : '/img/fox/calling.png'
                      */
                    }
                    style={{
                      height: '60vh',
                      //height: '60svh',
                      width: '60vh',
                      //width: '60svh',
                      left: '50%',
                      position: 'absolute',
                      transform: 'translateX(-50%)',
                    }}
                  />
                </div>

                <div className="modal-footer no-mobile-keyboard">
                  {noConnection && (
                    <>
                      <ErrorText
                        text={t('Check your internet connection')}
                        onClick={() => {
                          //setLastPriceUpdate(Date.now());
                          //setNoConnection(false);
                          nativeApp.haptics('success');
                          //onReconnect && onReconnect();
                          logger?.event('click', {
                            target: 'TryToReconnect',
                            price,
                            now: Date.now(),
                            lastPriceUpdate,
                          });
                          //window.location.reload();
                          navigate('/home', { replace: true });
                        }}
                      />
                      {/*                       <div
                        className="btn btn-secondary"
                        role="button"
                        onClick={() => {
                          setLastPriceUpdate(Date.now());
                          setNoConnection(false);
                          nativeApp.haptics('success');
                          onReconnect && onReconnect();
                          logger?.event('click', { target: 'TryToReconnect' });
                        }}
                      >
                        {t(`Try again`)}
                      </div>
 */}{' '}
                    </>
                  )}

                  <div className="text-center" style={{ flexBasis: '100%' }}>
                    <small className="text-muted mt-3">
                      Binance • Coinbase • Gate • OKx • Bybit
                    </small>
                  </div>
                </div>
              </ModalLayout>
            </div>
          )}

          {/* 2: wait for button, 3: transition to app */}
          {(step === 2 || step === 3) && price && (
            <>
              <ModalLayout
                hideNavigation
                onClose={null}
                onBack={null}
                isTransparent={step >= 3 ? true : false}
              >
                <div className="modal-header">
                  {/* <h1
                    className="text-center text-default"
                    style={{
                      position: 'absolute',
                      left: '50%',
                      width: '80%',
                      transform: 'translateX(-50%)',
                      top: '30px',
                    }}
                  >
                    {step <= 2 && `${t('Predict the next move')}`}
                  </h1> */}
                </div>
                <div
                  className="modal-body no-scroll"
                  onTouchStart={(e) => {
                    //4 fingers
                    if (e.touches['3']) {
                      window.location.href = window.location.href;
                    }
                  }}
                >
                  {step === 2 && (
                    <div
                      className="text-center text-default text-nowrap"
                      style={{
                        fontWeight: 500,
                        position: 'absolute',
                        left: step === 2 ? centralPoint.x : 0,
                        top: step === 2 ? centralPoint.y : 0,
                        transform:
                          step === 2
                            ? `translate(-40%, calc(-25px - ${centralPoint.y}px + 40vh)) scale(2.5)`
                            : `translate(12px, 16px) scale(1)`,
                        transition:
                          'left 0.5s ease-in-out, top 0.5s ease-in-out, transform 0.5s ease-in-out',
                      }}
                    >
                      {asset}
                      <img
                        src="/img/btc.svg"
                        alt=""
                        style={{
                          width: '18px',
                          marginTop: '-3px',
                          marginLeft: '4px',
                          marginRight: '4px',
                          opacity: 0,
                        }}
                      />
                    </div>
                  )}

                  <div
                    className="text-center text-default text-nowrap"
                    style={{
                      position: 'absolute',
                      left: centralPoint.x,
                      top: centralPoint.y,
                      transform:
                        step === 2
                          ? `translate(170px, calc(85px - ${centralPoint.y}px + 40vh)) scale(5)`
                          : `translate(0, 70px)`,
                      transition:
                        'left 0.5s ease-in-out, top 0.5s ease-in-out, transform 0.5s ease-in-out',
                    }}
                  >
                    <DotCoin />
                  </div>

                  <div
                    className="text-center"
                    style={{
                      position: 'absolute',
                      left: centralPoint.x,
                      top: centralPoint.y,
                      transform:
                        step === 2
                          ? `translate(-50%, calc(40px - ${centralPoint.y}px + 40vh)) `
                          : `translate(calc(-50% - 1.5px), 79.5px)`,
                      transition: 'all 0.5s ease-in-out',
                    }}
                  >
                    <div
                      style={{
                        transform:
                          step === 2
                            ? `translateY(-20px) scale(3)`
                            : `translate(1px, -3px) scale(1)`,
                        transition: 'all 0.5s ease-in-out',
                      }}
                    >
                      <small
                        className="text-muted"
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        ${priceFmt(price, 2, 2)}
                      </small>
                    </div>

                    {step === 2 && (
                      <small className="text-uppercase text-default">
                        {t('Current real-time price')}
                      </small>
                    )}
                  </div>
                </div>

                {step === 2 && (
                  <div className="modal-footer no-mobile-keyboard">
                    <div
                      className="btn btn-primary"
                      role="button"
                      onClick={() => {
                        setStep(3);
                        nativeApp.haptics('success');
                      }}
                    >
                      {/* {t(`Accept challenge`)} */}
                      {t(`Continue`)}
                    </div>
                  </div>
                )}
              </ModalLayout>
            </>
          )}
        </div>
      )}
    </>
  );
}
