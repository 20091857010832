import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { useLocalStorage } from './use-hooks';
import { useRef } from 'react';
import { createContext } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useLoggerContext } from './use-logger';
import { useLocation } from 'react-router-dom';

const Sound = createContext();
export function SoundContext({ children }) {
  const sound = useSound();
  const persistedSound = useMemo(() => sound, [sound]);
  return <Sound.Provider value={persistedSound}>{children}</Sound.Provider>;
}
export const useSoundContext = () => useContext(Sound);

const emptyFunction = () => {};
const emptySounds = {
  hits: emptyFunction,
  html5Click: emptyFunction,
  music: emptyFunction,
  normalClick: emptyFunction,
  wetClick: emptyFunction,
  highClick: emptyFunction,
  levelUp: emptyFunction,
  success: emptyFunction,
  fail: emptyFunction,
  bonus: emptyFunction,
  win: emptyFunction,
};

function useSound() {
  const logger = useLoggerContext();

  const [isInited, setIsInited] = useState(false);
  const [sounds, setSounds] = useState(emptySounds);
  const [enableSonuds] = useLocalStorage('enable-sounds', true);
  const [enableMusic] = useLocalStorage('enable-music', true);
  const [isEnabledHtml5, setIsEnabledHtml5] = useState(null);
  const [music, setMusic] = useState(null);
  const [musicGame, setMusicGame] = useState(null);
  const [musicLobby, setMusicLobby] = useState(null);
  const [musicRelax, setMusicRelax] = useState(null);
  const [musicPower, setMusicPower] = useState(null);

  const refEnableMusic = useRef();
  refEnableMusic.current = enableMusic;

  const refEnableSonuds = useRef();
  refEnableSonuds.current = enableSonuds;

  function init(
    mp3,
    volume = 1,
    rate = 1,
    html5 = false,
    loop = false,
    name = ''
  ) {
    const s = new Howl({
      src: [mp3],
      autoplay: false,
      preload: true,
      loop: loop,
      html5,
      rate,
      volume: volume,
    });
    if (name === 'game') setMusicGame(s);
    if (name === 'game') setMusic(s);
    if (name === 'lobby') setMusicLobby(s);
    if (name === 'relax') setMusicRelax(s);
    if (name === 'power') setMusicPower(s);
    return () => {
      if (!refEnableSonuds.current) return;
      try {
        if (html5) setIsEnabledHtml5(s);
        s && s.play();
      } catch {
        if (process.env.NODE_ENV !== 'production')
          alert('failed to start audio');
      }
    };
  }

  function initHits() {
    const hitDuration = 160;
    const hits = new Howl({
      src: ['/sounds/hits.mp3'],
      autoplay: false,
      html5: false,
      preload: true,
      sprite: {
        hit1: [0, hitDuration],
        hit2: [342, hitDuration],
        hit3: [645, hitDuration],
        hit4: [1110, hitDuration],
        hit5: [1474, hitDuration],
      },
      volume: 0.7,
      rate: 2,
    });
    return (i) => {
      if (!refEnableSonuds.current) return;
      if (enableSonuds && i <= 8) hits.play(`hit${i}`);
    };
  }

  function initWins() {
    const wins = [
      init('/sounds/win1.mp3', 0.4, 1),
      init('/sounds/win2.mp3', 0.4, 1),
      init('/sounds/win3.mp3', 0.4, 1),
    ];
    return (i) => {
      if (i >= 0 && i <= 2) wins[i]();
    };
  }

  useEffect(() => {
    if (isInited) return;

    try {
      const newSounds = {
        hits: initHits(),
        html5Click: init('/sounds/silence.mp3', 0.7, 1, true),
        musicGame: init('/sounds/music-game.mp3', 0, 1, false, true, 'game'),
        musicLobby: init('/sounds/music-lobby.mp3', 0, 1, false, true, 'lobby'),
        musicRelax: init('/sounds/music-relax.mp3', 0, 1, false, true, 'relax'),
        musicPower: init('/sounds/music-power.mp3', 0, 1, false, true, 'power'),
        normalClick: init('/sounds/normal-click.mp3', 0.5, 1),
        wetClick: init('/sounds/wet-click.mp3', 0.6, 1),
        highClick: init('/sounds/high-click.mp3'),
        levelUp: init('/sounds/level-up.mp3', 0.3, 1),
        bonus: init('/sounds/bonus.mp3', 0.5, 1),
        success: init('/sounds/success.mp3', 1, 1),
        fail: init('/sounds/fail.mp3', 1, 1),
        notification: init('/sounds/notification.mp3', 0.2, 1),
        win: initWins(),
      };
      setSounds(newSounds);
      setIsInited(true);
    } catch (e) {
      //setSounds(emptySounds);
      //setIsInited(false);
      logger.event('error', { msg: 'Can not init sounds', error: e.message });
      //if (process.env.NODE_ENV !== 'production') alert('sounds init error');
    }
  }, [isInited]);

  //Track pathname change
  const location = useLocation();
  useEffect(() => {
    if (!musicGame) return;
    if (!musicLobby) return;

    if (music) {
      //isEnabledHtml5
      //const enableMusic = localStorage.getItem('enable-music');
      if (!refEnableMusic.current) {
        music.pause();
      } else {
        const pathname = location.pathname;

        const newMusic =
          pathname.replace(/\/+$/, '') === '/app'
            ? musicGame
            : pathname.indexOf('app/league') >= 0 ||
              pathname.indexOf('app/leader  board') >= 0
            ? musicPower
            : pathname.indexOf('app/bonus/welcome') >= 0 ||
              pathname.indexOf('app/bonus/push') >= 0
            ? musicRelax
            : pathname.indexOf('claim') >= 0
            ? music
            : pathname.indexOf('app/auth/') >= 0
            ? pathname.indexOf('app/auth/success') >= 0
              ? musicRelax
              : musicLobby
            : music;

        if (newMusic !== music) {
          const v = music.volume();
          music.fade(v, 0, 2500);
          //setTimeout(() => music.stop(), 2500);
          setMusic(newMusic);
        }

        if (!newMusic.playing()) {
          newMusic.play();
          newMusic.fade(0, 0.2, 2000);
        } else {
          //alert('volume');
          const v = newMusic.volume();
          if (location.pathname.replace(/\/+$/, '') === '/app') {
            newMusic.fade(v, 0.2, 5000);
          } else {
            newMusic.fade(v, 0.4, 5000);
          }
        }
      }
    }
  }, [location.pathname, localStorage.getItem('enable-music')]);
  //}, [location.pathname, refEnableMusic.current]);

  const stopMusic = () => {
    const v = music.volume();
    music.fade(v, 0, 3000);
    setTimeout(() => music.stop(), 3000);
  };

  return { ...sounds, stopMusic };
}
