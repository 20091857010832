import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Subscription from './pages/subscription';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { register, update } from './serviceWorkerRegistration';
import { UserContext } from './shared/hooks/use-user';
import { LoggerContext } from './shared/hooks/use-logger';
import { UpdaterContext } from './shared/hooks/use-updater';
import { BonusesContext } from './shared/hooks/use-bonus';
import { AdsenseContext } from './shared/hooks/use-adsense';
import { MonetagContext } from './shared/hooks/use-monetag';
import { NativeAppContext } from './shared/hooks/use-native-app';
import Ref from './pages/ref';
import { TelegramContext } from './shared/hooks/use-telegram';
import Download from './pages/download';
import { AnalyticsContext } from './shared/hooks/use-analytics';
import { AdsContext } from './shared/hooks/use-ads';
import { AdMobContext } from './shared/hooks/use-admob';
import { AppodealContext } from './shared/hooks/use-appodeal';

i18next.use(LanguageDetector).init({
  resources: {
    en: {
      translation: require('./locales/en.json'),
    },
    es: {
      translation: require('./locales/es.json'),
    },
    de: {
      translation: require('./locales/de.json'),
    },
    pt: {
      translation: require('./locales/pt.json'),
    },
    fr: {
      translation: require('./locales/fr.json'),
    },
    tr: {
      translation: require('./locales/tr.json'),
    },
    ru: {
      translation: require('./locales/ru.json'),
    },
  },
});

document.documentElement.lang = i18next.language;

const router = createBrowserRouter([
  {
    path: 'subscription',
    element: <Subscription />,
  },
  {
    path: 'link',
    element: (
      <>
        {process.env.NODE_ENV === 'production' ? (
          <div />
        ) : (
          <div className="text-default">
            <div className="m-3">
              <a target="_blank" href="https://boxo.trade/app/bonus/">
                https://boxo.trade/app/bonus/
              </a>
            </div>
            <div className="m-3">
              <a href="https://boxo.trade/test?message=123">
                https://boxo.trade/test?message=123
              </a>
            </div>
            <div className="m-3">
              <a href="boxo://app/auth/google">boxo://app/auth/google</a>
            </div>
            <div className="m-3">
              <a href="boxoapp://app/auth/google">boxoapp://app/auth/google</a>
            </div>
            <div className="m-3">
              <a href="trade.boxo://app">trade.boxo://app</a>
            </div>
            <div className="m-3">
              <a href="boxo.trade://app">boxo.trade://app</a>
            </div>
            <div className="m-3">
              <a href="https://webclient-git-native-box-options.vercel.app/app">
                https://webclient-git-native-box-options.vercel.app/app
              </a>
            </div>
            <div className="m-3">
              <a href="exp://172.20.10.8:8082">exp://172.20.10.8:8082</a>
            </div>
          </div>
        )}
      </>
    ),
  },
  {
    path: 'subscription/:email',
    element: <Subscription />,
  },
  {
    path: 'ref/:target',
    element: (
      <LoggerContext>
        <Ref />
      </LoggerContext>
    ),
  },
  {
    path: 'download',
    element: (
      <LoggerContext>
        <Download />
      </LoggerContext>
    ),
  },
  {
    path: '*',
    element: (
      <LoggerContext>
        <TelegramContext>
          <NativeAppContext>
            <AnalyticsContext>
              <UpdaterContext>
                <AdMobContext>
                  <AppodealContext>
                    <AdsContext>
                      <AdsenseContext>
                        <MonetagContext>
                          <UserContext>
                            <BonusesContext>
                              <App />
                            </BonusesContext>
                          </UserContext>
                        </MonetagContext>
                      </AdsenseContext>
                    </AdsContext>
                  </AppodealContext>
                </AdMobContext>
              </UpdaterContext>
            </AnalyticsContext>
          </NativeAppContext>
        </TelegramContext>
      </LoggerContext>
    ),
  },
]);

{
  /* <iframe
        style={{
          width: '100%',
          height: '100vh',
        }}
      > 
  */
}

if (process.env.NODE_ENV === 'production') console.log = function () {};

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, <RouterProvider router={router} />);
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<RouterProvider router={router} />);
  register();
  update();
}
