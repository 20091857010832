import { t } from 'i18next';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnIcon } from '../components/terminal';
import Header from '../shared/header';
import { useAnalyticsContext } from '../shared/hooks/use-analytics';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import { useSoundContext } from '../shared/hooks/use-sound';
import { useUserContext } from '../shared/hooks/use-user';
import {
  isInAppBrowser,
  isAndroid,
  isNativeApp,
  isIos,
  isTelegram,
} from '../shared/utils';
import { loadPersistentState } from '../shared/utils/persist';
import { InstallDialog } from './install';

export default function Landing({ isLandingForAds = false }) {
  const showStoreLanding = isLandingForAds;

  const logger = useLoggerContext();
  const navigate = useNavigate();
  const { user, isLogged } = useUserContext();
  const { track, setUser: analyticsSetUser } = useAnalyticsContext();
  const { normalClick, html5Click } = useSoundContext();
  const [isCanvasInited, setIsCanvasInited] = useState(false);
  const [isInstallShowing, setIsInstallShowing] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    const height = window.screen.availHeight;
    const width = window.screen.availWidth;
    if (isTelegram() && width > height && window.location.pathname === '/home')
      navigate('/', { replace: true });

    window.Telegram?.WebApp?.disableVerticalSwipes();

    // Initialising the canvas
    const canvas = document.querySelector('canvas');
    const ctx = canvas && canvas.getContext('2d');

    // Setting the width and height of the canvas
    canvas.width = canvas.clientWidth; // window.innerWidth;
    canvas.height = canvas.clientHeight; //window.innerHeight;

    // Setting up the letters
    let letters = '1234567890';
    letters = letters.split('');

    // Setting up the columns
    var fontSize = 13,
      columns = canvas.clientWidth / fontSize;

    // Setting up the drops
    var drops = [];
    for (var i = 0; i < columns; i++) {
      //      drops[i] = 1;
      drops[i] = (Math.random() * canvas.clientHeight) / fontSize;
    }

    // Setting up the draw function
    function draw() {
      ctx.fillStyle = 'rgba(33, 43, 52, 0.7)'; //'rgba(0, 0, 0, .1)'; //'#212b34';
      ctx.fillRect(0, 0, canvas.clientWidth, canvas.clientHeight);

      for (var i = 0; i < drops.length; i++) {
        var text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillStyle = '#5bb576'; //'#0f0';
        ctx.font = `normal 100 ${fontSize}px sans-serif`;
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        drops[i]--;
        if (drops[i] <= 0 && Math.random() > 0.5) {
          drops[i] = drops[i] = canvas.clientHeight / fontSize;
        }
      }
    }

    if (!isCanvasInited) {
      // Loop the animation
      setInterval(draw, 200);
      setIsCanvasInited(true);
    }

    if (isAndroid() && isInAppBrowser()) {
      setIsInstallShowing(true);
    }

    //Heartbeat
    /*
     const logHeartbeat = (next = 2000) => {
      if (next <= 18000)
        setTimeout(() => {
          logger?.event('landing', {
            waiting: Math.round(next / 1000),
          });
          logHeartbeat(next + 4000);
        }, next);
    };
    logHeartbeat();
    */
  }, []);

  const nativeApp = useNativeAppContext();
  const settings = loadPersistentState('settings');
  const debug_href = `${settings?.debug_native_app_href}/home`;

  const lng = window.localStorage.getItem('i18nextLng');
  const lang = lng ? lng.substring(0, 2) : 'En';

  return (
    <>
      <Header
        title="Start Stock Market Simulator"
        description={`Welcome To the Boxo Game! Play now!`}
      />

      <div className="row---">
        {/* <div className="col-6">asdf</div> */}
        <div className="col---">
          <div
            className="landing"
            onTouchStart={(e) => {
              /*         if (e.touches['1']) {
          //2 fingers touch
          nativeApp.signOut('google');
        }
 */
              if (e.touches['2']) {
                //3 fingers touch
                //track('ChallengeBonus600');
              }

              if (e.touches['3']) {
                //4 fingers touch
                nativeApp.updateVersion();
                if (isNativeApp() || isTelegram()) {
                  window.location.href = !debug_href
                    ? window.location.href
                    : debug_href;
                }
              }
            }}
            onDoubleClick={(e) => {
              if (window.location.href === debug_href) {
                //nativeApp.signIn('google');
                //nativeApp.getPushNotifications(true);
                //nativeApp.alert('Hi from pwa');
                //nativeApp.registerPushNotifications();
                //nativeApp.alert('Hi from pwa');
                //nativeApp.vibrate(2000);
                //nativeApp.haptics('warning');
                //nativeApp.showAdMob();
              }
              if (process.env.NODE_ENV !== 'production') {
                //nativeApp.requestReview();
                //alert('req');
                return;

                nativeApp.requestTrackingPermissions();

                //if (nativeApp.trackingPermissionsStatus !== null);
                alert(
                  'trackingPermissionsStatus=' +
                    nativeApp.trackingPermissionsStatus
                );
                nativeApp.initAppodeal();
                //nativeApp.showIronSource();
                //nativeApp.initAdMob();
                //nativeApp.loadAdMob();
                //nativeApp.showAdMob('interstitial');
                //nativeApp.debugAdMob();

                //nativeApp.integrationTesting();
                //track('ChallengeBonus500');
                //track('RegistrationCompleted');
                //analyticsSetUser('testUser', { lang: 'testLang' });
                //alert('1234-1');
                //nativeApp.openWebBrowser(
                //'https://apps.apple.com/us/app/crypto-game-boxo/id6725853622'
                //'https://play.google.com/store/apps/details?id=com.BoxoTradeApp'
                //'market://details?id=com.BoxoTradeApp'
                //);
                //setIsInstallShowing(true);

                //document.body.style.height = '200px';
                //alert('height = ' + document.body.style.height);
                //window.visualViewport.height - 200 + 'px';
                /*           document
            .querySelector('meta[name=viewport]')
            .setAttribute(
              'content',
              'width=device-width, height=500, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover'
            );
 */
              }
            }}
          >
            <div
              style={{
                width: '100%',
                position: 'absolute',
              }}
            >
              <div
                className={`row`}
                style={{
                  lineHeight: '48px',
                  margin: '4px 6px 0',
                }}
              >
                <div className="col-6">
                  {
                    <div
                      className="text-capitalize text-start text-primary"
                      role="button"
                      onClick={() => {
                        navigate('/app/settings/language', {
                          state: window.location.pathname,
                          replace: true,
                        });
                      }}
                    >
                      <span /> {lang}
                    </div>
                  }
                </div>

                <div className="col-6">
                  {!showStoreLanding && (
                    <div
                      role="button"
                      onClick={() => {
                        if (isNativeApp() || isTelegram())
                          navigate('/app/settings/about', {
                            state: window.location.pathname,
                            replace: true,
                          });
                        else
                          navigate('/', {
                            replace: true,
                          });
                      }}
                    >
                      <div className="text-end text-primary">{t('About')}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className="text-center landing-container"
              style={{ width: '80%' }}
            >
              <h1>BOXO</h1>
              <div>
                <small>
                  {
                    //t('Real-Time Prediction Game')
                    t('Real-Time Trading Game')
                  }
                </small>
              </div>
              {/*         <img src="/img/logo.svg" alt="boxo-logo" /> */}
              {/* <video
                style={{
                  height: '41vh',
                  height: '41svh',
                  width: 'auto',
                  margin:
                    logger?.visits > 2 || (isLogged && user?.email)
                      ? '10px 0'
                      : '4.5vh 0',
                }}
                playsInline
                muted
                loop
                autoPlay
                poster="/img/fox/bit-box.png"
              >
                 <source
                  src="/video/fox/btc.hevc.mp4"
                  type="video/mp4;codecs=hvc1"
                  //webm with alpha channel => H.265
                /> 
                 <source src="/video/fox/btc.webm" type="video/webm" /> 
              </video> */}

              <img
                className="img-fox"
                src="/img/fox/bit-box.png"
                style={{
                  height: '41vh',
                  height: '41svh',
                  width: 'auto',
                  margin:
                    logger?.visits > 2 || (isLogged && user?.email)
                      ? '10px 0'
                      : '4.5vh 0',
                }}
              />

              <div className="text-default">
                {
                  //t('Guess the next BITCOIN price')
                  t('Based on live BITCOIN price')
                }
              </div>
              <div className="text-muted">
                {
                  // <small>Binance • Coinbase • Gate • OKx • Bybit</small>
                }
                <small>{t('Predict • Win • Compete')}</small>
              </div>

              {showStoreLanding && (
                <>
                  <div
                    className="btn btn-primary mt-4 mb-1 text-default"
                    role="button"
                    onClick={() => {
                      track('ClickDownloadOnCampaign');
                      navigate('/download?src=campaign', {
                        replace: true,
                      });
                    }}
                  >
                    <BtnIcon
                      src={
                        isIos()
                          ? '/img/btn-apple.svg'
                          : '/img/btn-google-play.svg'
                      }
                    />
                    {isIos() ? `App Store` : `Google Play`}
                  </div>
                </>
              )}
              {!showStoreLanding && (
                <>
                  {isLogged && user?.email && (
                    <div
                      className="btn btn-primary btn-flashing text-uppercase mt-3 mb-1"
                      role="button"
                      onClick={() => {
                        const canvas = document.querySelector('canvas');
                        logger?.event('click', {
                          target: 'PlayNow',
                          canvas: {
                            w: canvas.clientWidth,
                            h: canvas.clientHeight,
                          },
                        });
                        if (logger?.visits <= 2) track('ClickPlayNow');
                        html5Click();
                        normalClick();
                        navigate('/app/start', { replace: true });
                      }}
                    >
                      {t('PLAY NOW')}
                    </div>
                  )}
                  {!(isLogged && user?.email) && (
                    <>
                      <div
                        className="btn btn-primary btn-flashing text-uppercase mt-3 mb-1"
                        role="button"
                        onClick={() => {
                          const canvas = document.querySelector('canvas');
                          logger?.event('click', {
                            target: 'PlayNow',
                            canvas: {
                              w: canvas.clientWidth,
                              h: canvas.clientHeight,
                            },
                          });
                          if (logger?.visits <= 2) track('ClickPlayNow');
                          html5Click();
                          normalClick();
                          navigate('/app/start', { replace: true });
                        }}
                      >
                        {/* {logger?.visits <= 2
                          ? t('PLAY NOW')
                          : t('Play as guest')} */}
                        {t('PLAY NOW')}
                      </div>

                      {logger?.visits > 2 && (
                        <div
                          className="btn btn-secondary mt-1"
                          role="button"
                          onClick={() => {
                            navigate('/app/auth/sign-up', { replace: true });
                          }}
                        >
                          {`${t('Register')} / ${t('Login')}`}
                          {/* {`${t('Create an account')}`} */}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {process.env.REACT_APP_VERSION && (
                <div style={{ fontSize: '12px', opacity: 0.15 }}>
                  Version: {process.env.REACT_APP_VERSION}
                  {process.env.NODE_ENV !== 'production' ? '.dev' : ''}
                  {nativeApp?.version
                    ? `-${nativeApp?.version}` //
                    : ''}
                  {window.location.href === debug_href ? '.dbg' : ''}
                  {(process.env.NODE_ENV !== 'production' ||
                    window.location.href === debug_href) &&
                  isIos()
                    ? '-ios'
                    : ''}
                  {(process.env.NODE_ENV !== 'production' ||
                    window.location.href === debug_href) &&
                  isAndroid()
                    ? '-android'
                    : ''}
                  {(process.env.NODE_ENV !== 'production' ||
                    window.location.href === debug_href) &&
                  !isAndroid() &&
                  !isIos()
                    ? '-na'
                    : ''}
                </div>
              )}
            </div>

            <InstallDialog
              logger={logger}
              show={isInstallShowing}
              onClose={() => setIsInstallShowing(false)}
            />

            <canvas />
          </div>
        </div>
      </div>
    </>
  );
}
