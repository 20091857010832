import { t } from 'i18next';
import { useMemo } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deliverPurchase, fetchProducts } from '../api/products';
import {
  compareVersions,
  isAndroid,
  isIos,
  isNativeApp,
  isTelegram,
} from '../utils';
import { useInterval } from './use-interval';
import { useLoggerContext } from './use-logger';

const productsDEV = [
  {
    title: 'Small pack (dev)',
    description: '3600 Boxo',
    price: '4.99',
    currency: 'USD',
    localizedPrice: `$4.99`,
    type: 'iap',
    productId: 'SmallBoxoPack',
  },
  {
    title: 'Medium pack (dev)',
    description: '8400 Boxo',
    price: '9.99',
    currency: 'USD',
    localizedPrice: `$9.99`,
    type: 'iap',
    productId: 'MediumBoxoPack',
  },
  {
    title: 'Large pack (dev)',
    description: '19900 Boxo',
    price: '19.99',
    currency: 'USD',
    localizedPrice: `$19.99`,
    type: 'iap',
    productId: 'LargeBoxoPack',
  },

  {
    title: 'Premium for 1 week (dev)',
    description: 'Boost bonuses without ads',
    price: '2.99',
    currency: 'USD',
    localizedPrice: `$2.99`,
    type: 'iap',
    productId: 'premiumweek',
  },
  {
    title: 'Premium for 1 month (dev)',
    description: 'Boost bonuses without ads',
    price: '9.99',
    currency: 'USD',
    localizedPrice: `$9.99`,
    type: 'iap',
    productId: 'premiummonth',
  },
];

const NativeApp = createContext();

export function NativeAppContext({ children }) {
  const NativeAppProps = useNativeApp();
  const persistedNativeAppProps = useMemo(
    () => NativeAppProps,
    [NativeAppProps]
  );
  return (
    <NativeApp.Provider value={persistedNativeAppProps}>
      {children}
    </NativeApp.Provider>
  );
}
export const useNativeAppContext = () => useContext(NativeApp);

function useNativeApp() {
  const navigate = useNavigate();
  const logger = useLoggerContext();
  const [version, setVersion] = useState(null);
  const [expoPushToken, setExpoPushToken] = useState();
  const [productsIAP, setProductsIAP] = useState([]);
  const [productsDB, setProductsDB] = useState([]);
  const [products, setProducts] = useState([]);
  const [trackingPermissionsStatus, setTrackingPermissionsStatus] =
    useState(null);

  //====================== IAP ============

  const getProductAttr = (p) => {
    switch (p?.name) {
      case 'Small Pack':
        return { name: t('Small Pack'), type: 'shop', idx: 0 };
      case 'Medium Pack':
        return { name: t('Medium Pack'), type: 'shop', idx: 1 };
      case 'Large Pack':
        return { name: t('Large Pack'), type: 'shop', idx: 2 };
      case 'Premium Week':
        return { name: t('No ads for 1 week'), type: 'premium', idx: 3 };
      case 'Premium Month':
        return { name: t('No ads for 1 month'), type: 'premium', idx: 4 };
    }
    return { name: p?.name, type: '', idx: 0 };
  };
  useEffect(() => {
    if (!productsIAP?.length || !productsDB?.length) return;

    const newProducts = productsDB
      .map((p) => {
        const productIAP = productsIAP.find(
          (pIAP) => pIAP.productId?.toLowerCase() === p.product_id
        );
        return {
          id: productIAP?.productId,
          ...getProductAttr(p),
          amount: p.amount,
          price: productIAP?.localizedPrice,
        };
      })
      .filter((p) => p.id)
      .sort((a, b) => a.idx - b.idx);
    setProducts(newProducts);
  }, [productsDB?.length, productsIAP?.length, localStorage.getItem('lng')]);

  const onDeliverPurchase = useRef();
  const setOnDeliverPurchase = (callback) =>
    (onDeliverPurchase.current = callback);

  const onPurchaseError = useRef();
  const setOnPurchaseError = (callback) => (onPurchaseError.current = callback);

  async function handleDeliverPurchase(purchase) {
    const { amount, error } = await deliverPurchase(purchase);
    finishPurchase(purchase);
    onDeliverPurchase.current &&
      onDeliverPurchase.current(amount, purchase, error);
  }

  async function handlePurchaseError(purchaseError) {
    logger?.event('native', { name: 'error', purchaseError });
    onPurchaseError.current && onPurchaseError.current(purchaseError);
  }

  //
  function handleSignIn(platform, result) {
    alert(`sign in on ${platform}: ${JSON.stringify(result)}`);
  }

  //========================= Push ===========

  function handlePushNotification(notification) {
    const persistPushNotificationStatus = async (id) => {
      return fetch('/api/push/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
    };

    if (!notification?.data) return;
    persistPushNotificationStatus(notification?.data?.id);
    const url = notification?.data?.url;
    const email = notification?.data?.email;
    const redirectUrl = `/app/auth/login?&email=${btoa(email ?? '')}&url=${btoa(
      url ?? ''
    )}`;
    if (!!url) {
      //logger?.event('native', { name: 'push', notification }); //TODO: prevent native app calling it multiple times
      navigate(redirectUrl, { replace: true });
    } else {
      logger?.event('error', { name: 'push', msg: 'empty url', notification }); //TODO: prevent native app calling it multiple times
    }
  }

  //========================= SWITCH ===========

  function handleNativeAppMessage(msg) {
    try {
      const data = JSON.parse(msg.data);
      switch (data?.method) {
        case 'pushNotication':
          handlePushNotification(data?.notification);
          break;
        case 'onDeliverPurchase':
          handleDeliverPurchase(data?.purchase);
          break;
        case 'onPurchaseError':
          handlePurchaseError(data?.purchaseError);
          break;
        case 'onSignIn':
          handleSignIn(data?.platform, data.result);
          break;
        case 'setProducts':
          if (false && process.env.NODE_ENV !== 'production') {
            alert('Ignoring products from device. Keeping DEV products.');
            return;
          }
          if (data?.products?.length > 0) {
            setProductsIAP(data?.products);
            logger?.event('native', {
              products: data?.products?.length,
            });
          } else {
            //Retry
            setTimeout(() => postData({ method: 'getProducts' }), 15000);
            try {
              if (data?.error === 'Not ready') {
                logger?.event('native', {
                  name: 'error',
                  msg: `setProducts not ready`,
                });
              } else if (data?.error)
                logger?.event('native', {
                  name: 'error',
                  msg: `setProducts: ${data?.error}`,
                });
              else
                logger?.event('native', {
                  name: 'error',
                  msg: `setProducts empty`,
                  data,
                });
            } catch (e) {
              //loggin failed
            }
          }
          /*
            else if (data?.products?.length === 0)
            alert(`NO PRODUCTS: ${JSON.stringify(data)}`);
         */
          break;
        case 'setExpoPushToken':
          setExpoPushToken(data?.token);
          if (getPushNotificationsCallback.current) {
            getPushNotificationsCallback.current({
              token: data?.token,
              status: data?.status,
              message: data?.message,
            });
            getPushNotificationsCallback.current = null;
          }
          break;
        case 'version':
          setVersion(data?.version);
          break;
        case 'onShowAdMob':
          //Depricated since native version 1.12.0
          showAdMobEventListener?.current &&
            showAdMobEventListener.current(data?.event);
          break;
        case 'onAdMob':
          showAdMobEventListener?.current &&
            showAdMobEventListener.current(data?.event);
          break;
        case 'onAppodeal':
          //logger?.event('appodeal', data);
          onAppodealEventListener?.current &&
            onAppodealEventListener.current(data?.event);
          break;
        case 'onTrackingPermissionsStatus':
          logger?.event('native', {
            trackingPermission: isIos()
              ? 'ios'
              : isAndroid()
              ? 'android'
              : 'unknown',
            granted: data?.granted,
            status: data?.status, //appodealInit() fails on 'undetermined'
            canAskAgain: data?.canAskAgain,
            expires: data?.expires,
          });
          setTrackingPermissionsStatus(data?.status);
        case 'onIronSource':
          //Do no use alerts which might stuck ads!!!
          break;
        case 'navigate':
          setTimeout(() => {
            try {
              const baseUrl = `${window.location.protocol}//${window.location.host}`;
              const location = data?.location?.replace(baseUrl, '');
              //logger?.event('native', { name: 'navigate', url: location }); //TODO -- prevent too many events!
              if (
                location.indexOf('download') < 0 &&
                location.indexOf('site') < 0
              )
                if (location === '/') navigate('/home', { replace: true });
                else navigate(location, { replace: true });
            } catch (e) {
              logger?.event('error', {
                name: 'onNativeNavigate',
                url: data?.location,
                e,
              });
            }
          }, 400);
          break;
        case 'log':
          const name = data?.event?.name;
          const eventData = data?.event?.data;
          logger?.event('native', { name, eventData });
          break;
        case 'onRequestReview':
          logger.event('native', {
            name: 'requestReview',
            result: data?.result,
            error: data?.error,
          });
          break;
        case 'alert':
          alert(data?.message);
          break;
        default:
          if (process.env.NODE_ENV !== 'production')
            alert('Native app calls unknown method: ' + data?.method);
      }
    } catch (_) {}
  }

  useEffect(() => {
    //Do not init for not native app
    if (!isNativeApp() && process.env.NODE_ENV === 'production') return;

    const messageListener = window.addEventListener(
      'message',
      (msg) => handleNativeAppMessage(msg),
      true
    );

    try {
      getPushNotifications();
      postData({ method: 'getLastPushNotification' });
      postData({ method: 'getProducts' });
      if (process.env.NODE_ENV !== 'production') setProductsIAP(productsDEV);
      fetchProducts().then(async (response) => {
        setProductsDB(response?.products);
      });
    } catch (error) {
      try {
        logger?.event('error', { msg: 'native not initialized', error });
      } catch (e) {}
    }

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  useInterval(
    () => {
      postData({ method: 'getVersion' });
      postData({ method: 'initialized' });
    },
    version ? null : 1000
  );

  useEffect(() => {
    if (version) logger?.event('native', { version });
  }, [version]);

  function postData(data) {
    window.ReactNativeWebView?.postMessage(JSON.stringify(data));
  }

  //============================================================
  // Methods
  //============================================================

  const getPushNotificationsCallback = useRef();
  function getPushNotifications(requestPermission = false, callback = null) {
    getPushNotificationsCallback.current = callback;
    postData({ method: 'getPushNotifications', requestPermission });
  }

  function nativeAlert(message) {
    postData({ method: 'alert', message, title: 'Message' });
  }
  function vibrate(vibration) {
    postData({ method: 'vibrate', vibration });
  }
  function showAdMob(adType, adUnitId) {
    postData({
      method: 'showAdMob',
      adType,
      adUnitId, // Depricated starting from 1.13.0 native app
    });
  }
  function initAdMob(rewaded, interstitial, rewadedInterstitial) {
    postData({
      method: 'initAdMob',
      rewaded,
      interstitial,
      rewadedInterstitial,
    });
  }

  function loadAdMob() {
    postData({ method: 'loadAdMob' });
  }

  function debugAdMob(adUnit) {
    postData({ method: 'debugAdMob', adUnit });
  }

  //-------

  function initIronSource() {
    //TODO - Used hardcoded appKey...
    postData({
      method: 'initIronSource',
      userId: undefined,
      appKey: undefined,
      placementId: undefined,
    });
  }

  function integrationTesting() {
    postData({ method: 'ironSourceIntegrationTesting' });
  }

  function showIronSource() {
    postData({ method: 'showIronSource' });
  }

  //----------------
  function initAppodeal(env = 'dev') {
    if (compareVersions(version, '1.12.0') >= 0)
      postData({
        method: 'initAppodeal',
        isTest: env === 'dev',
        adTypes: undefined, //default rewarded + interstitial
      });
  }
  function showAppodeal(adType = 'rewarded') {
    if (compareVersions(version, '1.12.0') >= 0)
      postData({ method: 'showAppodeal', adType });
  }

  function requestTrackingPermissions() {
    if (compareVersions(version, '1.12.0') >= 0)
      postData({ method: 'requestTrackingPermissions' });
  }

  function requestReview() {
    //if (compareVersions(version, '1.14.0') >= 0)
    postData({ method: 'requestReview' });
  }

  //-----------------

  function openWebBrowser(url, type = '', redirectUrl = '') {
    //type = 'auth' for secure browser with cookies
    //redirectUrl for auth browser only
    if (isNativeApp())
      postData({ method: 'openWebBrowser', url, type, redirectUrl });
    else if (!isTelegram())
      window.Telegram.WebApp.openLink(url, { try_instant_view: true });
    else window.open(url, '_blank');
  }

  function closeWebBrowser() {
    postData({ method: 'closeWebBrowser' });
  }

  function signIn(platform) {
    postData({ method: 'signIn', platform });
  }

  function isSignedIn(platform) {
    postData({ method: 'isSignedIn', platform });
  }

  function signOut(platform) {
    postData({ method: 'signOut', platform });
  }

  function haptics(type) {
    if (!isTelegram()) postData({ method: 'haptics', type });
    else
      try {
        const haptic = window.Telegram.WebApp.HapticFeedback;
        if (['error', 'warning', 'success'].includes(type)) {
          haptic?.notificationOccurred(type);
        } else if (['selection'].includes(type)) {
          haptic?.selectionChanged();
        } else {
          haptic?.impactOccurred(type);
        }
      } catch (e) {
        //alert(JSON.stringify(e));
      }
  }

  function updateVersion() {
    postData({ method: 'updateVersion' });
  }

  function purchaseProduct(productId) {
    postData({ method: 'purchaseProduct', productId });
  }

  function finishPurchase(purchase) {
    postData({ method: 'finishPurchase', purchase });
  }

  const showAdMobEventListener = useRef();
  function addAdEventListener(event, callback) {
    if (event === 'onShowAdMob') {
      showAdMobEventListener.current = callback;
      return () => (showAdMobEventListener.current = null);
    }
  }

  const onAppodealEventListener = useRef();
  function addAppodealListener(callback) {
    onAppodealEventListener.current = callback;
    return () => (onAppodealEventListener.current = null);
  }

  function firebaseEvent(name, data) {
    postData({ method: 'firebaseEvent', name, data });
    //logger?.event('native', { name: 'firebase', event: { name, data } });
  }

  function openInbox() {
    postData({ method: 'openInbox' });
  }

  /*   function appodealSetTesting(value) {
    postData({ method: 'appodealSetTesting', value });
  }

 */
  return {
    version,
    products,
    purchaseProduct,
    expoPushToken,
    alert: nativeAlert,
    vibrate,
    haptics,
    initAdMob,
    loadAdMob,
    showAdMob,
    debugAdMob,
    addAdEventListener,
    addAppodealListener,
    showIronSource,
    integrationTesting,
    updateVersion,
    getPushNotifications,
    setOnDeliverPurchase,
    setOnPurchaseError,
    signIn,
    signOut,
    isSignedIn,
    openWebBrowser,
    closeWebBrowser,
    firebaseEvent,
    openInbox,
    initAppodeal,
    showAppodeal,
    trackingPermissionsStatus,
    requestTrackingPermissions,
    requestReview,
  };
}
