import { t } from 'i18next';
import { createContext } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Loader } from '../../pages/loader';
import { compareVersions, isAndroid, isIos, isNativeApp } from '../utils';
import { loadPersistentState } from '../utils/persist';
import { useLoggerContext } from './use-logger';
import { useNativeAppContext } from './use-native-app';

const AdMob = createContext();
export function AdMobContext({ children }) {
  const adMobProps = useAdMob();
  const persistedAdMobProps = useMemo(() => adMobProps, [adMobProps]);
  return (
    <AdMob.Provider value={persistedAdMobProps}>{children}</AdMob.Provider>
  );
}
export const useAdMobContext = () => useContext(AdMob);

function useAdMob() {
  const nativeApp = useNativeAppContext();
  const logger = useLoggerContext();

  const [isLoadedRewarded, setIsLoadedRewarded] = useState(false);
  const [isLoadedRewardedInterstitial, setIsLoadedRewardedInterstitial] =
    useState(false);
  const [isLoadedInterstitial, setIsLoadedInterstitial] = useState(false);

  const listeners = useRef();
  function setListeners(
    callbacks = { onDone: null, onFail: null, onShowing: null }
  ) {
    listeners.current = callbacks;
  }

  const handleShowAdMob = (event) => {
    logger?.event('adMob', event);
    const { onDone, onFail, onShowing } = listeners.current;

    if (event?.name === 'done') onDone && onDone(event?.data);
    if (event?.name === 'failed') onFail && onFail('admob is failed');
    if (event?.name === 'update') {
      if (event?.data?.event === 'loaded') {
        if (event?.data?.adType === 'rewarded') setIsLoadedRewarded(true);
        if (event?.data?.adType === 'rewardedInterstitial')
          setIsLoadedRewardedInterstitial(true);
        if (event?.data?.adType === 'interstitial')
          setIsLoadedInterstitial(true);
      }
      onShowing && onShowing(event?.data);
    }
  };

  function init() {
    if (!isNativeApp()) return false;
    if (compareVersions(nativeApp?.version, '1.12.0') < 0) return false;
    nativeApp?.addAdEventListener('onShowAdMob', handleShowAdMob);

    //Init ads
    const rewaded = isIos()
      ? 'ca-app-pub-8010863558446703/1011493092'
      : isAndroid()
      ? 'ca-app-pub-8010863558446703/3969390003'
      : undefined;
    const interstitial = isIos()
      ? undefined
      : isAndroid()
      ? undefined
      : undefined;
    const rewadedInterstitial = isIos()
      ? 'ca-app-pub-8010863558446703/5824836074'
      : isAndroid()
      ? 'ca-app-pub-8010863558446703/8698411423'
      : undefined;
    if (process.env.NODE_ENV === 'production')
      nativeApp?.initAdMob(rewaded, interstitial, rewadedInterstitial);
    else nativeApp?.initAdMob();
    return true;
  }

  useEffect(() => {
    //init(); -- Admob AUTO init for 1.12.0 is disabled because we move to Appodeal
  }, [nativeApp?.version]);

  const showRewarded = () => nativeApp?.showAdMob('rewarded');
  const showRewardedInterstitial = () =>
    nativeApp?.showAdMob('rewardedInterstitial');

  return {
    isLoadedRewarded,
    isLoadedRewardedInterstitial,
    isLoadedInterstitial,
    showRewarded,
    showRewardedInterstitial,
    setListeners,
    init,
  };
}

export function AdMobContainer({ format, onDone, onFail, onShowing }) {
  const nativeApp = useNativeAppContext();

  if (compareVersions(nativeApp?.version, '1.12.0') < 0)
    return (
      <LegacyAdMobContainer
        format={format}
        onDone={onDone}
        onFail={onFail}
        onShowing={onShowing}
      />
    );
  else
    return (
      <NewAdMobContainer
        format={format}
        onDone={onDone}
        onFail={onFail}
        onShowing={onShowing}
      />
    );
}

function NewAdMobContainer({ format = 'Long', onDone, onFail, onShowing }) {
  const adMob = useAdMobContext();
  const nativeApp = useNativeAppContext();
  useEffect(() => {
    adMob.setListeners({ onDone, onFail, onShowing });
  }, []);
  useEffect(() => {
    if (!adMob.init()) onFail && onFail('adMob is not supported');
  }, [nativeApp?.version]);

  const [shouldWait, setShouldWait] = useState(true);

  useEffect(() => {
    if (format === 'Long')
      if (adMob.isLoadedRewarded) adMob.showRewarded();
      else if (!shouldWait) onFail && onFail('admob rewarded is not loaded');
      else setTimeout(() => setShouldWait(false), 3500);

    if (format === 'Short')
      if (adMob.isLoadedRewarded) adMob.showRewardedInterstitial();
      else if (!shouldWait)
        onFail && onFail('admob interstitial is not loaded');
      else setTimeout(() => setShouldWait(false), 3500);
  }, [format, shouldWait]);
  return <Loader overlay />;
}

// ----------------- LEGACY ----------------------
// DELETE AFTER MIGRATION TO NATIVE APP v.1.12.0 OR HIGHER

function LegacyAdMobContainer({ format, onDone, onFail, onShowing }) {
  const logger = useLoggerContext();
  const handleShowAdMob = (event) => {
    logger?.event('adMob', { name: event?.name, format, event: event?.data });
    if (event?.name === 'done') onDone && onDone(event?.data);
    if (event?.name === 'failed') onFail && onFail('adMob is failed');
    if (event?.name === 'showing') onShowing && onShowing(event?.data);
  };

  //format = "Long" | "Short"
  function getAdmobByFormat(format = '') {
    const settings = loadPersistentState('settings');

    //key = adMob[Adnroid | Ios][Short | Long | empty][Test | empty]
    const key = `adMob${isAndroid() ? 'Android' : ''}${
      isIos() ? 'Ios' : ''
    }${format}${window?.location?.hostname === 'boxo.trade' ? '' : 'Test'}`;

    const adMobForPlatform = settings ? settings[key] : null;

    logger.event('adMob', { showing: adMobForPlatform });

    const adMobFallback = {
      adType: 'rewardedInterstitial',
      adUnitId: 'ca-app-pub-3940256099942544/6978759866',
    };
    const adMob = !adMobForPlatform ? adMobFallback : adMobForPlatform;

    return adMob;
  }

  const nativeApp = useNativeAppContext();
  useEffect(() => {
    if (!isNativeApp()) {
      onFail && onFail('AdMob is not supported');
      return;
    }
    const unsubscibe = nativeApp?.addAdEventListener(
      'onShowAdMob',
      handleShowAdMob
    );
    const { adType, adUnitId } = getAdmobByFormat(format);
    //alert('Showing====' + adType + ' id====' + adUnitId);
    nativeApp?.showAdMob(adType, adUnitId);

    return () => {
      unsubscibe && unsubscibe();
    };
  }, []);

  return (
    <>
      <Loader overlay />
    </>
  );
}
